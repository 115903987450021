import React, {
  useEffect,
  Fragment,
  useCallback,
  useState,
  useRef,
} from "react";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Select from "react-dropdown-select";
import { Box, Grid, Link } from "@material-ui/core";
import {
  BiChevronUp,
  BiChevronDown,
  BiArrowBack,
  BiPlusCircle,
  BiXCircle,
  BiCheckCircle,
} from "react-icons/bi";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import { BiPlus, BiMinus } from "react-icons/bi";
import DatePicker from "react-date-picker";
import { apiAxios } from "../../Util/ApiAxios";
import { checkNumbersOnly, checkDob } from "../../Util/validations";
import moment from "moment";

import {
  maxUseOptions,
  minTimeOptions,
  maxBillingFreqList,
  carePlanGridInfoList,
} from "../../Util/OtherList";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProgramTemplate(options) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [programList, setProgramList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);

  // loader
  const [ifLoading, setIfLoading] = useState(false);

  const [programID, setProgramID] = React.useState("");
  const [programName, setProgramName] = React.useState("");
  const [programAbbrev, setProgramAbbrev] = React.useState("");
  const [programDesc, setProgramDesc] = React.useState("");
  const [programReimburse, setProgramReimburse] = React.useState("");
  const [programStart, setProgramStart] = React.useState(new Date());
  const [programEnd, setProgramEnd] = React.useState("");
  const [programBcode, setProgramBcode] = React.useState("");
  const [addonBcode, setAddonBcode] = React.useState("");
  const [maxBillFreq, setMaxBillFreq] = React.useState("");
  const [maxUserType, setMaxUserType] = React.useState("");
  const [minCondition, setMinCondition] = React.useState("");
  // const [minTimer, setMinTimer] = React.useState(10);
  // const [maxUse, setMaxUse] = React.useState(1);
  const [minTimer, setMinTimer] = React.useState("");
  const [maxUse, setMaxUse] = React.useState("");
  const [addonCounter, setAddonCounter] = React.useState(0);
  const [userTypes, setUserTypes] = React.useState([]);
  const [userTypesOption, setUserTypesOption] = React.useState([]);
  const [initialVisit, setInitialVisit] = React.useState(true);
  const [minUserType, setMinUserType] = React.useState("");
  const [addonArray, setAddonArray] = React.useState([
    { code: "--", max_use: "--", min_timer: "--" },
  ]);

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const [addoncode, setAddOnCode] = React.useState([
    { code: "", max_use: "1", min_timer: "10" },
  ]);

  /*Error*/
  const [programNameError, setProgramNameError] = React.useState(false);
  const [programAbbrevError, setProgramAbbrevError] = React.useState(false);
  const [programReimburseError, setProgramReimburseError] =
    React.useState(false);
  const [startdateError, setStartDateError] = React.useState(false);
  const [enddateError, setEndDateError] = React.useState(false);
  const [maxUseError, setMaxUseError] = React.useState(false);
  const [minTimerError, setMinTimerError] = React.useState(false);
  const [maxBillFreqError, setMaxBillFreqError] = React.useState(false);
  const [minConditionError, setMinConditionError] = React.useState(false);
  const [maxUserTypeError, setMaxUserTypeError] = React.useState(false);
  const [programBcodeError, setProgramBcodeError] = React.useState(false);
  const [listCareplan, setListCareplan] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProgramList = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let data = {
        search_value: "",
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
      };

      apiAxios
        .post("/program/list", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setProgramList(response.data.data);
            setTotalPages(response.data.total_pages);
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  );

  const [checkedList, setCheckedList] = React.useState([]);

  const handleChangeChecked = (event, value) => {
    var arr = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      arr = checkedList ? checkedList : [];
      arr.push(value);
      setCheckedList([...arr]);
    } else {
      // const index = checkedList.indexOf(value);

      const index = checkedList.findIndex(object=>{
        return object.id === value.id
      })

      arr = checkedList ? checkedList : [];
      arr.splice(index, 1);
      setCheckedList([...arr]);

      //console.log("--->", index);
    }
    console.log("--->", checkedList);
  };

  useEffect(() => {
    getProgramList();
    getUserType();
    getCarePlanAttribute();
  }, []);

  useEffect(() => {
    if (userTypes) {
      var ob_arr = [];
      //console.log("user types = ", userTypes);
      for (var i = 0; i < userTypes.length; i++) {
        let value = userTypes[i].id;
        let label = userTypes[i].name;
        let ob = {
          label: label,
          value: value,
        };
        //console.log("ob types = ", ob);
        ob_arr.push(ob);
        userTypesOption.push(ob);
      }
      setUserTypesOption(ob_arr);
    }
  }, [userTypes]);

  const saveDetails = (flag) => {

    let careplanInfo = carePlanGridInfoList();
    console.log(careplanInfo)

    console.log(maxUse)
    console.log(minTimer)
    console.log(maxBillFreq)
    console.log(maxUserType)
    console.log(addoncode)

    let isValid = true;
    var temp_arr = [];
    console.log("submit form");

    if (programName.trim().length > 0) {
      setProgramNameError(false);
    } else {
      setProgramNameError(true);
      isValid = false;
    }

    if (programAbbrev.trim().length > 0) {
      setProgramAbbrevError(false);
    } else {
      setProgramAbbrevError(true);
      isValid = false;
    }

    if (programBcode.trim().length > 0) {
      setProgramBcodeError(false);
    } else {
      setProgramBcodeError(true);
      isValid = false;
    }


    if (!checkNumbersOnly(programReimburse) || programReimburse == null) {
      setProgramReimburseError(true);
      isValid = false;
    } else {
      setProgramReimburseError(false);
    }

    // if (parseInt(checkDob(programStart).substring(0, 2)) > 0) {
    //   setStartDateError(true);
    //   isValid = false;
    // } else {
    //   setStartDateError(false);
    // }
    if(programStart){
      setStartDateError(false);
    }else {
      setStartDateError(true);
      isValid = false;
    }

    // if (
    //   parseInt(checkDob(programEnd).substring(0, 2)) > 0 ||
    //   parseInt(checkDob(programEnd).substring(0, 2)) -
    //   parseInt(checkDob(programStart).substring(0, 2) <= 0)
    // ) {
    //   setEndDateError(true);
    //   isValid = false;
    // } else {
    //   setEndDateError(false);
    // }


    // if(programEnd){
    //   setEndDateError(false);
    // }else {
    //   setEndDateError(true);
    //   isValid = false;
    // }



    if (maxUse || maxUse.length > 0) {
      setMaxUseError(false);
    } else {
      setMaxUseError(true);
      isValid = false;
    }
    if (minTimer) {
      setMinTimerError(false);
    } else {
      setMinTimerError(true);
      isValid= false;
    }
    if(maxBillFreq){
      setMaxBillFreqError(false)
    }else {
      setMaxBillFreqError(true);
      isValid = false;
    }
    
    if(maxUserType){
      setMaxUserTypeError(false)
    }else {
      setMaxUserTypeError(true)
      isValid = false;
    }

    if(minCondition){
      setMinConditionError(false)
    }else {
      setMinConditionError(true)
      isValid = false;
    }

    if (checkedList.length > 0) {

      for (var i = 0; i < checkedList.length; i++) {
        var id = checkedList[i].id;
        var short = checkedList[i].atrribute_short_name;

        var ipID = {
          [id]: short
        }
        temp_arr.push(ipID);
      }

    }

    if (isValid) {
      setIfLoading(true);
      let data;
      let url = "";
      if (flag == 1) {
        data = {
          program_name: programName,
          program_abbrev: programAbbrev,
          effective_start_date: moment(programStart).format("YYYY-MM-DD"),
          effective_end_date: programEnd ? moment(programEnd).format("YYYY-MM-DD") : "",
          add_on_timer_min: "5",
          program_description: programDesc,
          primary_billing_code: programBcode,
          max_use: maxUse.value ? maxUse.value : maxUse[0].value,
          timer_min: minTimer ? minTimer : "",
          max_billing_frequency:maxBillFreq? maxBillFreq[0].value : "",
          min_user_type: maxUserType ? maxUserType[0].value : "",
          reimbursement_amt: programReimburse,
          min_num_conditions: minCondition ? minCondition[0].value :"",
          initial_visit_required: initialVisit,
          careplan_template_id: "",
          parent_code: programBcode,
          add_on_code:(addoncode[0]?.code || addoncode.length<1) ? addoncode : [],
          care_plan_grid_info: careplanInfo,
          careplan: checkedList,
          
        };
        console.log("data submit = ", data);

        url = "/program/create";
      } else {
        console.log("max bill freq = ", maxBillFreq);

        data = {
          program_id: programID,
          program_name: programName,
          program_abbrev: programAbbrev,
          effective_start_date: moment(programStart).format("YYYY-MM-DD"),
          effective_end_date: programEnd?  moment(programEnd).format("YYYY-MM-DD") : "",
          add_on_timer_min: "5",
          program_description: programDesc? programDesc : "" ,
          primary_billing_code: programBcode,
          max_use: maxUse.value ? maxUse.value : maxUse[0].value,
          timer_min: minTimer ? minTimer : "",
          max_billing_frequency: Array.isArray(maxBillFreq) ? maxBillFreq[0].value : maxBillFreq.value,
          min_user_type: maxUserType ? maxUserType.value : maxUserType[0].value,
          reimbursement_amt: programReimburse,
          min_num_conditions: minCondition.value
            ? minCondition.value
            : minCondition[0].value,
          initial_visit_required: initialVisit,
          careplan_template_id: "",
          care_plan_grid_info: temp_arr,
          parent_code: programBcode,
          add_on_code: (addoncode[0]?.code || addoncode.length<1) ? addoncode : [],
          min_user_type: maxUserType.value
            ? maxUserType.value
            : maxUserType[0].value,
          add_on_code_flag: addoncode ? "1" : "0",
          careplan: checkedList,
          care_plan_grid_info: careplanInfo,
        };
        console.log("data submit = ", data);

        url = "/program/update";
      }

      console.log("DATA == == ", data);

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setOpen(false);
            setIfLoading(false);

            // setMaxUserType("");

            // setMaxBillFreq("");
            // setMinCondition("");
            // setMaxUse("")

            handleClose();

           

            getProgramList();
            getUserType();
            getCarePlanAttribute();

            // window.location.reload()

          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
          localStorage.removeItem("admin_Refresh_Token");
          localStorage.removeItem("admin_Token");
          window.location.href = "/";
        });
    }
  };

  function getUserType() {
    let data = {
      table_config_rows_per_page: "15",
      table_config_page_no: "1",
      search: "",
    };

    apiAxios
      .post("/client/user-type-master-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setUserTypes(response.data.data);
        } else {
          // toast.error(response.data.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }

        console.log("Response - ", response);

        if (response.statusCode == 403) {
        }
      })
      .catch((error) => {
        //console.log(error.hint);

        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        localStorage.removeItem("admin_Refresh_Token");
        localStorage.removeItem("admin_Token");
        window.location.href = "/";
      });
  }

  function getCarePlanAttribute() {
    let data = {

    };

    apiAxios
      .post("/report/list-care-plan-attribute", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("careplan attribute response --> ", response);
        if (response.status == 200) {
          let arr = [];
          let temp_arr = [];
          let listarr = [];
          arr = response.data;
          setListCareplan(arr);
          console.log("arr - ", arr);

          for (var i = 0; i < arr.length; i++) {
            var id = arr[i].id;
            var short = arr[i].atrribute_short_name;
            var name = arr[i].attribute_name;

            var ipID = {
              [id]: short
            }
            temp_arr.push(ipID);

            var ob2 = {};
            ob2.short_name = short;
            ob2.name = name;
            listarr.push(ob2);
          }

          console.log("list arr - ", listarr[0]);
          // setListCareplan(temp_arr);

        } else {
          // toast.error(response.data.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }

        console.log("Response - ", response);
        if (response.statusCode == 403) {
        }

      })
      .catch((error) => {
        //console.log(error.hint);

        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });


      });

    console.log("careplan attributes - ", listCareplan);
  }

  //  modal  //
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);

  const [viewData, setViewData] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    getUserType();
  };

  useEffect(() => {
    if (viewData) {
      console.log("data = ", viewData.add_on_code);
      console.log("addon array = ", addonArray);
      setAddonArray(viewData.add_on_code);
    }
  }, [viewData, addonArray]);

  const handleClickOpenView = (data, flag) => {
    if (flag == 1) {
      setViewData(data);
     console.log("careplan view")
    console.log(data.care_plan_grid_info)
    console.log(data.careplan)

    let userTypeList = userTypesOption
    let minUserData  = data.min_user_type
    console.log(minUserData)
    console.log("====")
    console.log(userTypeList)
    for(var i=0; i<userTypeList.length; i++){
      if(userTypeList[i].value === minUserData){
        console.log("inside")
        console.log(userTypeList[i])
        setMinUserType(userTypeList[i].label)
      }
    }
    


      setOpenView(true);
    } else {
      setCheckedList(data.careplan)
      setProgramID(data.id);
      setUpdateFlag(true);
      setProgramName(data.program_name);
      console.log("Program name = ", programName);
      setProgramAbbrev(data.program_abbrev);
      setProgramDesc(data.program_description);
      setProgramReimburse(data.reimbursement_amt);
      var date = new Date(data.effective_start_date);
      setProgramStart(date);
      var date2 =data.effective_end_date? new Date(data.effective_end_date) : "";

      setProgramEnd(date2);

      setProgramBcode(data.primary_billing_code);
      setMaxUse(data.max_use);
      // setInitialVisit(data.initial_visit_required == "True" ? true : false);
      setInitialVisit(data.initial_visit_required === true ? true : false);
      let minCon = maxUseOptions();
      for (var i = 0; i < minCon.length; i++) {
        if (data.min_num_conditions == minCon[i].value) {
          setMinCondition(minCon[i]);
        }
        if (data.max_use == minCon[i].value) {
          setMaxUse(minCon[i]);
        }
      }
      // let minTim = minTimeOptions();
      // for (var i = 0; i < minTim.length; i++) {
      //   if (data.timer_min == minTim[i].value) {
      //     setMinTimer(minTim[i]);
      //   }
      // }

      setMinTimer(data.timer_min)

      let maxBill = maxBillingFreqList();
      for (var i = 0; i < maxBill.length; i++) {
        if (data.max_billing_frequency == maxBill[i].value) {
          setMaxBillFreq(maxBill[i]);
        }
      }

      console.log("Max Bill Freq = ", maxBillFreq);
      if (userTypesOption.length > 0) {
        for (var i = 0; i < userTypesOption.length; i++) {
          if (data.min_user_type == userTypesOption[i].value) {
            setMaxUserType(userTypesOption[i]);
            //console.log("User Type Matched - ");
          }
        }
      }
      setAddOnCode(data.add_on_code ? data.add_on_code : addoncode );

      console.log("add on = ", addoncode);
      setOpen(true);
    }
  };

  const handleClose = () => {

        setMaxUserType("");

        setMaxBillFreq("");
        setMinCondition("");
        setMaxUse("");

    console.log(maxUse)
    console.log(maxUserType)
    console.log(minCondition)
    console.log(maxBillFreq)



    setCheckedList([])
    setProgramID("");
    setUpdateFlag(false);
    setProgramName("");
    
    setProgramAbbrev("");
    setProgramDesc("");
    setProgramReimburse("");
  
    setProgramStart(new Date());
   
    setProgramEnd("");
    setProgramBcode("");
    setMaxUse("");
  
    setInitialVisit(true);
  
        setMinCondition("");
     
     
      
        setMinTimer("");
      
     
   
        


        
   
    setAddOnCode([
      { code: "", max_use: "1", min_timer: "10" },
    ]);

    setOpen(false);
  };

  const addAddon = () => {
    setAddonCounter(addonCounter + 1);
    //let arr = addoncode;
    //arr.push({'code': '', 'use': '', 'time': ''});
    setAddOnCode([...addoncode, { code: "", max_use: "1", min_timer: "10" }]);
    console.log("Addoncode = ", addoncode);
  };

  const removeAddOn = (index) => {
    console.log("Index = ".index);
    let arr = [...addoncode];
    if (index > -1) {
      arr.splice(index, 1);
    }
    setAddonCounter(addonCounter - 1);
    setAddOnCode(arr);
  };

  let handleChangeCode = (i, e) => {
    let newFormValues = [...addoncode];
    newFormValues[i][e.target.name] = e.target.value;
    setAddOnCode(newFormValues);

    console.log("Addoncode 2 = ", addoncode);
  };

  const changeVisitFlag = () => setInitialVisit(!initialVisit);

  const handleCloseView = () => {
    setOpenView(false);
  };

  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };
  const [value2, onChange2] = useState(new Date());
  return (
    <div>
      <Box className={classes.btncol}>
        <Button className={classes.addprovider} onClick={handleClickOpen}>
          <BiPlusCircle className={classes.icon} /> Add New Program
        </Button>
      </Box>
      <Box className={classes.ProgramTable}>
      <Box className={classes.Throw1}>
        <Box className={classes.Thcol5}>Name</Box>
        <Box className={classes.Thcol6}>Abbreviation</Box>
        <Box className={classes.Thcol8}>Start Date</Box>
        <Box className={classes.Thcol9}>End Date</Box>
        <Box className={classes.Thcol10}>Reimbursement Fee($)</Box>
        <Box className={classes.Thcol11}>Actions</Box>
      </Box>
      {programList.length > 0 ? (
        <>
          {programList.map((each) => (
            <Box className={classes.Tdrow1}>
              <Box className={classes.Tdcol5}>{each.program_name}</Box>
              <Box className={classes.Tdcol6}>{each.program_abbrev}</Box>
              <Box className={classes.Tdcol8}>{each.effective_start_date}</Box>
              <Box className={classes.Tdcol9}>{each.effective_end_date}</Box>
              <Box className={classes.Tdcol10}>{each.reimbursement_amt}</Box>
              <Box className={classes.Tdcol11}>
                {/* <Button className={classes.EditBtn} onClick={() => handleClickOpenView(each,2)}>Edit</Button> */}
                <Button
                  className={classes.ActionBtn}
                  onClick={() => handleClickOpenView(each, 1)}
                >
                  View
                </Button>
                <Button
                      className={classes.ActionBtn} style={{marginLeft: "10px"}}
                      onClick={() => handleClickOpenView(each, 2)}
                    >
                      Update
                    </Button>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box className={classes.Tdrow1}>
          <Box
            className={classes.Tdcol12}
            style={{ width: "100%", textAlign: "center", color: "#dfdfdf" }}
          >
            No Data Available
          </Box>
        </Box>
      )}
</Box>
<div     
         style={{
            width: '100%',
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right',
          }}>
      <Pagination
        count={totalPages}
        color="primary"
        page={currentPage}
        onChange={(event, pageNumber) =>
          getProgramList({
            table_config_page_no: `${pageNumber}`,
          })
        }
      />
    </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {/* modal */}
      <Dialog
        className={classes.modal}
        open={open}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
            <Typography variant="h3" className={classes.TopHeading}>
              {updateFlag? "Update Program Template" : "Add New Program Template" }
            </Typography>
            <Button onClick={handleClose} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <form>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    <Box className={classes.Formcol}>
                      <label>
                        Program Name<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <TextField
                        className={classes.input}
                        placeholder={"Enter Program Name"}
                        value={programName ? programName : ""}
                        type="text"
                        style={{ width: "70%" }}
                        onChange={(e) => setProgramName(e.target.value)}
                      />
                    </Box>
                    {programNameError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        Program name is required.
                      </p>
                    ) : (
                      <></>
                    )}
                    <Box className={classes.Formcol}>
                      <label>
                        Program Abbreviation
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <TextField
                        className={classes.input}
                        placeholder="Program Abbreviation"
                        value={programAbbrev ? programAbbrev : ""}
                        type="text"
                        style={{ width: "70%" }}
                        onChange={(e) => setProgramAbbrev(e.target.value)}
                      />
                    </Box>
                    {programAbbrevError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        Program Abbreviation is required.
                      </p>
                    ) : (
                      <></>
                    )}
                    <Box className={classes.Formcol}>
                      <label>Description</label>
                      <TextareaAutosize
                        className={classes.textarea}
                        placeholder="Enter Program Description"
                        value={programDesc ? programDesc : ""}
                        type="text"
                        style={{ width: "66%" }}
                        onChange={(e) => setProgramDesc(e.target.value)}
                        rows={3}
                      />
                    </Box>
                    <Box className={classes.Formcol}>
                      <label>Reimbursement Fee<span style={{ color: "#ff0000" }}>*</span></label>
                      <TextField
                        className={classes.input}
                        placeholder="Enter Reimbursement Fee"
                        value={programReimburse ? programReimburse : ""}
                        type="number"
                        style={{ width: "70%" }}
                        onChange={(e) => setProgramReimburse(e.target.value)}
                      />
                    </Box>
                    {programReimburseError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        Valid Reimbursement Amount is required.
                      </p>
                    ) : (
                      <></>
                    )}
                    <Box className={classes.Formcol}>
                      <label>
                        Start Date<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <DatePicker
                        onChange={setProgramStart}
                        value={programStart}
                        className={classes.input}
                        maxDate={moment().add(1, 'days').toDate()} 
                        clearIcon={null} 
                      />
                    </Box>
                    {startdateError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        Start Date cannot be in past.
                      </p>
                    ) : (
                      <></>
                    )}

                    <Box className={classes.Formcol}>
                      <label>
                        End Date
                        {/* <span style={{ color: "#ff0000" }}>*</span> */}
                      </label>
                      <DatePicker
                        onChange={setProgramEnd}
                        value={programEnd}
                        className={classes.input}
                        minDate={moment().add(1, 'days').toDate()} clearIcon={null}
                      />
                    </Box>
                    {enddateError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        End date should be greater than start date.
                      </p>
                    ) : (
                      <></>
                    )}

                    <Box className={classes.Formrow}>
                      <Box
                        className={classes.Formcol3}
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginRight: 15 }}>
                          Primary B-Code
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Box>
                        <TextField
                          className={classes.input}
                          placeholder="Primary B-Code"
                          value={programBcode ? programBcode : ""}
                          type="text"
                          style={{ width: "100%", marginRight: "4%" }}
                          onChange={(e) => setProgramBcode(e.target.value)}
                        />


                      {programBcodeError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: "12px",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        A program should have atleast one primary billing code.
                      </p>
                    ) : (
                      <></>
                    )}
                    </Box>
                      </Box>
                     
                      <Box
                        className={classes.Formcol3}
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginRight: 3 }}>
                          Max Use<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={maxUseOptions()}
                          value={maxUse}
                          onChange={setMaxUse}
                          placeholder={maxUse.label ? maxUse.label : "Maxuse"}
                          className={classes.select}
                          style={{ width: "100%" }}
                        />
                        {maxUseError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: "12px",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          Max Use is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      </Box>
                      
                      <Box
                        className={classes.Formcol3}
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginRight: 15 }}>
                          {" "}
                          Minimum Time
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        {/* <Select
                          options={minTimeOptions()}
                          value={minTimer}
                          onChange={setMinTimer}
                          placeholder={
                            minTimer.label ? minTimer.label : "Time"
                          }
                          className={classes.select}
                          style={{ width: "90%" }}
                        /> */}
                        <TextField
                        placeholder="minTimer"
                        type="number"
                        className={classes.input}
                        onChange={(e)=>setMinTimer(e.target.value.replace(/[^0-9]/g,""))}
                        value={minTimer}
                        style={{
                          width: "100%",
                          
                        }}
                        />
                         {minTimerError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: "12px",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          Min Timer required.
                        </p>
                      ) : (
                        <></>
                      )}
                      </Box>
                     
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h3"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: 18,
                              color: "#000",
                              fontWeight: "600",
                              marginBottom: 15,
                            }}
                          >
                            Add-on Code
                          </Typography>
                          <Box className={classes.Throw}>
                            <Box className={classes.Thcol}>
                              <label style={{ color: "#121212" }}>B-Code</label>
                            </Box>
                            <Box className={classes.Thcol2}>
                              <label style={{ color: "#121212" }}>
                                Max Use
                              </label>
                            </Box>
                            <Box className={classes.Thcol3}>
                              <label style={{ color: "#121212" }}>
                                {" "}
                                Minimum Time
                              </label>
                            </Box>
                            <Box className={classes.Thcol4}>
                              <Button
                                className={classes.SubmitAdd}
                                onClick={() => addAddon()}
                              >
                                <BiPlus size="30" />
                              </Button>
                            </Box>
                          </Box>
                          {addoncode.length > 0   ? (
                            <>
                              {addoncode.map((each, index) => (
                                <Box className={classes.Tdrow}>
                                  <Box className={classes.Tdcol}>
                                    <TextField
                                      className={classes.input}
                                      placeholder={
                                        each.code ? each.code : "Addon B-Code"
                                      }
                                      type="text"
                                      style={{
                                        width: "80%",
                                        marginRight: "4%",
                                      }}
                                      value={each.code || ""}
                                      onChange={(e) =>
                                        handleChangeCode(index, e)
                                      }
                                      name="code"
                                    />
                                  </Box>
                                  <Box className={classes.Tdcol2}>
                                    <TextField
                                      className={classes.input}
                                      placeholder={
                                        each.max_use ? each.max_use : "Max Use"
                                      }
                                      type="number"
                                      style={{
                                        width: "80%",
                                        marginRight: "4%",
                                      }}
                                      value={each.max_use || ""}
                                      onChange={(e) =>
                                        handleChangeCode(index, e)
                                      }
                                      name="max_use"
                                    />
                                  </Box>
                                  <Box className={classes.Tdcol3}>
                                    <TextField
                                      className={classes.input}
                                      placeholder={
                                        each.min_timer
                                          ? each.min_timer
                                          : "Minimum Time"
                                      }
                                      type="number"
                                      style={{
                                        width: "80%",
                                        marginRight: "4%",
                                      }}
                                      value={each.min_timer || ""}
                                      onChange={(e) =>
                                        handleChangeCode(index, e)
                                      }
                                      name="min_timer"
                                    />
                                  </Box>
                                  <Box className={classes.Tdcol4}>
                                    <Button className={classes.removebtn}>
                                      <BiMinus
                                        size="30"
                                        onClick={() => removeAddOn(index)}
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              ))}
                            </>
                          ) : (
                            <Box className={classes.Tdrow}>
                              <Box className={classes.Tdcol}>
                                No Codes Added
                              </Box>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <Box className={classes.Formcol2}>
                            <label>
                              Max Billing Freq
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <Select
                              options={maxBillingFreqList()}
                              value={maxBillFreq ? maxBillFreq : ""}
                              onChange={setMaxBillFreq}
                              placeholder={
                                maxBillFreq
                                  ? maxBillFreq.label
                                  : "Calendar Month"
                              }
                              className={classes.select}
                              style={{ width: "200px" }}
                            />
                          </Box>
                          {maxBillFreqError ? (
                            <p
                              style={{
                                color: "#dc3545",
                                fontSize: ".875em",
                                marginTop: "0.25rem",
                                width: "100%",
                                textAlign: "right",
                              }}
                            >
                              Max Billing Frequency is required.
                            </p>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <Box className={classes.Formcol2}>
                            <label>Max User Type<span style={{ color: "#ff0000" }}>*</span>

                            </label>
                            <Select
                              options={userTypesOption}
                              value={maxUserType}
                              onChange={setMaxUserType}
                              placeholder={
                                maxUserType.label
                                  ? maxUserType.label
                                  : "Select User Type"
                              }
                              className={classes.selectMax}
                              style={{ width: "200px" }}
                            />
                          </Box>
                          {maxUserTypeError ? (
                            <p
                              style={{
                                color: "#dc3545",
                                fontSize: ".875em",
                                marginTop: "0.25rem",
                                width: "100%",
                                textAlign: "right",
                              }}
                            >
                              Max user-type is required.
                            </p>
                          ) : (
                            <></>
                          )}

                        </Grid>
                        <Grid item xs={7} md={7}>
                          <Box className={classes.Formcol2}>
                            <label>Chronic Condition Min<span style={{ color: "#ff0000" }}>*</span>

                            </label>
                            <Select
                              options={maxUseOptions()}
                              value={minCondition}
                              onChange={setMinCondition}
                              placeholder={
                                minCondition.label
                                  ? minCondition.label
                                  : "Min Conditions"
                              }
                              className={classes.select}
                              style={{ width: "200px" }}
                            />
                          </Box>
                          {minConditionError ? (
                            <p
                              style={{
                                color: "#dc3545",
                                fontSize: ".875em",
                                marginTop: "0.25rem",
                                width: "100%",
                                textAlign: "right",
                              }}
                            >
                              Min Condition is required.
                            </p>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={1} md={1}></Grid>
                        <Grid item xs={3} md={3}>
                          <Box className={classes.checkList}>
                            <label>Initial visit</label>
                            <Checkbox
                              checked={initialVisit}
                              onChange={changeVisitFlag}
                              defaultChecked
                              color="success"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {/* <Box className={classes.Formcol2}>
                          <label>CarePlan Template</label>
                          <Select options={options} onChange={(values) => this.setValues(values)} placeholder="CCM " className={classes.select} style={{width:'200px'}} />
                      </Box>  */}
                           {listCareplan.length > 0 ?
                            <Box className={classes.Formcol45}>
                              <label>Select CarePlan Attributes -</label><br />
                              <ul style={{ paddingLeft: '10px' }}>
                                {listCareplan.map((each) => {
                                  return (
                                    <li style={{ listStyleType: 'none' }}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <input
                                              type="checkbox"
                                              checked={
                                                checkedList?.find((i)=> each.id === i.id) ? "checked" : ""
                                                 
                                                 
                                               }
                                              value={each}
                                              onChange={(event) => handleChangeChecked(event, each)}
                                              style={{ marginRight: '3%' }}
                                            />
                                          }
                                          label={each.attribute_name}
                                        />
                                      </FormGroup>
                                    </li>
                                  )
                                })
                                }
                              </ul>
                            </Box>
                            :
                            <></>
                          }

                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <FormGroup aria-label="position" row></FormGroup>
          {updateFlag ? (
            !ifLoading?
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={() => saveDetails(2)}
            >
              Update
            </Button>
            :
            <ThreeDots
                   
                   color="#000000"
                   height={50}
                   width={50}
                   timeout={0} //30 secs
                   
                   />

          ) : (

            !ifLoading?
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={() => saveDetails(1)}
            >
              Save
            </Button>
            :
                    <ThreeDots
                   
                   color="#000000"
                   height={50}
                   width={50}
                   timeout={0} //30 secs
                   
                   />
            
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        className={classes.modal}
        open={openView}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseView}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
            <Typography variant="h3" className={classes.TopHeading}>
              View Program Template
            </Typography>
            <Button onClick={handleCloseView} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <form>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    <Box className={classes.Formcol}>
                      <label>
                        Program Name<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <p className={classes.marginTopTen}>
                        {viewData.program_name}
                      </p>
                    </Box>
                    <Box className={classes.Formcol}>
                      <label>
                        Program Abbreviation
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <p className={classes.marginTopTen}>
                        {viewData.program_abbrev}
                      </p>
                    </Box>
                    <Box className={classes.Formcol}>
                      <label>Description</label>
                      <p className={classes.marginTopTen}>
                        {viewData.program_description}
                      </p>
                    </Box>
                    <Box className={classes.Formcol}>
                      <label>Reimbursement Fee</label>
                      <p className={classes.marginTopTen}>
                        {viewData.reimbursement_amt}
                      </p>
                    </Box>
                    <Box className={classes.Formcol}>
                      <label>
                        Start Date<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <p className={classes.marginTopTen}>
                        {viewData.effective_start_date}
                      </p>
                    </Box>
                    <Box className={classes.Formcol}>
                      <label>
                        End Date
                      </label>
                      <p className={classes.marginTopTen}>
                        {viewData.effective_end_date}
                      </p>
                    </Box>
                    <Box className={classes.Formrow}>
                      <Box
                        className={classes.Formcol2}
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginRight: 15 }}>
                          Primary B-Code
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <p className={classes.marginTopTen}>
                          {viewData.primary_billing_code}
                        </p>
                      </Box>
                      <Box
                        className={classes.Formcol2}
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginRight: 15 }}>
                          Max Use<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <p className={classes.marginTopTen}>
                          {viewData.max_use}
                        </p>
                      </Box>
                      <Box
                        className={classes.Formcol2}
                        style={{ marginBottom: 0 }}
                      >
                        <label style={{ marginRight: 15 }}>
                          {" "}
                          Minimum Time
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <p className={classes.marginTopTen}>
                          {viewData.timer_min}
                        </p>
                      </Box>
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {viewData.add_on_code_flag == "1" &&
                          viewData.add_on_code ? (
                          <Grid item xs={12} md={12}>
                            <Typography
                              variant="h3"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: 18,
                                color: "#000",
                                fontWeight: "600",
                                marginBottom: 15,
                              }}
                            >
                              Add-on Code
                            </Typography>

                            <Box className={classes.Throw}>
                              <Box className={classes.Thcol}>
                                <label style={{ color: "#121212" }}>
                                  B-Code
                                </label>
                              </Box>
                              <Box className={classes.Thcol2}>
                                <label style={{ color: "#121212" }}>
                                  Max Use
                                </label>
                              </Box>
                              <Box className={classes.Thcol3}>
                                <label style={{ color: "#121212" }}>
                                  {" "}
                                  Minimum Time
                                </label>
                              </Box>
                            </Box>

                            {addonArray ?
                              <>
                                {addonArray.map((each) => (
                                  <Box className={classes.Tdrow}>
                                    <Box className={classes.Tdcol}>
                                      {each.code}
                                    </Box>
                                    <Box className={classes.Tdcol2}>
                                      {each.max_use}
                                    </Box>
                                    <Box className={classes.Tdcol3}>
                                      {each.min_timer}
                                    </Box>
                                    <Box className={classes.Tdcol4}></Box>
                                  </Box>
                                ))}
                              </>
                              :
                              <div>No Data Found</div>
                            }
                          </Grid>
                        ) : (
                          <></>
                        )}

                        <Grid item xs={7} md={7}>
                          <Box className={classes.Formcol2}>
                            <label>
                              Max Billing Freq
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <p className={classes.marginTopTen}>
                              {viewData.max_billing_frequency}
                            </p>
                          </Box>
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <Box className={classes.Formcol2}>
                            <label>Max User Type<span style={{ color: "#ff0000" }}>*</span>

                            </label>
                            <p className={classes.marginTopTen}>{minUserType ? minUserType : "--" }</p>
                          </Box>
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <Box className={classes.Formcol2}>
                            <label>Chronic Condition Min<span style={{ color: "#ff0000" }}>*</span>

                            </label>
                            <p className={classes.marginTopTen}>
                              {viewData.min_num_conditions}
                            </p>
                          </Box>
                        </Grid>
                        <Grid item xs={1} md={1}></Grid>
                        <Grid item xs={3} md={3}>
                          <Box className={classes.checkList}>
                            <label>Initial visit</label>
                            {viewData.initial_visit_required ? (
                              <p className={classes.marginTopTen}>Yes</p>
                            ) : (
                              <p className={classes.marginTopTen}>No</p>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                        <label>CarePlan Attributes</label>
                            {viewData.careplan ? (
                              <ul>
                                {viewData.careplan.map((each,index,i) => {
                                  return (
                                    <li key={index} style={{ listStyleType: "square" }}>
                                      {/* {each[Object.keys(each)]}  */}
                                      {each.attribute_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <ul>
                                <li style={{ listStyleType: "none" }}>None</li>
                              </ul>
                            )}
                          {/* <Box className={classes.Formcol2}>
                            <label>CarePlan Template</label>
                            <Select options={options} onChange={(values) => this.setValues(values)} placeholder="CCM " className={classes.select} style={{width:'200px'}} />
                        </Box>  */}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <FormGroup aria-label="position" row></FormGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProgramTemplate;
const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  selectMax: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  ProgramTable:{
    width:'100%',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      overflowX:'auto'
    }
  },
  marginTopTen: {
    marginTop: "10px !important",
    fontSize: "15px !important",
  },
  SubmitAdd: {
    background: "#1612C6",
    borderRadius: 50,
    height: 30,
    minWidth: 30,
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: "600",
    width: 30,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    "&:hover": {
      background: "#0A70E8",
    },
  },
  removebtn: {
    background: "#DF0909",
    borderRadius: 50,
    height: 30,
    color: "#fff",
    fontFamily: "Poppins",
    width: 30,
    minWidth: 30,
    fontWeight: "600",
    fontSize: 20,
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      background: "#0A70E8",
    },
  },
  Formrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  Throw: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  Tdrow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  Throw1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 5,
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
    }
  },
  Tdrow1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    padding: "10px 0",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
    }
  },
  Thcol: {
    width: "39.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Thcol2: {
    width: "24.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Thcol3: {
    width: "24.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Thcol4: {
    width: "9.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Thcol5: {
    width: "24.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
  },
  Thcol6: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
  },
  Thcol7: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
  },
  Thcol8: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
  },
  Thcol9: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
  },
  Thcol10: {
    width: "19.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
  },
  Thcol11: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%',
    color: "#696969",
    // display: "flex",
    // justifyContent: "flex-end",
    textAlign: "center"
  },
  Tdcol: {
    width: "39.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol2: {
    width: "24.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol3: {
    width: "24.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol4: {
    width: "9.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol5: {
    width: "24.5%",
    wordWrap: "break-word",
    overflow: "auto",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol6: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol7: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol8: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol9: {
    width: "13.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol10: {
    width: "19.5%",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  Tdcol11: {
    width: "13.5%",
    display: "flex",
    justifyContent: "flex-end",
    fontFamily:'poppins',
    fontSize:14,
    paddingRight:'0.5%'
  },
  ModalTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  checkCol: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 40,
  },
  checkList: {
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    "& label": {
      flex: "0 0 160px !important",
      color: "#141621",
      marginTop: "0px !important",
    },
  },
  ActionBtn: {
    borderRadius: 10,
    textTransform: "capitalize",
    background: "#0A70E8",
    color: "#fff",
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  EditBtn: {
    borderRadius: 10,
    textTransform: "capitalize",
    background: "#E8740A",
    color: "#fff",
    marginRight: 10,
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  TopHeading: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: "600",
  },
  addprovider: {
    fontSize: 16,
    textTransform: "capitalize",
    color: "#7087A7",
    "&:hover": {
      background: "none",
      color: "#000",
    },
  },
  btncol: {
    marginTop: 15,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 20,
  },
  icon: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "10px",
  },
  backarrow: {
    color: "#8088A8",
    fontSize: "20px",
    marginRight: "8px",
  },
  Formcol: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
      marginTop: 10,
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  Formcol2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "5px",
    "& label": {
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
  },
  Formcol3: {
    display: "flex",
    width: "32%",
    // justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: "5px",
    '& p':{
      textAlign:'left',
      fontSize:12,
      height:20,
    },
    "& label": {
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
      textAlign:'left',
    },

  },

  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "776px",
      maxWidth: "776px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },

    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  textarea:{
    width:'100%',
    borderRadius:'10px',
        background:'#F9F9F9',
        border:'1px solid #D5D5D5',
        padding:'10px 15px',
        fontFamily:'Poppins',
        fontSize:'12px'
},
}));
