import React, { useState, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import { BiArrowBack, BiXCircle } from "react-icons/bi";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import FormGroup from "@material-ui/core/FormGroup";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { apiAxios } from "../Util/ApiAxios";
import moment from "moment";
import TopHeading from '../components/TopHeading'
import LeftNav from '../components/LeftNav'
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  checkName,
  checkEmail,
  checkNumbers,
  checkPhone,
  checkPhoneNumbers,
  checkNumbersOnly,
  checkDob,
  checkZip,
  checkUSPhone
} from "../Util/validations";

import { stateList } from "../Util/DropDownData";
import { FaGlasses } from "react-icons/fa";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PatientSearch({ options }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [tablemessage, setTableMessage] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);

  const [search, setSearch] = useState("");
  const [provider, setProvider] = useState("");
  const [program, setProgram] = useState("");

  const location = useLocation();
  const client_id = location.state?.client_id;
  const client_name = location.state?.client_name;

  const [value2, onChange2] = useState(new Date());
  const [emrid, setEmrID] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [patientID, setPatientID]= React.useState("");
  // const [patientReport, setPatientReport] = useState("");

  //Error States
  const [emrError, setEmrError] = useState(false);
  const [firstnameError, setFirstNameError] = useState(false);
  const [lastnameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [zipError, setZipError] = useState(false);
  // loader
  const [ifLoading, setIfLoading] = useState(false);

  console.log("test - ", location.state);

  

  const getPatientList = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let data = {
        client_id: client_id,
        // program_id: "",
        // provider_id: "",
        search_value: search ? search : "",
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
      };

      setTableMessage("Loading...");

      apiAxios
        .post("/patient/list-patient-demographics", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("patient list data --> ", response);
          if (response.data.statusCode == 200) {
            setPatientList(response.data.data);
            setTotalPages(response.data.total_pages);

            if (response.data.data.length == 0) {
              setTableMessage("No Data Found");
            }
          } else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
            setPatientList([])
            setTotalPages(1)
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error("Something Went Wrong!", {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          
        });
    }
  );

  function onSearchSubmit() {
    getPatientList();
  }

  function submitForm(flag) {
    let isValid = true;

    console.log("submit form");
    console.log(city.length)
    console.log(emrid.length)

    if (!checkName(firstname.trim())) {
      setFirstNameError(true);
      isValid = false;
      console.log("First Error if= ", firstnameError);
    } else {
      setFirstNameError(false);
    }
    console.log("First Error = ", firstnameError);

    if (!checkName(lastname.trim())) {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }
    if (emrid.trim().length >0 && emrid.trim().length <=255) {
      setEmrError(false);
    } else {
      setEmrError(true);
      isValid = false;
    }

    if (!checkEmail(email) && email.length > 0) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    if (!checkUSPhone(phone)) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }
    console.log("Age no sub = ", parseInt(checkDob(value2)));
    if (parseInt(checkDob(value2)) < 40 || parseInt(checkDob(value2)) >200  || isNaN(parseInt(checkDob(value2)))) {
      setDobError(true);
      isValid = false;
    } else {
      setDobError(false);
    }
    if (address.trim().length > 0) {
      setAddressError(false);
    } else {
      setAddressError(true);
      isValid = false;
    }
    if (state) {
      setStateError(false);
    } else {
      setStateError(true);
      isValid = false;
    }
    if (city.trim().length > 0 && city.trim().length <=255) {
      setCityError(false);
    } else {
      setCityError(true);
      isValid = false;
    }
    if (checkZip(zip)) {
      setZipError(false);
    } else {
      setZipError(true);
      isValid = false;
    }

    if (isValid) {
      setIfLoading(true);
      if(flag === 1){
      let data = {
        client_id: client_id,
        emr_id: emrid,
        patient_first_name: firstname,
        patient_last_name: lastname,
        patient_mailing_address: address,
        patient_city: city,
        patient_state: state[0]? state[0].label: "",
        patient_zip: zip,
        patient_email: email,
        patient_dob: moment(value2).format("YYYY-MM-DD"),
        patient_primary_phone: phone.replace(/[^0-9]/g, ''),
      };
    
      apiAxios
        .post("/patient/create-patient", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setOpen(false);
            setIfLoading(false);
            const timer = setTimeout(() => {
              window.location.href = "/patient";
            }, 2000);
       


          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });

      }else {
       
          let data = {
            client_id: client_id,
            patient_id : patientID,
            emr_id: emrid,
            patient_first_name: firstname,
            patient_last_name: lastname,
            patient_mailing_address: address,
            patient_city: city,
            patient_state: state.label? state.label : state[0].label,
            patient_zip: zip,
            patient_email: email,
            patient_dob: moment(value2).format("YYYY-MM-DD"),
            patient_primary_phone: phone.replace(/[^0-9]/g, ''),
          };
        
          apiAxios
            .post("/patient/update-patient-demographics", data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("admin_Token"),
              },
            })
            .then((response) => {
              console.log("response --> ", response);
              if (response.data.statusCode == 200) {
                toast.success(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setOpen(false);
                setIfLoading(false);
                const timer = setTimeout(() => {
                  window.location.href = "/patient";
                }, 2000);
           
    
    
              } else {
                toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setIfLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
    
              toast.error("Something Went Wrong!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setIfLoading(false);
            });
        
    
      }

    }
  }




  useEffect(() => {
    getPatientList();
   
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //  modal  //
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);

  const handleClickOpen = () => {
  
    setOpen(true);
  };

  const handleClose = () => {
   
    setOpen(false);

  
  };

  // update open

  const handleUpdateOpen= (data)=>{

    

  
    
      setFirstName(data.patient_first_name);
      setLastName(data.patient_last_name);
      onChange2(data.patient_dob);
      setAddress(data.patient_mailing_address);
      setCity(data.patient_city);
      setState(data.patient_state);
      setPatientID(data.id)
      
    let stateCatList = stateList();
  
    for(var i=0; i<stateCatList.length; i++){

      let str = data.patient_state
 

    const words = str.split(" ");

     for (let j = 0; j < words.length; j++) {
      words[j] = words[j][0].toUpperCase() + words[j].substr(1);
      }
  
         let newWords = words.join(' ');
          console.log(newWords)


        if(stateCatList[i].label ==  newWords){
          console.log(stateCatList[i])
        
          setState(stateCatList[i]);
          console.log(state)
        }
  

      }   



      setZip(data.patient_zip);
      setEmail(data.patient_email);
  
      setPhone(formatPhoneNumber(data.patient_primary_phone));
      
      setEmrID(data.emr_id);


    setOpenUpdate(true);

  }

  // update close 

  const handleUpdateClose = () => {

    setOpenUpdate(false);

    setFirstName("");
    setLastName("");
    setAddress("");
    setCity("");
    setState("");
    setZip("");
    setEmail("");
    onChange2("");
    setPhone("");
    setEmrID("");
  };

  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhone(formattedPhoneNumber);
  };


  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;


  }




  return (
    <div>
      <Header />
      <Box className={classes.Pagecontent}>
      <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                     
                 </Box>
                 <Box className={classes.leftHeading}>
                 <TopHeading />
                 </Box>
                 {/* left Accordion*/}
                <LeftNav />
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               </Box>
               

        {/* right col */}
        <Box className={classes.Rightcol}>
          <Link to="clients">
            <Button className={classes.backBtn}>
              <BiArrowBack className={classes.backarrow} />
            </Button>
          </Link>
          <br />
          <Typography style={{marginBottom:40,fontFamily:'Poppins',fontSize:18,color:'#696969'}}>Client: <b>{client_name}</b></Typography>
          <Box className={classes.Topfilter}>
            
            {/* <Box style={{ marginRight: 15 }}>
              <label>Provider Filter</label>
              <Select
                options={options}
                onChange={(values) => this.setValues(values)}
                placeholder="Select Provider"
                className={classes.select}
                style={{ width: "200px" }}
              />
            </Box> */}
            {/* <Box style={{ marginRight: 15 }}>
              <label>Program Filter</label>
              <Select
                options={options}
                onChange={(values) => this.setValues(values)}
                placeholder="Select Program "
                className={classes.select}
                style={{ width: "200px" }}
              />
            </Box> */}
            <Box className={classes.SearchCol}>
              <Box style={{ width: "60%" }}>
                <label>Patient Search</label>
                <TextField
                  className={classes.input}
                  placeholder="Patient Search"
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              
              <Button className={classes.Searchbtn} onClick={onSearchSubmit}>
                Search
              </Button>
              
            </Box>
            
          </Box>
          {search ? (
                <Typography variant="h6">
                  Search Result for '{search}'
                </Typography>
              ) : (
                <Typography variant="h6"></Typography>
              )}
          {/* filter end */}
          <Box className={classes.PatientTable}>
            <Box className={classes.PatientTop}>
           
              {/* {search ? (
                <Typography variant="h6">
                  Search Result for '{search}'
                </Typography>
              ) : (
                <Typography variant="h6"></Typography>
              )} */}
              
              <h2 style={{textAlign:"center"}}>Patient List</h2>
              <Button className={classes.Addbtn} onClick={handleClickOpen}>
                Add New Patient
              </Button>
              
            </Box>
           <Box className={classes.PatientListtable}>
            <Box className={classes.Throw}>
              {/* <Box className={classes.Thcol}>
                <label>Provider</label>
              </Box> */}
              <Box className={classes.Thcol2}>
                <label>First Name</label>
              </Box>
              <Box className={classes.Thcol3}>
                <label>Last Name</label>
              </Box>
              <Box className={classes.Thcol4}>
                <label>DOB</label>
              </Box>
              <Box className={classes.Thcol5}>
                <label>City</label>
              </Box>
              <Box className={classes.Thcol6}>
                <label>Email</label>
              </Box>
              <Box className={classes.Thcol7}>
                <label>Phone</label>
              </Box>
              <Box className={classes.Thcol8}>
                <label>Status</label>
              </Box>
              <Box className={classes.Thcol9}>
                <label>HRPM ID</label>
              </Box>
              <Box className={classes.Thcol11}>
                <label>EMR ID</label>
              </Box>
              <Box className={classes.Thcol10}>
                <label>Action</label>
              </Box>
              {/* <Box className={classes.Thcol11}>
                <label>Finalize Date</label>
              </Box> */}
              {/* <Box className={classes.Thcol12}>
                <label>Status</label>
              </Box> */}
              {/* <Box className={classes.Thcol13}>
                <label>Careplan</label>
              </Box> */}
            </Box>
            {patientList.length > 0 ? (
              <>
                {patientList.map((each) => (
                  <Box className={classes.Tdrow}>
                    {/* <Box className={classes.Thcol}>--</Box> */}
                    <Box className={classes.Thcol2}>
                      {each.patient_first_name}
                    </Box>
                    <Box className={classes.Thcol3}>
                      {each.patient_last_name}
                    </Box>
                    <Box className={classes.Thcol4}>{each.patient_dob}</Box>
                    <Box className={classes.Thcol5}>{each.patient_city}</Box>
                    <Box className={classes.Thcol6}>{each.patient_email}</Box>
                    <Box className={classes.Thcol7}>
                      {formatPhoneNumber(each.patient_primary_phone)}
                    </Box>
                    {/* <Box className={classes.Thcol8}>
                      <Link to="">--</Link>
                    </Box> */}
                    <Box className={classes.Thcol8}>{(each.status == 1) ? "Active" : "Inactive"}</Box>
                    {/* <Box className={classes.Thcol10}>Admin DHCT</Box> */}
                    {/* <Box className={classes.Thcol11}>--</Box> */}
                    {/* <Box className={classes.Thcol12}>
                      <Link to="">Active</Link>
                    </Box> */}
                    <Box className={classes.Thcol9}>{each.hrpm_id}</Box>
                    <Box className={classes.Thcol11}>{each.emr_id}</Box>
                    <Box className={classes.Thcol10}>
                      
                      <Button className={classes.Downloadbtn} onClick={()=>handleUpdateOpen(each)}>
                       Edit
                      </Button>
                      
                    </Box>
                    
                  </Box>
                ))}
              </>
            ) : (
              <Box className={classes.providerrow}>
                <Box
                  className={classes.tdcol12}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "5%",
                    color: "#dfdfdf",
                  }}
                >
                  {/* {tablemessage} */}
                  No Patient Data Available
                </Box>
              </Box>
            )}
            </Box>
          </Box>
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={(event, pageNumber) =>
                getPatientList({
                  table_config_page_no: `${pageNumber}`,
                })
              }
            />
          </div>

          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Box>
        {/* modal */}
        <Dialog
          className={classes.modal}
          open={open}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <br />
              <Box className={classes.loginform}>
                <h3 className={classes.Toptext}>Add New Patient</h3>
                <br />
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                        <label>
                          Patient EMR ID
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Patient EMR ID"
                          type="text"
                          value={emrid}
                          onChange={(e) =>  setEmrID(e.target.value)}
                           
                        />
                      </Box>
                      {emrError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Emr ID is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter First Name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          First name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Last Name"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Last name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol} >
                        <label>
                          Date of Birth
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                          onChange={onChange2}
                          value={value2}
                          className={classes.dateInput}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                          
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Age should be between 40 to 200 years.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Email</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Phone Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) => {
                            // if(checkPhoneNumbers(e.target.value))
                            // setPhone(e.target.value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'))
                            handleInput(e)
                          }
                          }
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid phone number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Address<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </Box>
                      {addressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Address is required.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          State<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={stateList()}
                          value={state}
                          onChange={setState}
                          placeholder="Select State"
                          className={classes.select}
                        />
                      </Box>
                      {stateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please select a state.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          City<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City"
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Box>
                      {cityError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          City is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Zipcode<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="tel"
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                        />
                      </Box>
                      {zipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                         (4 to 6) digit Zipcode is required.
                        </p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Box>
             
            </DialogContentText>
          </DialogContent>
      
          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            { !ifLoading ?
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={() => submitForm(1)}
            >
              Save
            </Button>
            :
                  <ThreeDots

                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    />
            }
          </DialogActions>

        </Dialog>

        {/* update modal */}
        <Dialog
          className={classes.modal}
          open={openUpdate}
          TransitionComponent={Transition}
          disableEnforceFocus={true}
          keepMounted
          onClose={handleUpdateClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleUpdateClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <br />
              <Box className={classes.loginform}>
                <h3 className={classes.Toptext}>Update Patient</h3>
                <br />
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                        <label>
                          Patient EMR ID
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Patient EMR ID"
                          type="text"
                          value={emrid}
                          onChange={(e) =>  setEmrID(e.target.value)}
                           
                        />
                      </Box>
                      {emrError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Emr ID is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter First Name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          First name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Last Name"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Last name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol} >
                        <label>
                          Date of Birth
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                          // onChange={onChange2}
                          onChange={(date)=>onChange2(date)}
                          // value={value2}
                          value={value2? new Date(value2):  null}
                          className={classes.dateInput}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Age should be between 40 to 200 years.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Email</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Phone Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) =>{
                            // if(checkPhoneNumbers(e.target.value)){
                            //   setPhone(e.target.value)
                            // }

                            handleInput(e)
                          
                          }
                            }
                           
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid phone number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Address<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </Box>
                      {addressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Address is required.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          State<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={stateList()}
                          value={state? state : ""}
                          onChange={setState}
                          placeholder={state? state.label : "Select State"}
                          className={classes.select}
                        />
                      </Box>
                      {stateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please select a state.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          City<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City"
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Box>
                      {cityError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          City is required (max char length 255)
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Zipcode<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="tel"
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                        />
                      </Box>
                      {zipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                        (4 to 6) digit Zipcode is required.
                        </p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Box>
             
            </DialogContentText>
          </DialogContent>
      

         

          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            { !ifLoading ?
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={() => submitForm(2)}
            >
              Save
            </Button>
            :
                  <ThreeDots

                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    />
            }
          </DialogActions>

        </Dialog>

        


      </Box>
    </div>
  );
}

export default PatientSearch;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  SearchCol:{
    display: "flex", flexDirection: "row", width: "50%",
    '@media only screen and (max-width: 1200px)':{
      width: "100%",
     },
     '@media only screen and (max-width: 820px)':{
      width: "100%",
     },
    '@media only screen and (max-width: 768px)':{
      width: "100%",
     },
  },
  leftHeading:{
    '@media only screen and (max-width: 820px)':{
      display:'none'
     },
    '@media only screen and (max-width: 768px)':{
      display:'none'
     },
  },
  BottomCol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  Toptext: {
    fontSize: 20,
    marginTop: 0,
    fontWeight: "600",
    fontFamily: "Poppins",
    color: "#121212",
  },
  PatientTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 40,
  },
  Throw: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px #ccc solid",
    paddingBottom: 7,
    fontSize: 14,
    '@media only screen and (max-width: 1200px)':{
      width:1200,
    },
    '@media only screen and (max-width: 820px)':{
      width:1200,
    },
    '@media only screen and (max-width: 768px)':{
      width:1200,
    },
    "& label": {
      color: "#919699",
    },
  },
  Thcol: {
    width: "8%",
  },
  Thcol2: {
    width: "10%",
    wordWrap: "break-word",
    overflow: "auto",
  },
  Thcol3: {
    width: "10%",
    wordWrap: "break-word",
    overflow: "auto",
  },
  Thcol4: {
    width: "12%",
  },
  Thcol5: {
    width: "10%",
    wordWrap: "break-word",
    overflow: "auto",
  },
  Thcol6: {
    width: "16%",
    wordWrap: "break-word",
    overflow: "auto",
  },
  Thcol7: {
    width: "13%",
  },
  Thcol8: {
    width: "8%",
  },
  Thcol9: {
    width: "5%",
    // textAlign:"left"
    '@media only screen and (max-width: 820px)':{
      width: "9%",
    },
    '@media only screen and (max-width: 768px)':{
      width: "9%",
    }
  },
  // Tdcol9: {
  //   width: "25%",
  // },
  Thcol10: {
    width: "6%",
    
  },
  Thcol11: {
    width: "10%",
    wordWrap: "break-word",
  },
  Thcol12: {
    width: "5%",
  },
  Thcol13: {
    width: "10%",
  },
  
  Tdrow: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px #ccc solid",
    padding: "10px 0",
    fontSize: 14,
    '@media only screen and (max-width: 1200px)':{
      width:1200,
    },
     '@media only screen and (max-width: 768px)':{
      width:1200,
    },
  },
  Searchbtn: {
    background: "#7087A7",
    borderRadius: 10,
    display: "flex",
    color: "#fff",
    height: 50,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    textTransform: "capitalize",
    marginTop: 28,
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  Downloadbtn: {
    background: "#0A70E8",
    borderRadius: 10,
    display: "flex",
    // alignItems:"center",
    // flexDirection: "column",
    // justifyContent: "center",
    color: "#fff",
    height: 35,
    fontSize: 14,
    // paddingLeft: 15,
    // paddingRight: 15,
    textTransform: "capitalize",

    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  ViewAll: {
    background: "#7087A7",
    borderRadius: 10,
    display: "flex",
    color: "#fff",
    height: 40,
    fontSize: 14,
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  Addbtn: {
    background: "#1612C6",
    borderRadius: 10,
    display: "flex",
    color: "#fff",
    height: 40,
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 30,
    height: 20,
    "&:hover": {
      background: "none",
    },
  },
  ModalTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  dateInput: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  Topfilter: {
    width: "100%",
    display: "flex",
    marginTop: 30,
    marginBottom: 30,
    flexDirection: "row",
    "& label": { color: "#666", marginBottom: 5, display: "flex" },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0074D9 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  pageTop: {
    textAlign: "left",
    marginBottom: "40px",
    display: "flex",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },

  backarrow: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "8px",
  },
  Leftcol: {
    width: "15%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "700px",
    '@media only screen and (max-width: 1200px)':{
      width: "25%",
    },
    '@media only screen and (max-width: 820px)':{
      position: "absolute",
    },
    '@media only screen and (max-width: 768px)':{
      position: "absolute",
    }
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    '@media only screen and (max-width: 820px)':{
      display: "none",
    },
    '@media only screen and (max-width: 768px)':{
      display: "none",
    }
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
    '@media only screen and (max-width:820px)':{
      left: "15px",
    },
    '@media only screen and (max-width: 768px)':{
      left: "15px",
    }
  },
  Rightcol: {
    width: "100%",
    padding: "20px 2%",
    borderLeft: "1px #ededed solid",
    '@media only screen and (max-width: 1200px)':{
      width: "70%",
    },
    '@media only screen and (max-width: 820px)':{
      width: "94%",
      padding: "20px 3%",
    },
    '@media only screen and (max-width: 768px)':{
      width: "94%",
      padding: "20px 3%",
    },
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #E3E5E5 solid",
      height: "40px",
      color: "#919699",
      padding: "0px",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& .MuiTab-root": {
      minWidth: "18%",
    },
    "& .MuiTab-root:nth-child(6)": {
      minWidth: "30%",
    },
    "& .MuiTab-root:nth-child(7)": {
      minWidth: "30%",
    },
    "& .MuiBox-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "& .MuiTab-root": {
      minHeight: "40px",
    },
    "& .MuiTabs-root": {
      minHeight: "40px",
    },
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },

  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
  },
  downloadModal : {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "950px",
      maxWidth: "950px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
    "& label": {
      color: "#666",
      marginBottom: 5,
      display: "flex",
      flex: "0 0 150px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0074D9 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  PatientListtable:{
    width:'100%',
    '@media only screen and (max-width: 1200px)':{
      overflowX:'auto'
    },
    '@media only screen and (max-width: 820px)':{
      overflowX:'auto'
    },
    '@media only screen and (max-width: 768px)':{
      overflowX:'auto'
    }
  }
}));
