import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import { BiArrowBack } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";
import Slide from "@material-ui/core/Slide";
import ManageAdminUser from "../components/ManageAdminUser";
import AdminDashboard from "../components/AdminDashboard";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import profile from "../images/profile-image.png";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { BiCamera } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/core/SwitchUnstyled";
import TopHeading from "../components/TopHeading";
import LeftNav from "../components/LeftNav";
import { Link } from "react-router-dom";
import { clientTypeList, passChangeFreqList, stateList, } from "../Util/DropDownData";
import {
  checkName,
  checkEmail,
  checkNumbers,
  checkPhone,
  checkPhoneNumbers,
  checkNumbersOnly,
  checkZip,
  checkClientZip,
  checkOnlyZero,
  checkAlphaNumeric,
  checkUSPhone
} from "../Util/validations";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiAxios } from "../Util/ApiAxios";
import { set } from "date-fns";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Input = styled("input")({
  display: "none",
});
const Root = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
  margin: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #e1dddd;
    border-radius: 40px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 15px;
    height: 15px;
    top: 3px;
    left: 3px;
    border-radius: 40px;
    background-color: #10c20c;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: #e1dddd;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 27px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #e1dddd;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
function AddNewClients(props, { options }) {
  const classes = useStyles();
  const [form2data, setForm2data] = useState({});
  const [form3data, setForm3data] = useState({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [clientType, setClientType] = useState("");
  // const [clientId, setClientId] = useState("");
  const [website, setWebsite] = useState("");
  const [npi, setNpi] = useState("");
  // const [passChangeFreq, setPassChangeFreq] = useState("90");

  const [passChangeFreq, setPassChangeFreq] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [billAddress, setBillAddress] = useState("");
  const [billState, setBillState] = useState("");
  const [billCity, setBillCity] = useState("");
  const [billZip, setBillZip] = useState("");
  const [checked, setChecked] = useState(false);
  const [imgData, setImgData] = useState("")
  const [ifLoading, setIfLoading] = useState(false);
  //Error States
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  //const [clientTypeError, setClientTypeError] = useState(false);
  // const [clientIdError, setClientIdError] = useState(false);
  const [npiError, setNpiError] = useState(false);
  const [clientTypeError, setClientTypeError] = useState(false);
  const [zipError, setZipError] = useState(false)
  const [billZipError, setBillZipError] = useState(false)
  const [logo, setLogo] = useState()
  const [preview,setPreview] = useState()



  function onNextClick() {

    console.log(passChangeFreq)

    console.log(state)
    console.log(billState)

    let isvalid = true;

    if (!checkName(name.trim())) {
      setNameError(true);
      isvalid = false;
    } else {
      setNameError(false);
    }
    if (!checkUSPhone(phone)) {
      setPhoneError(true);
      isvalid = false;
    } else {
      // if (checkOnlyZero(phone)) {
      //   setPhoneError(true);
      //   isvalid = false;
      // } else {
      //   setPhoneError(false);
      // }
      setPhoneError(false);
    }

    if (!checkEmail(email)) {
      setEmailError(true);
      isvalid = false;
    } else {
      setEmailError(false);
    }
    // if (!(clientId.length > 0)) {
    //   setClientIdError(true);
    //   isvalid = false;
    // } else {
    //   setClientIdError(false);
    // }
    if (!checkNumbersOnly(npi)) {
      setNpiError(true);
      isvalid = false;
    } else {
      setNpiError(false);
    }

    if(clientType){
      setClientTypeError(false)
    }else {
      setClientTypeError(true)
      isvalid = false;
    }

    if(zip){
      if(!checkZip(zip)){
        setZipError(true)
        isvalid = false
      }else {
        setZipError(false)
      }
    }else {
      setZipError(false)
    }

    if(billZip){
      if(!checkZip(billZip)){
        setBillZipError(true)
        isvalid = false
      }else {
        setBillZipError(false)
      }
    }else {
      setBillZipError(false)
    }





    if (isvalid) {
      props.history.push({
        pathname: "/new-clients-step3",
        state: {
          logo: imgSrc,
          form1data: {
            // client_id: clientId,
            client_npi_number: npi,
            name: name,
            client_name: name,
            client_type: clientType.value ? clientType.value : clientType[0].value,
            client_billing_address: billAddress,
            client_billing_city: billCity,
            client_billing_state: Array.isArray(billState) ?  billState[0].label : billState ,
            client_billing_zip: billZip,
            client_location_address: address,
            client_location_city: city,
            client_location_state:  state.label? state.label : state,
            client_location_zip: zip,
            client_phone: phone.replace(/[^0-9]/g,''),
            client_email: email,
            client_website: website,
            password_change_frequency: passChangeFreq.value ? passChangeFreq.value : passChangeFreq,
            checked: checked,
            image_name: imgData,
          },
          // form2data: form2data,
          form3data: form3data,
        },
      });
    }
  }

  function copyAddress(data) {
    console.log(state)
    console.log(billState)
    console.log(checked)
    setBillAddress(address);
    setBillState(state.label?state.label : state);
    setBillCity(city);
    setBillZip(zip);
    setChecked(data);
  }

  useEffect(() => {
    console.log("form1", props.history.location.state);
    if (props.history.location.state) {
      if (
        props.history.location.state.form1data &&
        Object.keys(props.history.location.state.form1data).length != 0
      ) {
        setName(props.history.location.state.form1data.client_name);
        setEmail(props.history.location.state.form1data.client_email);
        setPhone(formatPhoneNumber(props.history.location.state.form1data.client_phone));
        // setClientType(props.history.location.state.form1data.client_type);
        // setClientId(props.history.location.state.form1data.client_id);
        console.log("client type")
        console.log(props.history.location.state.form1data.client_type)

        let clientTypeVal = props.history.location.state.form1data.client_type
        let clientList = clientTypeList();
        console.log(clientList)
        for(var i=0; i<clientList.length; i++){

        if(clientList[i].value ==  clientTypeVal){
         
          console.log("client")
          console.log(clientList[i].label)
         
          setClientType(clientList[i]);
        }

      }
        console.log(clientType)

        setWebsite(props.history.location.state.form1data.client_website);
        setNpi(props.history.location.state.form1data.client_npi_number);

        // setPassChangeFreq(
        //   props.history.location.state.form1data.password_change_frequency
        // );

        let passwordChangeData = props.history.location.state.form1data.password_change_frequency

        let passwordChangeList = passChangeFreqList();

        for (var i=0; i< passwordChangeList.length; i++ ){

          if(passwordChangeList[i].value === passwordChangeData){

            setPassChangeFreq(passwordChangeList[i])
          }
        }






        setAddress(
          props.history.location.state.form1data.client_location_address
        );
        // setState(props.history.location.state.form1data.client_location_state);
        setCity(props.history.location.state.form1data.client_location_city);
        setZip(props.history.location.state.form1data.client_location_zip);
        setBillAddress(
          props.history.location.state.form1data.client_billing_address
        );

        // setBillState(
        //   props.history.location.state.form1data.client_billing_state
        // );

        console.log(props.history.location.state.form1data.client_billing_state)
      
        let stateListData = stateList()
        let stateData = props.history.location.state.form1data.client_location_state
        let billState = props.history.location.state.form1data.client_billing_state
        console.log(stateListData)
        console.log(props.history.location.state.form1data.client_location_state)
          console.log(props.history.location.state.form1data.client_billing_state)
          console.log(billState)
        for(var i=0; i<stateListData.length; i++){
          if(stateListData[i].label === stateData){
            console.log(stateListData[i])
            setState(stateListData[i])
          }
        }

        for(var i=0; i<stateListData.length; i++){
          if(stateListData[i].label === billState){
            console.log(stateListData[i])
            setBillState(stateListData[i].label)
          }
        }

       

        setBillCity(props.history.location.state.form1data.client_billing_city);
        setBillZip(props.history.location.state.form1data.client_billing_zip);
        setChecked(props.history.location.state.form1data.checked);
      }
      if (
        props.history.location.state.form2data &&
        Object.keys(props.history.location.state.form2data).length != 0
      ) {
        setForm2data(props.history.location.state.form2data);
      }
      if (
        props.history.location.state.form3data &&
        Object.keys(props.history.location.state.form3data).length != 0
      ) {
        setForm3data(props.history.location.state.form3data);
      }


      if(props.history.location.state.logo){
        console.log(props.history.location.state.logo)
        setImgSrc(props.history.location.state.logo)
       
      }

      if(props.history.location.state.form1data){
        setImgData(props.history.location.state.form1data.image_name)
       }

    }

  //  console.log(props.history.location.state.form1data.image_name)
  
      
    
  }, [props.history.location.state]);

  const label = { componentsProps: { input: { "aria-label": "Demo switch" } } };

  // client logo upload base 64 conversion code

  // useEffect(()=>{
  //   // if(!logo){
  //   //   setLogo(undefined)
  //   //   return
  //   // }
  //   // const objectURl = URL.createObjectURL(logo)
  //   // console.log(objectURl)
  //   // setPreview(objectURl)
  //   // return()=> URL.revokeObjectURL(objectURl)


  // },[])
 

  let base64code = ""

  const onChange = e => {
    const files = e.target.files;
   
    const file = files[0];
    setLogo(file)
    
    getBase64(file);
  };

  const [imgSrc, setImgSrc]= useState("")
 
  const onLoad = fileString => {

    base64code = fileString
   
    setImgSrc(base64code)

    let finalImg = base64code.substr(base64code.indexOf(",")+1)
    console.log(finalImg);
    setImgData(finalImg)
  };
 
  const getBase64 = file => {

   
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhone(formattedPhoneNumber);
  };


  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;


  }

 


  return (
    <div>
       <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                  
      <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
          <Box className={classes.leftHeading}>
            <TopHeading />
            </Box>
            <Box
              className={classes.pageTop}
              style={{ marginBottom: "40px" }}
            ></Box>
            {/* left Accordion*/}
            <LeftNav />
            <Box className={classes.bottomnav}>
              <Setting />
            </Box>
          </Box>
        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Link to="/clients">
                <Button className={classes.backBtn}>
                  <BiArrowBack className={classes.backarrow} />
                </Button>
              </Link>
              
             
              <Box className={classes.providerlist}>
                <Typography variant="h3" className={classes.topheading}>
                  Add New Client
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    color: "#696969",
                    fontStyle: "italic",
                  }}
                >
                  / Step 1
                </Typography>
                {/* <Typography variant="p" className={classes.Righttext}>General Informations</Typography> */}
              </Box>
              <Box className={classes.loginform}>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5" className={classes.FormHeading}>
                        General Information
                      </Typography>

                      <Box className={classes.Formcol}>
                        <label>
                          Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Name of Client"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                      {nameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid name.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Phone<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) => {
                            // if (checkPhoneNumbers(e.target.value)) {
                            //   setPhone(e.target.value);
                            // }
                            handleInput(e)
                          }}
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Email<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Email Address"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Client Type<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={clientTypeList()}
                          onChange={setClientType}
                         
                          
                          className={classes.select}
                          style={{width:'100%'}} 
                          
                          value={clientType? clientType : ""}
                          placeholder={clientType ? clientType.label : "Select Client Type"}
                         
                        />
                      </Box>
                      {clientTypeError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please select client type
                        </p>
                      ) : (
                        <></>
                      )}
                      {/* <Box className={classes.Formcol}>
                        <label>
                          Client ID<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Client ID"
                          type="text"
                          value={clientId}
                          onChange={(e) => {
                            if (checkAlphaNumeric(e.target.value)) {
                              setClientId(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      {clientIdError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid Id.
                        </p>
                      ) : (
                        <></>
                      )} */}
                      <Box className={classes.Formcol}>
                        <label>Client Website (if any)</label>
                        <TextField
                          className={classes.input}
                          placeholder="example: www.dhct.com"
                          type="text"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                          Client NPI Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Client NPI Number"
                          type="text"
                          value={npi}
                          onChange={(e) => {
                            if (checkPhoneNumbers(e.target.value)) {
                              setNpi(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      {npiError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid NPI number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>Password Change Freq</label>
                        <Select
                          options={passChangeFreqList()}
                          // onChange={(values) =>
                          //   setPassChangeFreq(values[0].value)
                          // }

                          onChange={(values)=>setPassChangeFreq(values[0].value)}
                            
    
                          placeholder={passChangeFreq ? passChangeFreq.label : "Select Password Change Frequency"}
                          className={classes.select}
                          style={{ width: "100%" }}
                         
                          value={passChangeFreq?  passChangeFreq.value : ""}
                        />
                      </Box>
                      
                      {/* Client Logo */}

                      <Box className={classes.Formcol}>
                        <label>Client Logo</label>

                       <form>
                       <input type="file" onChange={onChange} /> <br />
                       
                       {/* <textarea className={classes.input}></textarea> */}
                       {/* <Button className={classes.uploadBtn} onClick={imgUpload}>Upload</Button> */}
                       </form>
                       {imgSrc?
                      <Box className={classes.profileLogo2}>

                       <img src={imgSrc}  />
                       </Box>
                      : <></>
                       }
                       
                      </Box>


                        






                      <Typography variant="h5" className={classes.FormHeading}>
                        Client Address (Location)
                      </Typography>
                      <Box className={classes.Formcol}>
                        <label>Client Address</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={address}
                          onChange={(e) => {
                            if (checked) {
                              setAddress(e.target.value);
                              setBillAddress(e.target.value);
                            } else {
                              setAddress(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                          State
                        </label>
                        <Select
                          options={stateList()}
                          value={state? state.label : ""}
                          // onChange={setState}
                          placeholder={state? state.label : "Select State"}
                          className={classes.select}
                          onChange={(values) => {
                            if (checked) {
                              setState(values[0].label);
                              setBillState(values[0].label);
                            } else {
                              setState(values[0].label);
                              
                            }
                          }}
                         
                        />
                      </Box>
                      {/* <Box className={classes.Formcol}>
                        <label>State</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter State here"
                          type="text"
                          value={state}
                          onChange={(e) => {
                            if (checked) {
                              setState(e.target.value);
                              setBillState(e.target.value);
                            } else {
                              setState(e.target.value);
                            }
                          }}
                        />
                      </Box> */}
                      <Box className={classes.Formcol}>
                        <label>City</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City here"
                          type="text"
                          value={city}
                          onChange={(e) => {
                            if (checked) {
                              setCity(e.target.value);
                              setBillCity(e.target.value);
                            } else {
                              setCity(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Zipcode</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="text"
                          style={{ width: 200 }}
                          value={zip}
                          onChange={(e) => {
                            if (checked) {
                              if (e.target.value){
                                setZip(e.target.value);
                                setBillZip(e.target.value);
                              }
                            } else {
                              if (e.target.value) {
                                setZip(e.target.value);
                              }
                            }
                          }}
                        />
                      </Box>
                      {zipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid zip(4 to 6 digits)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label
                          style={{ flex: "0 0 260px", paddingTop: "20px" }}
                        >
                          Billing & Location Address Same
                        </label>
                        <label style={{ paddingTop: "20px" }}>
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={(e) => copyAddress(e.target.checked)}
                          />
                          Yes
                        </label>
                      </Box>
                      <Typography variant="h5" className={classes.FormHeading}>
                        Client Billing Address
                      </Typography>
                      <Box className={classes.Formcol}>
                        <label>Client Address</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={billAddress}
                          disabled={checked}
                          onChange={(e) => setBillAddress(e.target.value)}
                        />
                      </Box>
                      {/* <Box className={classes.Formcol}>
                        <label>State</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter State here"
                          type="text"
                          value={billState}
                          disabled={checked}
                          onChange={(e) => setBillState(e.target.value)}
                        />
                      </Box> */}
                      <Box className={classes.Formcol}>
                        <label>
                          State
                        </label>
                        <Select
                          options={stateList()}
                          value={billState ? billState : ""}
                          onChange={setBillState}
                          placeholder={billState? billState : "Select State"}
                          className={classes.select}
                          disabled={checked}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>City</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City here"
                          type="text"
                          value={billCity}
                          disabled={checked}
                          onChange={(e) => setBillCity(e.target.value)}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Zipcode</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="text"
                          style={{ width: 200 }}
                          value={billZip}
                          disabled={checked}
                          onChange={(e) => {
                            if (e.target.value) {
                              setBillZip(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      {billZipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid zip(4 to 6 digits)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Btncol}>
                        <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={(e) => onNextClick()}
                        >
                          {" "}
                          Next{" "}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            {/* <Grid item xs={4}>
                    <Box className={classes.ProfileCol}>
                        <Box className={classes.ProfileImg}>
                        <img src={profile} alt="profile images" />
                        </Box>
                        <Box className={classes.UploadBtn}>
                        <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" type="file" />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <BiCamera color="#121212" />
        </IconButton>
      </label>
      <h3 style={{fontSize:14,color:'#121212',fontFamily:'Poppins',fontWeight:'400'}}>Upload</h3>
                        </Box>
                        <Box className={classes.StatusCol}>
                            <Typography variant="h4" style={{fontSize:18,color:'#121212',fontFamily:'Poppins',marginBottom:20,}}>Client Status</Typography>
                            <Box style={{display:'flex',width:'90%',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                                <Typography variant="h5" style={{fontSize:14,color:'#121212',fontFamily:'Poppins'}}>Status : Active</Typography>
                                <SwitchUnstyled component={Root} {...label} defaultChecked />
                            </Box>
                        </Box>
                    </Box>
                  </Grid> */}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default AddNewClients;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  StatusCol: {
    width: "80%",
    background: "#F9F9F9",
    borderRadius: "15px 10px 10px",
    border: "1px #D5D5D5 solid",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  FormHeading: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "rgba(0,0,0,0.5)",
    marginBottom: 20,
    fontWeight: "600",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 5,
    marginTop: 20,
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 30,
    height: 20,
    "&:hover": {
      background: "none",
    },
  },
  UploadBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  ProfileCol: {
    padding: "90px 25px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  ProfileImg: {
    width: 150,
    height: 150,
    borderRadius: 20,
    display: "fle",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  Btncol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  providerlist: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 18,
    marginTop: 20,
  },
  Righttext: {
    fontSize: 16,
    color: "#696969",
  },
  Btnlink: {
    fontSize: "16px",
    color: "#7087A7",
    backgroundColor: "transparent",
    padding: "0 10px",
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "capitalize",
    "&:hover": {
      color: "#000",
      backgroundColor: "#fff",
    },
  },
  Leftbutton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  Accessbtn: {
    fontSize: "14px",
    color: "#141621",
    textTransform: "capitalize",
  },
  leftHeading:{
    '@media only screen and (max-width: 820px)':{
      display:'none'
     },
     '@media only screen and (max-width: 768px)':{
      display:'none'
     },
  },
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  btncheck: {
    color: "#5FD827",
    marginLeft: "10px",
  },
  btncancel: {
    color: "#C13229",
    marginLeft: "10px",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topheading: {
    marginBottom: "0px",
    fontWeight: "600",
    color: "#141621",
    fontFamily: "Poppins",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 10,
  },
  toprow: {
    width: "100%",
    borderBottom: "2px #E3E5E5 solid",
    display: "flex",
    color: "#919699",
    paddingBottom: "10px",
  },
  pageTop: {
    textAlign: "left",
    marginBottom: "40px",
    display: "flex",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  profile: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  profileLogo2:{
    width:'45px !important',
    height:'45px',
    borderRadius:'50%',
    
    marginRight:'10px',
    // overflow:'hidden',
    '& img':{
        width:'100%',
        // objectFit:"stretch",
        height:'100%'
    }
},
  backarrow: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "8px",
  },
  Leftcol: {
    width: "15%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1050px",
    '@media only screen and (max-width: 820px)':{
      position:'absolute'
              },
    '@media only screen and (max-width: 768px)':{
      position:'absolute'
              },
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    '@media only screen and (max-width: 820px)':{
      display:'none'
    },
    '@media only screen and (max-width: 768px)':{
      display:'none'
    }
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      left:'15px',
    right:'15px',
            }
  },
  Rightcol: {
    width: "75%",
    padding: "20px 2%",
    borderLeft: "1px #ededed solid",
    '@media only screen and (max-width: 820px)':{
      width:'94%',
      padding:'20px 3%',
            },
    '@media only screen and (max-width: 768px)':{
      width:'94%',
      padding:'20px 3%',
            },
    "& .MuiAccordionSummary-root": {
      borderBottom: "2px #E3E5E5 solid",
      height: "40px",
      color: "#919699",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& a": {
      textDecoration: "none",
    },
  },

  Downarrow: {
    fontSize: "20px",
    color: "#7087A7",
    marginLeft: "5px",
  },

  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#7087A7",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#7087A7",
      color: "#fff",
    },
  },

  icon: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "10px",
  },
  providerrow: {
    width: "100%",
    borderBottom: "1px #E3E5E5 solid",
    padding: "15px 0",
    display: "flex",
    "& p": {
      textAlign: "left",
    },
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },

  pageTop: {
    textAlign: "left",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  checkicon: {
    fontSize: "25px",
    color: "#47C932",
  },
  inputfile: {
    display: "none",
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
   
    // '& .react-dropdown-select-input':{
    //  display: "none"
    // },
   
  },
  Toptext: {
    fontSize: "18px",
    color: "#141621",
    fontWeight: "600",
    marginTop: "-15px",
    marginBottom: "30px",
  },
  Textheading: {
    fontSize: "16px",
    marginTop: "0px",
    fontWeight: "500",
  },
  Addbtn: {
    width: "180px",
    height: "45px",
    background: "#E13F66",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 0px 12px 6px rgba(0, 0, 0, 0.18)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
    },
  },
  cancelbtn: {
    background: "#DADADA",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      background: "#000",
    },
  },
  nextbtn: {
    background: "#7087A7",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    marginLeft: "15px",
    "&:hover": {
      background: "#000",
    },
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "180px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },

  img:{
    // width: "100%",
    // textAlign: "center",
    // color: "green",
   
    // lineHeight: "100px",
    // borderRadius: "50%",
     // fontSize: "25px",
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
   
    // background: "#512DA8",
  
    // color:"#fff",
    // textAlign: "center",
  
    // margin: "20px 0"
  },

//   profile:{
//     width:'45px',
//     height:'45px',
//     borderRadius:'50px',
    
//     marginRight:'10px',
//     overflow:'hidden',
//     '& img':{
//         width:'100%',
//         objectFit:"stretch",
//         height:'100%'
//     }
// },

  uploadBtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "85px",
    height: "22px",
    borderRadius: "5px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
    },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "700px",
      maxWidth: "700px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },

  
}));
