import React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ManageProviderList from "./pages/ManageProviderList";
import ManageClients from "./pages/ManageClients";
import AddNewClients from "./pages/AddNewClients";
import AddNewClientStep from "./pages/AddNewClientStep";
import AddNewClientStep3 from "./pages/AddNewClientStep3";
import Dashboard from "./pages/Dashboard";
import AddUser from "./pages/AddUser";
import LandingPage from "./pages/LandingPage";
import PatientSearch from "./pages/PatientSearch";
import AddProgram from "./pages/AddProgram";
import Login from "./pages/Login";
import ProtectedRouteAdmin from "./Util/ProtectedRoute";
import LogoutModal from "./pages/LogoutModal";
import IdleTimer from "./Util/IdleTimer";
import ManageProgram from './pages/ManageProgram';

function App() {
  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("admin_Token") &&
      localStorage.getItem("admin_Refresh_Token")
    ) {
      const timer = new IdleTimer({
        timeout: 900, //expire after x seconds
        onTimeout: () => {
          setIsTimeout(true);
          console.log("onTimeout");
        },
        onExpired: () => {
          //do something if expired on load
          setIsTimeout(true);
          console.log("onExpired");
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [isTimeout]);

  const onCloseModal = () => {
    setIsTimeout(false);
  };

  return isTimeout ? (
    <LogoutModal onClose={onCloseModal}></LogoutModal>
  ) : (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login} />
            <ProtectedRouteAdmin
              exact
              path="/dashboard"
              component={LandingPage}
            />
            <ProtectedRouteAdmin exact path="/toolkit" component={Dashboard} />
            <ProtectedRouteAdmin
              exact
              path="/clients"
              component={ManageClients}
            />
            <Route exact path="/new-clients" component={AddNewClients} />
         
            <ProtectedRouteAdmin
              exact
              path="/new-clients-step3"
              component={AddNewClientStep3}
            />
            <ProtectedRouteAdmin
              exact
              path="/manage-user"
              component={ManageProviderList}
            />
            <ProtectedRouteAdmin exact path="/add-user" component={AddUser} />
            <ProtectedRouteAdmin
              exact
              path="/patient"
              component={PatientSearch}
            />
            <ProtectedRouteAdmin
              exact
              path="/add-program"
              component={AddProgram}
            />
            <ProtectedRouteAdmin
              exact
              path="/manage-program"
              component={ManageProgram}
            />
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
