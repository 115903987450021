import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FaMedkit } from "react-icons/fa"
import { Box,Grid, Link } from '@material-ui/core'
import clsx from 'clsx'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import NavIcon from "../images/nav-icon.png";
import {BiArrowBack,BiClipboard, BiSearch,BiCheckSquare} from "react-icons/bi"
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSelector, useDispatch } from 'react-redux'
import { BiChevronLeft } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import Setting from './Setting'
import TopHeading from './TopHeading'
function LeftNav() {
    const classes = useStyles();
    // Accordion2 //
  const [expanded2, setExpanded2] = React.useState('panel6');
  const handleChange3 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box style={{padding:'0 20px'}}><TopHeading /></Box>
      <Box style={{padding:20,display:'flex',justifyContent:'space-between',flexDirection:"column",height:'90vh'}}>
       <nav>
            <ul className={classes.navlist}>
              <li>
                <NavLink
                  activeClassName={classes.activeNav}
                  className={classes.Leftnavlink}
                  to="/dashboard"
                  
                >
                
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.activeNav}

                
                  
                  isActive={(match,location)=>{
                  return  [  "/manage-program","/add-program", "/patient", "/manage-user", "/add-user", "/new-clients-step3",
                    "/new-clients-step2", "/new-clients", "/clients" 
                   ].includes(location.pathname)
                  
                  }}
                  className={

                  classes.Leftnavlink
                    
                  }

                  to="/clients"
                 
                >
                  Manage Client
                </NavLink>
              </li>
              
              <li>
                <NavLink activeClassName={classes.activeNav} className={classes.Leftnavlink} to="/toolkit">
                  Toolkit
                </NavLink>
              </li>
               {/*<li>
                <NavLink activeClassName={classes.activeNav} className={classes.Leftnavlink} to="">
                  Settings
                </NavLink>
              </li> */}
            </ul>
          </nav>
          <Setting />
          </Box>
    </div>
  );
  
    return (
        <div>
          <Box className={classes.ResponsiveBtn}> {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><img src={NavIcon} alt="Nav images" /></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      </Box>
             <Box className={classes.Accordionnav}>
             <nav>
            <ul className={classes.navlist}>
              <li>
                <NavLink
                  activeClassName={classes.activeNav}
                  className={classes.Leftnavlink}
                  to="/dashboard"
                  
                >
                
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.activeNav}

                
                  
                  isActive={(match,location)=>{
                  return  [  "/manage-program","/add-program", "/patient", "/manage-user", "/add-user", "/new-clients-step3",
                    "/new-clients-step2", "/new-clients", "/clients" 
                   ].includes(location.pathname)
                  
                  }}
                  className={

                  classes.Leftnavlink
                    
                  }

                  to="/clients"
                 
                >
                  Manage Client
                </NavLink>
              </li>
              
              <li>
                <NavLink activeClassName={classes.activeNav} className={classes.Leftnavlink} to="/toolkit">
                  Toolkit
                </NavLink>
              </li>
               {/*<li>
                <NavLink activeClassName={classes.activeNav} className={classes.Leftnavlink} to="">
                  Settings
                </NavLink>
              </li> */}
            </ul>
          </nav>
                 {/* <Accordion expanded={expanded2 === 'panel5'} onChange={handleChange3('panel5')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}><BiClipboard className={classes.icon} /> CCM</Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel6'} onChange={handleChange3('panel6')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}><BiCheckSquare className={classes.icon} /> VALIDATE</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
         <ul>
           <li><Link to="" style={{color:'#7087A7'}}>Contact Information</Link></li>
           <li><Link to="">Providers</Link></li>
         </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel7'} onChange={handleChange3('panel7')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}><BiSearch className={classes.icon} /> PROGRAM</Typography>
         
        </AccordionSummary>
        <AccordionDetails>
        <ul>
           <li><Link to="" style={{color:'#7087A7'}}>Summary</Link></li>
         </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded2 === 'panel8'} onChange={handleChange3('panel8')} style={{boxShadow:'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><FaMedkit className={classes.icon} /> CARE PLAN</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ul>
           <li><Link to="" style={{color:'#7087A7'}}>Conditions</Link></li>
           <li><Link to="">Symptoms</Link></li>
           <li><Link to="">Goals</Link></li>
           <li><Link to="">Clinical Change Queue</Link></li>
           <li><Link to="">Hospitalizations & Surgeries</Link></li>
           <li><Link to="">Medications & Vaccincations</Link></li>
           <li><Link to="">Resources</Link></li>
           <li><Link to="">Notes</Link></li>
         </ul>
        </AccordionDetails>
      </Accordion> */}
      </Box>
        </div>
    )
}

export default LeftNav
const drawerWidth = 25;
const useStyles = makeStyles(({ spacing, transitions }) => ({
    Accordionnav:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
      },
      '@media only screen and (max-width: 768px)':{
        display:'none'
      },
        '& .MuiAccordion-root:before':{
          display:'none'
        },
        '& ul':{
          listStyle:'none',
          margin:'0px',
          padding:'0px',
          '& li':{
            fontSize:'15px',
            color:'#141621',
            lineHeight:'40px',
            marginBottom:10,
            '& a':{
              color:'#141621',
              cursor:'pointer',
              '&:hover':{
                color:'#7087A7',
                textDecoration:'none'
              }
            }
          }
        },
        '& .MuiTypography-root':{
          display:'flex',
          alignItems:'center'
        },
        '& .MuiAccordion-root.Mui-expanded':{
          margin:'0px'
        },
        '& .MuiAccordion-rounded':{
          borderBottom:'1px #E3E5E5 solid',
          padding:'10px 0'
        }
      },
      icon:{
        color:'#7087A7',
        fontSize:'20px',
        marginRight:'10px'
      },
      activeNav: {
        background: '#430AE8',
        color:'#fff !important',
        borderRadius:10,
      },
      Leftnavlink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#000',
        height:45,
        width: '100%',
        fontSize: 15,
        paddingLeft: 15,
        textDecoration: 'none',
        justifyContent:'flex-start',
        textTransform:'capitalize',
        borderRadius:10,
        fontFamily:'Poppins'
      },
      content: {
        flexGrow: 1,
        transition: transitions.create('margin', {
          easing: transitions.easing.sharp,
          duration: transitions.duration.leavingScreen,
        }),
        marginLeft: spacing(-1 * drawerWidth),
      },
      ResponsiveBtn:{
        position:'absolute',
        top:'-78px',
        left:'-5px',
        display:'none',
        '@media only screen and (max-width: 820px)':{
          display:'block'
        },
        '@media only screen and (max-width: 768px)':{
          display:'block'
        }
      },
      navlist: {
        margin: 0,
        padding: '0 0 0 10px',
        listStyle: 'none',
        '& li': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      Navlink: {
        background: '',
      },
      contentShift: {
        transition: transitions.create('margin', {
          easing: transitions.easing.easeOut,
          duration: transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      list: {
        width: 250,
      },
      fullList: {
        width: 'auto',
      }, 
}));