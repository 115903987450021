import React, {
    useEffect,
    Fragment,
   
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from "react-router-dom"
import { Pagination } from '@material-ui/lab';
import Header from '../components/Header'
import { Box,Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiChevronUp, BiChevronDown,BiArrowBack, BiPlusCircle} from "react-icons/bi"
import ManageUser from '../components/AdminDashboard'
import profile from '../images/doctor2.jpg'
import profile2 from '../images/doctor1.jpg'
import TopHeading from '../components/TopHeading'
import LeftNav from '../components/LeftNav'
import { Link } from 'react-router-dom'
import { apiAxios } from "../Util/ApiAxios";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-date-picker";
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import moment from "moment";

function AddProgram(props, { options }) {
    const classes = useStyles();

    const [tablemessage, setTableMessage] = useState("");
    const [programList, setProgramList] = useState([]);
    const [programOptions, setProgramOptions] = useState([]);
    const [programName, setProgramName] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [providerList, setProviderList] = useState([]);
    const [providerOptions,setProviderOptions] = useState([]);
    const [providerName, setProviderName] = useState("");
    const [showLoader, setShowLoader] = React.useState(false);
   
    // error
    const [providerError, setProviderError] = useState(false);
    const [programError, setProgramError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const currentPageRef = useRef(1);

    const location = useLocation();
  const client_id = location.state?.client_id;
  const client_name = location.state?.client_name;

    console.log(client_name)
    console.log(client_id)

    useEffect(() => {
        getProgramList();
        getProviderList();
        
      }, []);
    

   function getProviderList(){
        
    
          let data = {
            client_id: client_id,
            table_config_rows_per_page: "100",
            table_config_page_no: "1",
            cutid:"",
            search_value: "",
            user_master_type_id: "",
            is_provider:true
          };
    
          apiAxios
            .post("/client/clientuserlist", data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("admin_Token"),
              },
            })
            .then((response) => {
              console.log("response --> ", response);
              if (response.data.statusCode == 200) {
                setProviderList(response.data.data);
                setTotalPages(response.data.total_pages);
                // if (providerList) {
                //     var ob_arr = [];
                //     for (var i = 0; i < providerList.length; i++) {
                //       let value = providerList[i].id;
                //       let label = providerList[i].first_name;
                  
                //       let ob = {
                //         label: label,
                //         value: value,
                       
                //       };
                //       console.log("ob types = ", ob);
                //       ob_arr.push(ob);
                //     }
                //     setProviderOptions(ob_arr);
                //     console.log("Ob arr = ", programOptions);
                // }
              }
                else if(response.data.statusCode == 502) {

                
              } else {
                // toast.error(response.data.message, {
                //   position: "bottom-center",
                //   autoClose: 5000,
                //   hideProgressBar: false,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                // });
              }
              
            })
            .catch((error) => {
              console.log(error);
    
              // toast.error("Something Went Wrong!", {
              //   position: "bottom-center",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              // });
            });
        }
      
        function getProgramList(){
            // async ({
            //   table_config_rows_per_page = "5",
            //   table_config_page_no = "1",
            // } = {}) => {
            //   setCurrentPage(parseInt(table_config_page_no));
        
              let data = {
                search_value: "",
                table_config_rows_per_page: 100,
                table_config_page_no: 1,
              };
        
              apiAxios
                .post("/program/list", data, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("admin_Token"),
                  },
                })
                .then((response) => {
                  console.log("response --> ", response);
                  if (response.data.statusCode == 200) {
                    setProgramList(response.data.data);
                    setTotalPages(response.data.total_pages);
                  
                  } else {
                    // toast.error(response.data.message, {
                    //   position: "bottom-center",
                    //   autoClose: 5000,
                    //   hideProgressBar: false,
                    //   closeOnClick: true,
                    //   pauseOnHover: true,
                    //   draggable: true,
                    //   progress: undefined,
                    // });
                  }
                  
                })
                .catch((error) => {
                  console.log(error);
        
                  // toast.error("Something Went Wrong!", {
                  //   position: "bottom-center",
                  //   autoClose: 5000,
                  //   hideProgressBar: false,
                  //   closeOnClick: true,
                  //   pauseOnHover: true,
                  //   draggable: true,
                  //   progress: undefined,
                  // });
                });
            }

   useEffect(()=>{
    if (programList) {
        var ob_arr = [];
        for (var i = 0; i < programList.length; i++) {
          let value = programList[i].id;
          let label = programList[i].program_name;
      
          let ob = {
            label: label,
            value: value,
           
          };
          console.log("ob types = ", ob);
          ob_arr.push(ob);
        }
        setProgramOptions(ob_arr);
        console.log("Ob arr = ", programOptions);
    }

    if (providerList) {
        var ob_arr = [];
        for (var i = 0; i < providerList.length; i++) {
          let value = providerList[i].id;
          let label = providerList[i].first_name + " " + providerList[i].last_name ;
      
          let ob = {
            label: label,
            value: value,
           
          };
          console.log("ob types = ", ob); 
          ob_arr.push(ob);
        }
        setProviderOptions(ob_arr);
        console.log("Ob arr = ", providerOptions);
    }
   },[programList,providerList])

   function handleSubmit() {
        let isValid = true

        console.log(startDate)
        console.log(endDate)
        if (programName) {
            setProgramError(false);
           
          } else {
            setProgramError(true);
            isValid = false;
          }

          if (providerName) {
            setProviderError(false);
           
          } else {
            setProviderError(true);
            isValid = false;
          }
          if (startDate === null) {
            setStartDateError(true);
            isValid = false;
          } else {
            setStartDateError(false);
            
          }
          if (endDate === null) {
            setEndDateError(true);
            isValid = false;
          } else {
            setEndDateError(false);
            
          }

        if(isValid){
            setShowLoader(true);
          let data = {
                program_id: programName ? programName[0].value : "",
                client_provider_id: providerName ? providerName[0].value : "",
                provider_program_start_date: moment(startDate).format("YYYY-MM-DD"),
                provider_program_end_date: moment(endDate).format("YYYY-MM-DD"),
                status: "1",
                client_id: client_id
                
            }
            
            apiAxios
            .post("/client/create-client-provider-programs", data, {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("admin_Token"),
              },
            })
            .then((response) => {
              console.log("response --> ", response);
              setShowLoader(false);
              if (response.data.statusCode == 200) {
                setProgramList(response.data.data);
                setTotalPages(response.data.total_pages);
                toast.success(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                const timer = setTimeout(()=>{
                  
                  props.history.push({
                    pathname: "/manage-program",
                    state: {
                      
                        client_id: client_id,
                        client_name : client_name
                      
                    }
                  });
      


                },2000)

                

                
              } else {
                toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowLoader(false);
              }
              
            })
            .catch((error) => {
              console.log(error);
              setShowLoader(false);
              toast.error("Something Went Wrong!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowLoader(false);
            });
        
        }



   }
  
    return (
        <div>
             <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
            <Box className={classes.leftHeading}>
            <TopHeading />
            </Box>
            <Box
              className={classes.pageTop}
              style={{ marginBottom: "40px" }}
            ></Box>
            {/* left Accordion*/}
            <LeftNav />
            <Box className={classes.bottomnav}>
              <Setting />
            </Box>
          </Box>
        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8}>
              {/* <Link to="/manage-program"> */}
              <Link to={{pathname: "/manage-program", state: { client_id: client_id, client_name: client_name}, }}>
                <Button className={classes.backBtn}>
                  <BiArrowBack className={classes.backarrow} />
                </Button>
              </Link>
              <br />
              <Typography style={{marginBottom:40,fontFamily:'Poppins',fontSize:18,color:'#696969'}}>Client: <b>{client_name}</b></Typography>
              <Box className={classes.providerlist}>
                <Typography variant="h3" className={classes.topheading}>
                  Add Program
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    color: "#696969",
                    fontStyle: "italic",
                  }}
                >
                  
                </Typography>
                {/* <Typography variant="p" className={classes.Righttext}>General Informations</Typography> */}
              </Box>
              <Box className={classes.loginform}>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      {/* <Typography variant="h5" className={classes.FormHeading}>
                        General Information
                      </Typography> */}

                    
                     
                      
                     
                     
                      <Box className={classes.Formcol}>
                        <label>
                          Program List<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                           options={programOptions}
                           value={programName}
                          onChange={setProgramName}
                          placeholder="Program Name"
                          className={classes.select}
                          style={{ width: "100%" }}
                        //   values={clientTypeList().filter(
                        //     (option) => option.value === clientType
                        //   )}
                        />
                      </Box>
                      {programError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please select this field.
                        </p>
                      ) : (
                        <></>
                      )}
                      
                      <Box className={classes.Formcol}>
                        <label>Provider List<span style={{ color: "#ff0000" }}>*</span></label>
                        <Select
                        options={providerOptions}
                        onChange={setProviderName}
                        value={providerName}
                          placeholder="Provider"
                          className={classes.select}
                          style={{ width: "100%" }}
                          
                        />
                      </Box>

                      {providerError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          PLease select this field.
                        </p>
                      ) : (
                        <></>
                      )}
                      {/* <Typography variant="h5" className={classes.FormHeading}>
                        Client Address (Location)
                      </Typography> */}
                      <Box className={classes.Formcol}>
                        <label>Start Date<span style={{ color: "#ff0000" }}>*</span></label>
                        <DatePicker
                         onChange={setStartDate}
                            value={startDate}
                            clearIcon={null}
                          className={classes.input}
                          maxDate={moment().toDate()}
                        />
                      </Box>
                      {startDateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          PLease enter start Date.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>End Date<span style={{ color: "#ff0000" }}>*</span></label>
                        <DatePicker
                         value={endDate}
                         onChange={setEndDate}
                          className={classes.input}
                          minDate={moment().toDate()}
                          clearIcon={null}
                        />
                      </Box>
                      {endDateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter end Date.
                        </p>
                      ) : (
                        <></>
                      )}
                     

                      
                      

                <Box className={classes.Btncol}>

                {showLoader ? (
                  <ThreeDots
                    // type="ThreeDots"
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                  />
                ) : (
                  <Button
                    size="large"
                    className={classes.loginbtn}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                )}

                        {/* <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={handleSubmit}
                        >
                          {" "}
                          Submit{" "}
                        </Button> */}
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
           
          </Grid>
        </Box>
      </Box> 

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        </div>
    )
}

export default AddProgram
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    backBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        marginTop:20,
        marginBottom:15,
        width:30,
        height:20,
        '&:hover':{
            background:'none'
        }
    },
    ActionBtn:{
        borderRadius:10,
        textTransform:'capitalize',
        background:'#0A70E8',
        color:'#fff',
        '&:hover':{
            background:'rgba(0,0,0,0.8)'
        }
    },
    Topcol:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:'0',
        '& a':{
            textDecoration:'none'
        }
    },
    Tdcol:{
        width:'20%'
    },
    Tdcol2:{
        width:'30%'
    },
    Tdcol3:{
        width:'25%'
    },
    Tdcol4:{
        width:'15%'
    },
    Tdcol5:{
        width:'10%',
        textAlign:'right'
    },
    Thcol:{
        width:'20%'
    },
    Thcol2:{
        width:'30%'
    },
    Thcol3:{
        width:'25%'
    },
    Thcol4:{
        width:'15%'
    },
    Thcol5:{
        width:'10%',
        textAlign:'right'
    },
    addprovider:{
        fontSize:'16px',
        color:'#7087A7',
        textTransform:'capitalize'
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end'
    },
    topheading:{
        fontWeight:'600',
        color:'#141621'
    },
    Throw:{
        width:'100%',
        borderBottom:'2px #E3E5E5 solid',
        display:'flex',
        color:'#919699',
        paddingBottom:'10px',
        alignItems:'center'
    },
    pageTop:{
        textAlign:'left',
        marginBottom:'40px',
        display:'flex',
        '& button':{
            padding:'0px',
            background:'none',
            color:'#919699',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500'
        }
    },
    profile:{
        width:'80px',
        height:'80px',
        borderRadius:'50%',
        overflow:'hidden',
        '& img':{
            width:'100%'
        }
    },
    backarrow:{
        color:'#7087A7',
        fontSize:'20px',
        marginRight:'8px'
    },
    Leftcol:{
        width:'15%',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px'
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px'
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px'
    },
    Rightcol:{
        width:'75%',
        padding:'20px 2%',
        borderLeft:'1px #ededed solid',
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #E3E5E5 solid',
            height:'40px',
            color:'#919699',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        }
    },
    
Downarrow:{
    fontSize:'20px',
    color:'#7087A7',
    marginLeft:'5px'
},

Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#7087A7',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#7087A7',
    color:'#fff',
  }
},

icon:{
  color:'#7087A7',
  fontSize:'20px',
  marginRight:'10px'
},
Tdrow:{
    width:'100%',
    borderBottom:'1px #E3E5E5 solid',
    padding:'15px 0',
    alignItems:'center',
    display:'flex',
    '& p':{
        textAlign:'left'
    }
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},

pageTop:{
    textAlign:'left',
    '& button':{
        padding:'0px',
        background:'none',
        color:'#919699',
        fontSize:'15px',
        textTransform:'capitalize',
        fontWeight:'500'
    }
},
  
Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  StatusCol: {
    width: "80%",
    background: "#F9F9F9",
    borderRadius: "15px 10px 10px",
    border: "1px #D5D5D5 solid",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
  },
  FormHeading: {
    fontFamily: "Poppins",
    fontSize: 16,
    color: "rgba(0,0,0,0.5)",
    marginBottom: 20,
    fontWeight: "600",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 5,
    marginTop: 20,
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 30,
    height: 20,
    "&:hover": {
      background: "none",
    },
  },
  UploadBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  ProfileCol: {
    padding: "90px 25px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  ProfileImg: {
    width: 150,
    height: 150,
    borderRadius: 20,
    display: "fle",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  Btncol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  providerlist: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 18,
    marginTop: 20,
  },
  Righttext: {
    fontSize: 16,
    color: "#696969",
  },
  Btnlink: {
    fontSize: "16px",
    color: "#7087A7",
    backgroundColor: "transparent",
    padding: "0 10px",
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "capitalize",
    "&:hover": {
      color: "#000",
      backgroundColor: "#fff",
    },
  },
  Leftbutton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  Accessbtn: {
    fontSize: "14px",
    color: "#141621",
    textTransform: "capitalize",
  },
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  btncheck: {
    color: "#5FD827",
    marginLeft: "10px",
  },
  btncancel: {
    color: "#C13229",
    marginLeft: "10px",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  topheading: {
    marginBottom: "0px",
    fontWeight: "600",
    color: "#141621",
    fontFamily: "Poppins",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 10,
  },
  toprow: {
    width: "100%",
    borderBottom: "2px #E3E5E5 solid",
    display: "flex",
    color: "#919699",
    paddingBottom: "10px",
  },
  pageTop: {
    textAlign: "left",
    marginBottom: "40px",
    display: "flex",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  profile: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  leftHeading:{
  '@media only screen and (max-width: 820px)':{
      display:'none'
     },
    '@media only screen and (max-width: 768px)':{
      display:'none'
     },
  },
  backarrow: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "8px",
  },
  Leftcol: {
    width: "15%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1050px",
    '@media only screen and (max-width: 820px)':{
      position:'absolute'
              },
    '@media only screen and (max-width: 768px)':{
      position:'absolute'
              },
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    '@media only screen and (max-width: 820px)':{
      display:'none'
    },
    '@media only screen and (max-width: 768px)':{
      display:'none'
    }
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      left:'15px',
    right:'15px',
            }
  },
  Rightcol: {
    width: "75%",
    padding: "20px 2%",
    borderLeft: "1px #ededed solid",
    '@media only screen and (max-width: 820px)':{
      width:'94%',
      padding:'20px 3%',
            },
    '@media only screen and (max-width: 768px)':{
      width:'94%',
      padding:'20px 3%',
            },
    "& .MuiAccordionSummary-root": {
      borderBottom: "2px #E3E5E5 solid",
      height: "40px",
      color: "#919699",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    "& a": {
      textDecoration: "none",
    },
  },

  Downarrow: {
    fontSize: "20px",
    color: "#7087A7",
    marginLeft: "5px",
  },

  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#7087A7",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#7087A7",
      color: "#fff",
    },
  },

  icon: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "10px",
  },
  providerrow: {
    width: "100%",
    borderBottom: "1px #E3E5E5 solid",
    padding: "15px 0",
    display: "flex",
    "& p": {
      textAlign: "left",
    },
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },

  pageTop: {
    textAlign: "left",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  checkicon: {
    fontSize: "25px",
    color: "#47C932",
  },
  inputfile: {
    display: "none",
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  Toptext: {
    fontSize: "18px",
    color: "#141621",
    fontWeight: "600",
    marginTop: "-15px",
    marginBottom: "30px",
  },
  Textheading: {
    fontSize: "16px",
    marginTop: "0px",
    fontWeight: "500",
  },
  Addbtn: {
    width: "180px",
    height: "45px",
    background: "#E13F66",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 0px 12px 6px rgba(0, 0, 0, 0.18)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
    },
  },
  cancelbtn: {
    background: "#DADADA",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      background: "#000",
    },
  },
  nextbtn: {
    background: "#7087A7",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    marginLeft: "15px",
    "&:hover": {
      background: "#000",
    },
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },

    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "180px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "700px",
      maxWidth: "700px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
   }));