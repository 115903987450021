import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from "react-router-dom"
import { Pagination } from '@material-ui/lab';
import Header from '../components/Header'
import { Box,Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiChevronUp, BiChevronDown,BiArrowBack, BiPlusCircle, BiXCircle} from "react-icons/bi"
import ManageUser from '../components/AdminDashboard'
import profile from '../images/doctor2.jpg'
import profile2 from '../images/doctor1.jpg'
import TopHeading from '../components/TopHeading'
import LeftNav from '../components/LeftNav'
import { Link } from 'react-router-dom'
import { apiAxios } from "../Util/ApiAxios";
import DatePicker from "react-date-picker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormGroup from "@material-ui/core/FormGroup";
import moment from "moment";
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core//Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "react-dropdown-select";
import {
    checkName,
    checkEmail,
    checkNumbers,
    checkPhone,
    checkPhoneNumbers,
    checkNumbersOnly,
    checkDob,
    checkOnlyZero,
    checkPassword,
    checkUserName,
    checkZip,
    checkUSPhone,
  } from "../Util/validations";
import { stateList} from '../Util/DropDownData';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


function ManageProviderList(props) {
    const classes = useStyles();

    const [tablemessage, setTableMessage] = useState("");
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);

    const location = useLocation();
    const client_id = location.state?.client_id;
    const client_name = location.state?.client_name;

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [value2, onChange2] = useState(new Date());
    const[clientUserTypeID, setClientUserTypeID] = useState("");
    const[userMasterID, setUserMasterID] = useState("")
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [clientID, setClientID] = useState("");
    const [clientName, setClientName] = useState("");
    const [utmID, setUtmID] = useState("");
    // error validation
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [dobError, setDobError] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false)

  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false)

  const [npiNumber,setNpiNUmber] = useState("");
  const [npiGroupNumber,setNpiGroupNumber] = useState("")
  const [employerNumber,setEmployerNumber] = useState("")
  const [providerPracticeName,setProviderPracticeName] = useState("")
  const [countryCode,setCountryCode] = useState("")
  const [providerType,setProviderType] = useState("")
  const [mailingAddress,setMailingAddress] = useState("")
  const [mailingCity,setMailingCity] = useState("")
  const [mailingState,setMailingState] = useState("")
  const [mailingZip,setMailingZip] = useState("")
  const [locationAddress,setLocationAddress] = useState("")
  const [locationCity,setLocationCity] = useState("")
  const [locationState,setLocationState] = useState("")
  const [locationZip,setLocationZip] = useState("")

  const [disableUser, setDisableUser] = useState("")

  const [npiNumberError,setNpiNUmberError] = useState(false);
  const [npiGroupNumberError,setNpiGroupNumberError] = useState(false)
  const [employerNumberError,setEmployerNumberError] = useState(false)
  const [providerPracticeNameError,setProviderPracticeNameError] = useState(false)
  const [countryCodeError,setCountryCodeError] = useState(false)
  const [providerTypeError,setProviderTypeError] = useState(false)
  const [mailingAddressError,setMailingAddressError] = useState(false)
  const [mailingCityError,setMailingCityError] = useState(false)
  const [mailingStateError,setMailingStateError] = useState(false)
  const [mailingZipError,setMailingZipError] = useState(false)
  const [locationAddressError,setLocationAddressError] = useState(false)
  const [locationCityError,setLocationCityError] = useState(false)
  const [locationStateError,setLoctionStateError] = useState(false)
  const [locationZipError,setLocationZipError] = useState(false)




    // loader
    const [ifLoading, setIfLoading] = useState(false);

  const getUserList = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let data = {
        client_id: client_id ?  client_id : clientID,
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
        user_master_type_id: "",
        cutid: "",
        search_value: "",
        is_provider: 0
      };

      setTableMessage("Loading...");

      apiAxios
      .post("/client/clientuserlist", data, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("admin_Token")
        },
      })
      .then((response) => {
        console.log("response --> ",response);
        if(response.data.statusCode == 200) {
            setUserList(response.data.data);
            setTotalPages(response.data.total_pages);
            if(response.data.data.length > 0 ){}
            else{
                setTableMessage("No Data Found");
            }
        }
        else if(response.data.statusCode == 502){
            setTableMessage("No Data Found");
        }
        else {
            // toast.error(response.data.message, {
            //     position: "bottom-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
        }
      })
      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //     position: "bottom-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     });

            localStorage.removeItem("admin_Refresh_Token");
                localStorage.removeItem("admin_Token");
                window.location.href = "/"; 

      });

    },
  );
  

  useEffect(() => {
    getUserList();

    console.log(props.history.location.state)
    if(props.history.location.state) {
      setClientID(props.history.location.state.client_id)
      setClientName(props.history.location.state.client_name)
    }
  }, [props.history.location.state]);


   //  modal  //
   const [open, setOpen] = React.useState(false);

   const handleClickOpen = (data) => {
    
    
     setFirstName(data.first_name)
     setLastName(data.last_name)
     setEmail(data.email)
     setPhone(formatPhoneNumber(data.mobile))
     onChange2(data.dob)
     setClientUserTypeID(data.client_user_type_id)
     setUserMasterID(data.user_master_id)
     setUserName(data.username);
     setPassword(data.password);
     setUtmID(data.utm_id)


     let stateCatList = stateList();
  
     for(var i=0; i<stateCatList.length; i++){
 
       let str = data.provider_mailing_state
     // let stateName = str.charAt(0).toUpperCase() + str.slice(1);
 
     const words = str.split(" ");
 
     // words.map((word) => { 
     //     return word[0].toUpperCase() + word.substring(1); 
     //  }).join(" ");
 
 
      for (let j = 0; j < words.length; j++) {
       words[j] = words[j][0]?.toUpperCase() + words[j].substr(1);
       }
   
          let newWords = words.join(' ');
           console.log(newWords)
 
 
         if(stateCatList[i].label ==  newWords){
           console.log(stateCatList[i])
           // let st = stateCatList[i]
           // console.log(st)
           setMailingState(stateCatList[i]);
           console.log(stateCatList[i])
         }
   }
 
 
   
   for(var i=0; i<stateCatList.length; i++){
 
     let str = data.provider_location_state
   // let stateName = str.charAt(0).toUpperCase() + str.slice(1);
 
   const words = str.split(" ");
 
   // words.map((word) => { 
   //     return word[0].toUpperCase() + word.substring(1); 
   //  }).join(" ");
 
 
    for (let j = 0; j < words.length; j++) {
     words[j] = words[j][0]?.toUpperCase() + words[j].substr(1);
     }
 
        let newWords = words.join(' ');
         console.log(newWords)
 
 
       if(stateCatList[i].label ==  newWords){
         console.log(stateCatList[i])
         // let st = stateCatList[i]
         // console.log(st)
         setLocationState(stateCatList[i]);
         console.log(stateCatList[i])
       }
 }
 
 
 
 
 
 
 
     setNpiNUmber(data.provider_npi_number)
     setNpiGroupNumber(data.provider_npi_group_number)
     setEmployerNumber(data.employer_number)
     setProviderPracticeName(data.provider_practice_name)
     setCountryCode(data.provider_country_code)
     setProviderType(data.provider_type)
     setMailingAddress(data.provider_mailing_address)
     setMailingCity(data.provider_mailing_city)
   
     setMailingZip(data.provider_mailing_zip)
     setLocationAddress(data.provider_location_address)
     setLocationCity(data.provider_location_city)
  
     setLocationZip(data.provider_location_zip)

     setDisableUser((data.is_active === "True")? "true" : "false")
 
     setOpen(true);
 
   };
 
   const handleClose = () => {
     setOpen(false);
   };
 

   const togglePasswordVisibility = (flag)=>{

    if(flag === 1){
      setPasswordShown(!passwordShown)
    }else if(flag === 2){
      setPasswordChangeVisible(!passwordChangeVisible)
    }
    
  }



   function submitForm() {
    let isValid = true;

    console.log("submit form");

    if (!checkName(firstname.trim())) {
      setFirstNameError(true);
      isValid = false;
      console.log("First Error if= ", firstnameError);
    } else {
      setFirstNameError(false);
    }
    console.log("First Error = ", firstnameError);
   
    if (!checkName(lastname.trim())) {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }
    if (!checkUserName(username.trim())) {
      setUserNameError(true);
      isValid = false;
    } else {
      setUserNameError(false);
    }
    if (!checkUSPhone(phone)) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }
    if (!checkEmail(email)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }
    
    // if (parseInt(checkDob(value2).substring(0, 2)) < 0) {
    //   setDobError(true);
    //   isValid = false;
    // } else {
    //   setDobError(false);
    // }
    
    // if (value2){
    //   setDobError(false);
      
    // } else {
    //   setDobError(true);
    //   isValid = false;
    // }
    if(value2){
    if (parseInt(checkDob(value2)) < 18 || parseInt(checkDob(value2)) >200 || isNaN(parseInt(checkDob(value2)))) {
      setDobError(true);
      isValid = false;
    } else {
      setDobError(false);
    }
  }else {
    setDobError(false);
  }


  // additional proivder fields 

  if(providerPracticeName){
    if(!checkName(providerPracticeName.trim())){
      setProviderPracticeNameError(true)
      isValid = false
    }else {
      setProviderPracticeNameError(false)
    }
  }else {
    setProviderPracticeNameError(false)
  }



  if(mailingAddress){
    if(mailingAddress.trim()){
      setMailingAddressError(false)
    }else {
      setMailingAddressError(true)
      isValid = false
    }
  }else {
    setMailingAddressError(false)
  }


  if(locationAddress){
    if(locationAddress.trim()){
      setLocationAddressError(false)
    }else {
      setLocationAddressError(true)
      isValid = false
    }
  }else {
    setLocationAddressError(false)
  }


  if(mailingCity){
    if(mailingCity.trim()){
      setMailingCityError(false)
    }else {
      setMailingCityError(true)
      isValid = false
    }
  }else {
    setMailingCityError(false)
  }


  if(locationCity){
    if(locationCity.trim()){
      setLocationCityError(false)
    }else {
      setLocationCityError(true)
      isValid = false
    }
  }else {
    setLocationCityError(false)
  }

  if(mailingZip){
    if(!checkZip(mailingZip)){
      setMailingZipError(true)
      isValid = false
    }else {
      
      setMailingZipError(false)
    }
  }else {
    setMailingZipError(false)
  }
  

  if(locationZip){
    if(!checkZip(locationZip)){
      setLocationZipError(true)
      isValid = false
    }else {
      
      setLocationZipError(false)
    }
  }else {
    setLocationZipError(false)
  }


  if(providerType){
    if(providerType.trim()){
      setProviderTypeError(false)
    }else {
      setProviderTypeError(true)
      isValid = false
    }
  }else {
    setProviderTypeError(false)
  }


    if (isValid) {
      setIfLoading(true);
      let data = {
        additional_info_view_table: "",
        cutid: clientUserTypeID,
        permission: "",
        email: email,
        first_name: firstname,
        last_name: lastname,
        dob: value2?  moment(value2).format("YYYY-MM-DD") : "",
        user_master_id: userMasterID,
        user_login_panel_type: "1",
        mobile: phone.replace(/[^0-9]/g,''),
        username: username,
        password: "",
        utm_id : utmID,
        is_active : disableUser,

        provider_npi_number:npiNumber? npiNumber : "",
        provider_npi_group_number: npiGroupNumber ? npiGroupNumber : "",
        employer_number: employerNumber ? employerNumber : "",
        provider_practice_name: providerPracticeName ? providerPracticeName : "",
        provider_country_code: countryCode ? countryCode : "",
        provider_type: providerType ? providerType : "",
        provider_mailing_address: mailingAddress? mailingAddress : "",
        provider_mailing_city: mailingCity ? mailingCity : "",
        provider_mailing_state: mailingState.length>0? mailingState[0].label : mailingState.label,
        provider_mailing_zip: mailingZip ? mailingZip : "",
        provider_location_address: locationAddress ? locationAddress : "",
        provider_location_city: locationCity ? locationCity : "",
        provider_location_state: locationState.length>0 ? locationState[0].label : locationState.label,
        provider_location_zip: locationZip ? locationZip : ""


      };

      console.log("Final Data = ", data);

      apiAxios
        .post("/provider/update-dhct-provider", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);

          if (response.data.statusCode == 200) {
            // setClientUserTypes(response.data.data);

            //console.log("user types = ", userTypes);
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
            
             const timer = setTimeout(() => {
              window.location.href = "/manage-user";
            }, 2000);
            
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });
    }
  }


  const handleChangeSelected = (event) => {
    //console.log("Selected = ", selected);
    setDisableUser(event.target.value);
  };


  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhone(formattedPhoneNumber);
  };


  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;

    }

    return (
        <div>
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                     
                 </Box>
                 <Box className={classes.leftHeading}>
                 <TopHeading />
                 </Box>
                 {/* left Accordion*/}
                <LeftNav />
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
                   <Link to="/clients">
                        <Button className={classes.backBtn}><BiArrowBack className={classes.backarrow} /></Button>
                    </Link>
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <Box className={classes.providerlist}>
                      <Box className={classes.Topcol}>
                    <h3 className={classes.topheading}>User List</h3>
                    <Link to={{pathname: "/add-user", state: { client_id: client_id? client_id : clientID, client_name: client_name ? client_name : clientName }, }}><Button className={classes.addprovider}><BiPlusCircle className={classes.icon} /> Add User</Button></Link>
                    </Box>
                    <Typography style={{marginBottom:40,fontFamily:'Poppins',fontSize:18,color:'#696969'}}>Client: <b>{client_name? client_name : clientName }</b></Typography>
                    <Box className={classes.UserTable}>
                    <Box className={classes.Throw}>
                        <Box className={classes.Thcol}>First Name</Box>
                        <Box className={classes.Thcol}>Last Name</Box>
                        <Box className={classes.Thcol2}>Email</Box>
                        <Box className={classes.Thcol3}>Phone Number</Box>
                        <Box className={classes.Thcol4}>User Type</Box>
                        <Box className={classes.Thcol5}>Update</Box>
                    </Box>
                    
                    { (userList.length > 0) ?
                    <>
                    {userList.map((each) => (
                    <Box className={classes.Tdrow}>
                        <Box className={classes.Tdcol}>{each.first_name}</Box>
                        <Box className={classes.Tdcol}>{each.last_name}</Box>
                        <Box className={classes.Tdcol2}>{each.email}</Box>
                        <Box className={classes.Tdcol3}>{formatPhoneNumber(each.mobile)}</Box>
                        <Box className={classes.Tdcol4}>{each.short_name}</Box>
                        <Box className={classes.Tdcol5}><Button className={classes.ActionBtn} onClick={()=>handleClickOpen(each)}>Update</Button></Box>
                    </Box>
                    ))}
                    </>
                    :
                    <Box className={classes.providerrow}>
                        <Box className={classes.tdcol12} style={{textAlign:'center',width:'100%',color:'#dfdfdf'}}>
                            {tablemessage}
                        </Box>
                    </Box>
                    }
                    </Box>
                </Box>

                  </Grid>

                  <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getUserList({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div>  

                        {/* Update user modal */}
        <Dialog
          className={classes.modal}
          open={open}
          disableEnforceFocus={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <Box className={classes.loginform}>
                <h3 className={classes.Toptext}>Update User</h3>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                     

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter First Name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          First name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Last Name"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Last name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Date of Birth
                          {/* <span style={{ color: "#ff0000" }}>*</span> */}
                        </label>
                        <DatePicker
                          onChange={onChange2}
                          
                          value={value2? new Date(value2):  null}
                          className={classes.input}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter correct dob for valid age range(18-200 years)
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Email
                        <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Phone Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) => handleInput(e)}
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid phone number.
                        </p>
                      ) : (
                        <></>
                      )}
                    
                      
                    <Box className={classes.Formcol}>
                        <label>
                          User Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="User Name"
                          value={username}
                          onChange={(e) => setUserName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {userNameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid username
                        </p>
                      ) : (
                        <></>
                      )}

                    {/* disable user */}

                    <Box className={classes.FormcolNew}>

                    <label>
                        Disable User
                      </label>
                     
                       
                      <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          
                          value={disableUser}
                          name="radio-buttons-group"
                         
                        style={{marginLeft: "135px"}}
                        >
                          <Box style={{display:"flex", alignItems:"flex-start"}}>
                          <FormControlLabel
                            className={classes.customRadio}
                            value="false"
                            control={<Radio />}
                            label="Yes"
                            onChange={handleChangeSelected}
                            selected={disableUser}
                          />
                          <FormControlLabel
                            className={classes.customRadio}
                            value="true"
                            control={<Radio />}
                            label="No"
                            onChange={handleChangeSelected}
                            selected={disableUser}
                          />
                         </Box>
                        </RadioGroup>
                       
                      
                        </Box>
                      
{utmID === "13448f62-746e-438e-bf71-a153d67cbbda"?
                        <>
                        <Box className={classes.Formcol}>
                        <label>
                          Provider NPI number
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider NPI number"
                          type="number"
                          value={npiNumber}
                          onChange={(e) => {
                          
                              setNpiNUmber(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>

                       <Box className={classes.Formcol}>
                        <label>
                          Provider NPI group number
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider NPI Group Number"
                          type="number"
                          value={npiGroupNumber}
                          onChange={(e) => {
                         
                              setNpiGroupNumber(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                        Employer number
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Employer number"
                          type="number"
                          value={employerNumber}
                          onChange={(e) => {
                            
                              setEmployerNumber(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>

                      <Box className={classes.Formcol}>
                        <label>
                        Provider practice name
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider practice name"
                          value={providerPracticeName}
                          onChange={(e) => setProviderPracticeName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {providerPracticeNameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid name
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                        Provider Country code
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider Country code"
                          type="number"
                          value={countryCode}
                          onChange={(e) => {
                          
                              setCountryCode(e.target.value.replace(/[^0-9]/g,""));
                            
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                        Provider type
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider type"
                          value={providerType}
                          onChange={(e) => setProviderType(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {providerTypeError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid provider type
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                        Provider mailing address
                        </label>
                        <TextareaAutosize className={classes.textarea} aria-label="minimum height" 
                      maxLength={500} minRows={4} 
                      value={mailingAddress}
                      onChange={(e) => setMailingAddress(e.target.value)}
                       placeholder="Provider mailing address" />
                      </Box>
                      {mailingAddressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid Address
                        </p>
                      ) : (
                        <></>
                      )}
                        
                      <Box className={classes.Formcol}>
                        <label>
                        Provider mailing city
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider mailing city"
                          value={mailingCity}
                          onChange={(e) => setMailingCity(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {mailingCityError? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid City
                        </p>
                      ) : (
                        <></>
                      )}
                      
                      <Box className={classes.Formcol}>
                                            <label>
                                            Provider mailing state
                                            </label>
                                            <Select
                                                options={stateList()}
                                                value={mailingState ? mailingState : ""}
                                                onChange={setMailingState}
                                                placeholder={mailingState? mailingState.label : "Select mailing State"}
                                                className={classes.select}
                                            />
                                        </Box>
                          
                                        <Box className={classes.Formcol}>
                                            <label>
                                            Provider mailing zip
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Provider mailing zip"
                                                type="tel"
                                                value={mailingZip}
                                                onChange={(e) =>{
                                                    setMailingZip(e.target.value);
                                                    if (checkZip(e.target.value)) {
                                                     setMailingZipError(false);
                                                   } else {
                                                     setMailingZipError(true);
                                                     
                                                   }
                                                   }}
                                            />
                                        </Box>
                                        {mailingZipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid zip(4 to 6 digits)
                        </p>
                      ) : (
                        <></>
                      )}
                                
                                        <Box className={classes.Formcol}>
                        <label>
                        Provider location address
                        </label>
                        <TextareaAutosize className={classes.textarea} aria-label="minimum height" 
                      maxLength={500} minRows={4} 
                      value={locationAddress}
                      onChange={(e) => setLocationAddress(e.target.value)}
                       placeholder="Provider location address" />
                      </Box>
                      {locationAddressError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid Address
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                        Provider Location city
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Provider Location city"
                          value={locationCity}
                          onChange={(e) => setLocationCity(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {locationCityError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid City
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                                            <label>
                                            Provider location state
                                            </label>
                                            <Select
                                                options={stateList()}
                                                value={locationState ? locationState : ""}
                                                onChange={setLocationState}
                                                placeholder={locationState? locationState.label : "Provider location state"}
                                                className={classes.select}
                                            />
                                        </Box>
                                        <Box className={classes.Formcol}>
                                            <label>
                                            Provider location zip
                                            </label>
                                            <TextField
                                                className={classes.input}
                                                placeholder="Provider location zip"
                                                type="tel"
                                                value={locationZip}
                                                onChange={(e) =>{
                                                    setLocationZip(e.target.value);
                                                    if (checkZip(e.target.value)) {
                                                     setLocationZipError(false);
                                                   } else {
                                                     setLocationZipError(true);
                                                     
                                                   }
                                                   }}
                                            />
                                        </Box>
                                        {locationZipError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter a valid zip(4 to 6 digits)
                        </p>
                      ) : (
                        <></>
                      )}
                      </>
                      : 
                      <></>





                         }




                      
                      {/* <Box className={classes.Formcol} style={{position:"relative"}}>
                        <label>
                          Password
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Password"
                          type={passwordShown? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {password?
                    (<RemoveRedEyeIcon
                className={classes.redEye}
                onClick={()=>togglePasswordVisibility(1)}
              />) : <></>}
                      </Box>
                      {passwordError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter valid password. (Password should have: 8
                          characters and minimum 1 upper character, 1 lower
                          character, 1 number and 1 special character)
                        </p>
                      ) : (
                        <></>
                      )} */}


                    </Grid>
                  </Grid>
                </form>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            {!ifLoading?
            <Button
              size="large"
              className={classes.loginbtn}
                onClick={submitForm}
            >
              Save
            </Button>
            :
                  <ThreeDots
                   
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    />
            }
          </DialogActions>
        </Dialog>
                

                  <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                  
               </Grid>
               
               </Box>
            </Box>

                    <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />

        </div>
    )
}

export default ManageProviderList
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    backBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        marginTop:20,
        marginBottom:15,
        width:30,
        height:20,
        '&:hover':{
            background:'none'
        }
    },
    ActionBtn:{
        borderRadius:10,
        textTransform:'capitalize',
        background:'#0A70E8',
        color:'#fff',
        '&:hover':{
            background:'rgba(0,0,0,0.8)'
        }
    },
    UserTable:{
      width:'100%',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
        overflowX:'auto'
      }
    },
    Topcol:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:'0',
        '& a':{
            textDecoration:'none'
        }
    },
    Tdcol:{
        width:'20%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol2:{
        width:'37%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol3:{
        width:'21%'
    },
    Tdcol4:{
        width:'15%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol5:{
        width:'10%',
        textAlign:'right'
    },
    Thcol:{
        width:'20%'
    },
    Thcol2:{
        width:'37%'
    },
    Thcol3:{
        width:'21%'
    },
    Thcol4:{
        width:'15%'
    },
    Thcol5:{
        width:'10%',
        textAlign:'right'
    },
    addprovider:{
        fontSize:'16px',
        color:'#7087A7',
        textTransform:'capitalize'
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end'
    },
    topheading:{
        fontWeight:'600',
        color:'#141621'
    },
    Throw:{
        width:'100%',
        borderBottom:'2px #E3E5E5 solid',
        display:'flex',
        color:'#919699',
        paddingBottom:'10px',
        alignItems:'center',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1000,
        }
    },
    pageTop:{
        textAlign:'left',
        marginBottom:'40px',
        display:'flex',
        '& button':{
            padding:'0px',
            background:'none',
            color:'#919699',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500'
        }
    },
    profile:{
        width:'80px',
        height:'80px',
        borderRadius:'50%',
        overflow:'hidden',
        '& img':{
            width:'100%'
        }
    },
    backarrow:{
        color:'#7087A7',
        fontSize:'20px',
        marginRight:'8px'
    },
    Leftcol:{
        width:'15%',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px',
        '@media only screen and (max-width: 820px)':{
          position:'absolute'
                  },
        '@media only screen and (max-width: 768px)':{
          position:'absolute'
                  },
    },
    select: {
      width: "100%",
      border: "none !important",
      borderRadius: "10px !important",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      height: "50px",
      fontSize: "18px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
    textarea:{
      width:'100%',
      borderRadius:'10px',
          background:'#F9F9F9',
          border:'1px solid #D5D5D5',
          padding:'10px 15px',
          fontFamily:'Poppins',
          fontSize:'14px'
  }, 
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px',
        '@media only screen and (max-width: 820px)':{
          display:'none'
        },
        '@media only screen and (max-width: 768px)':{
          display:'none'
        }
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      left:'15px',
    right:'15px',
            }
    },
    Rightcol:{
        width:'75%',
        padding:'20px 2%',
        borderLeft:'1px #ededed solid',
        '@media only screen and (max-width: 820px)':{
          width:'94%',
          padding:'20px 3%',
                },
        '@media only screen and (max-width: 768px)':{
          width:'94%',
          padding:'20px 3%',
                },
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #E3E5E5 solid',
            height:'40px',
            color:'#919699',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        }
    },
    leftHeading:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
       },
      '@media only screen and (max-width: 768px)':{
        display:'none'
       },
    },   
Downarrow:{
    fontSize:'20px',
    color:'#7087A7',
    marginLeft:'5px'
},

FormcolNew:{
  display:'flex',
 
  alignItems:'flex-start',
  marginBottom:'30px',
 
'&>div':{
      width:'100%'
  },  
  '& p':{
      fontSize:'18px',
      margin:'0px'
  },
  '& label':{
      // flex:'0 0 205px',
      color:'#000',
      fontSize:'15px',
      fontWeight:'400',
      // marginTop:10,
  },
  '& .react-dropdown-select-input':{
      width:'100%'
  }
},

Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#7087A7',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#7087A7',
    color:'#fff',
  }
},

icon:{
  color:'#7087A7',
  fontSize:'20px',
  marginRight:'10px'
},
Tdrow:{
    width:'100%',
    borderBottom:'1px #E3E5E5 solid',
    padding:'15px 0',
    alignItems:'center',
    display:'flex',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
    },
    '& p':{
        textAlign:'left'
    }
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},

pageTop:{
    textAlign:'left',
    '& button':{
        padding:'0px',
        background:'none',
        color:'#919699',
        fontSize:'15px',
        textTransform:'capitalize',
        fontWeight:'500'
    }
},
modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
    "& label": {
      color: "#666",
      marginBottom: 5,
      display: "flex",
      flex: "0 0 150px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0074D9 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },

  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  redEye: {
    position: "absolute ",
    // top: "20px",
   bottom: "15px",
    left: "457px",
    cursor: "pointer",
    opacity: 0.8
  },

   }));