import React, { useEffect, useState, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core//Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { checkName } from '../../Util/validations'
import FormControl from '@mui/material/FormControl';


import Select from "react-dropdown-select"
import { Box,Grid, Link } from '@material-ui/core'
import {BiChevronUp, BiChevronDown,BiArrowBack, BiPlusCircle,BiXCircle,BiCheckCircle} from "react-icons/bi"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import { pink } from '@mui/material/colors'
import Checkbox from '@mui/material/Checkbox'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "@material-ui/lab";
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { apiAxios } from "../../Util/ApiAxios";
import { clientAdmin, careteam , noAdminAccess } from '../../Util/Permission';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function UserTemplate(options) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
     // loader
  const [ifLoading, setIfLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);
  const [userTypes, setUserTypes] = useState([])
  const [deletable, setDeletable] = useState(false)
  const [deletableError, setdeletableError] = useState(false)

  const [userTypeName, setUserTypeName] = useState("")
  const [userTypeNameError, setUsertypeNameError] = useState(false)
 

 
 

  // console.log(clientAdminLevel)
  // console.log(careteamLevel)
  // console.log(noAdmin)

  // const options = [
  //   { label: "Client Admin", value: clientAdminLevel },
  //   { label: "Care team", value: careteamLevel },
  //   { label: "No Admin", value: noAdmin },
   
  // ];





  const getUserType = useCallback(
    async ({
      table_config_rows_per_page = "15",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let data = {
        search: "",
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
      };

      apiAxios
        .post("/client/user-type-master-list", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            setUserTypes(response.data.data);
            setTotalPages(response.data.total_pages);
          } else {
            // toast.error(response.data.message, {
            //   position: "bottom-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        })
        .catch((error) => {
          console.log(error);

          // toast.error("Something Went Wrong!", {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
    }
  );

  useEffect(()=>{
    getUserType();
  },[])
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    //  modal  //
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUserTypeName("")
  
    setDeletable(false);
  
    setOpen(false);
  };

  // deletable radio button

  const handleChangeSelected = (event) => {
    //console.log("Selected = ", selected);
    setDeletable(event.target.value);
  };


  // form submit 

  const saveDetails = () => {


    

    let isValid = true;
   
    console.log("submit form");
    console.log(deletable)

    if(!checkName(userTypeName)){
      setUsertypeNameError(true)
      isValid = false
    }else {
      setUsertypeNameError(false)
    }

    if(deletable){
      setdeletableError(false)
    }else {
      setdeletableError(true)
      isValid = false
    }

   
    
    if (isValid) {
      setIfLoading(true);
      let data;
      let url = "";
     
        data = {
          name: userTypeName,
          can_delete: deletable,
          login_type: "care_team",
          permission:  [],
          
        };
        console.log("data submit = ", data);

        url = "/client/create-user-type-master";
      
      console.log("DATA == == ", data);

      apiAxios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);
          if (response.data.statusCode == 200) {
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setOpen(false);
            handleClose();
            setIfLoading(false);
            getUserType();
           
            // window.location.reload()

          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
          // localStorage.removeItem("Refresh_Token");
          // localStorage.removeItem("Token");
          // window.location.href = "/";
        });
    }
  };


 

  
  

    return (
        <div>
             <Box className={classes.btncol}>
        <Button className={classes.addprovider} onClick={handleClickOpen}>
          <BiPlusCircle className={classes.icon} /> Add New User Type
        </Button>
      </Box>
      
      <Box className={classes.Throw1}>
        <Box className={classes.Thcol5}>User Type Name</Box>
        <Box className={classes.Thcol6}>Login Type</Box>
        <Box className={classes.Thcol8}>Deletable</Box>
        {/* <Box className={classes.Thcol9}>End Date</Box>
        <Box className={classes.Thcol10}>Reimbursement Fee($)</Box>
        <Box className={classes.Thcol11}>Actions</Box> */}
      </Box>
      {userTypes.length > 0 ? (
        <>
          {userTypes.map((each) => (
            <Box className={classes.Tdrow1}>
              <Box className={classes.Tdcol5}>{each.name}</Box>
              <Box className={classes.Tdcol6}>{each.login_type}</Box>
              <Box className={classes.Tdcol8}>{(each.can_delete === "True")? "Yes" : "No"}</Box>
              
             
            </Box>
          ))}
        </>
      ) : (
        <Box className={classes.Tdrow1}>
          <Box
            className={classes.Tdcol12}
            style={{ width: "100%", textAlign: "center", color: "#dfdfdf" }}
          >
            No Data Available
          </Box>
        </Box>
      )}

        <div 
         style={{
            width: '100%',
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right',
          }}>
      <Pagination
        count={totalPages}
        color="primary"
        page={currentPage}
        onChange={(event, pageNumber) =>
          getUserType({
            table_config_page_no: `${pageNumber}`,
          })
        }
      />
     </div>

      

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {/* modal */}
      <Dialog
        className={classes.userModal}
        open={open}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
            <Typography variant="h3" className={classes.TopHeading}>
              Add New User Type
            </Typography>
            <Button onClick={handleClose} className={classes.closebtn}>
              <BiXCircle className={classes.closeicon} />
            </Button>
          </Box>
          <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
              <form>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    <Box className={classes.Formcol}>
                    
                      <label>
                        User Type Name<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <TextField
                        className={classes.input}
                        placeholder={"Enter user type Name"}
                        value={userTypeName ? userTypeName : ""}
                        type="text"
                        style={{ width: "63%" }}
                        onChange={(e) => setUserTypeName(e.target.value)}
                        
                      />
                    </Box>
                    {userTypeNameError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        User Type Name is required(upto 100 characters long)
                      </p>
                    ) : (
                      <></>
                    )}
                     
                     {/* <Box className={classes.Formcolumn}>
                      <Box style={{display:"flex", alignItems : "center"}}>
                       
                        <label>Permission
                          
                          </label>
                       
                      <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"                                   
                                      >
                                        <label>Client Admin Level</label>
                                       <Checkbox
                                       name="client"
                                       id="clientLevel"
                                      //  value={clientAdminLevel}
                                       inputProps={{ 'aria-label': 'Checkbox A'}}
                                       onChange={handleCheck}
                                        checked={checked === "clientLevel"}
                                       />

                                     <label>Care Team Level</label>
                                       <Checkbox
                                       name="client"
                                       id="careteamLevel"
                                      // value={careteamLevel}
                                       inputProps={{ 'aria-label': 'Checkbox B'}}
                                       onChange={handleCheck}
                                       checked={checked === "careteamLevel"}
                                       />

                                      <label>No Admin Access Level</label>
                                       <Checkbox
                                        name="client"
                                        id="noAdmin"
                                      //  value={noAdmin}
                                       inputProps={{ 'aria-label': 'Checkbox C'}}
                                       onChange={handleCheck}
                                       checked={checked === "noAdmin"}
                                     
                                       />
                                      </RadioGroup>
                                    </FormControl>
                    </Box>
                  </Box> 
 */}



                     <Box className={classes.FormcolNew}>
                      <label>
                        Deletable<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                     
                       
                      <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={deletable}
                          value={deletable}
                          name="radio-buttons-group"
                          onChange={handleChangeSelected}
                        style={{marginLeft: "135px"}}
                        >
                          <Box style={{display:"flex", alignItems:"flex-start"}}>
                          <FormControlLabel
                            className={classes.customRadio}
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            className={classes.customRadio}
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                         </Box>
                        </RadioGroup>
                       
                        </Box>
                        {deletableError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        Please choose a deletable option
                      </p>
                    ) : (
                      <></>
                    )}
                       
                  
                         

                        
                      
                
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <FormGroup aria-label="position" row></FormGroup>
          {
            !ifLoading?
            <Button
              size="large"
              className={classes.loginbtn}
              onClick={saveDetails}
            >
              Submit
            </Button>
            :
            <ThreeDots
                   
                   color="#000000"
                   height={50}
                   width={50}
                   timeout={0} //30 secs
                   
                   />

}

            
        </DialogActions>
      </Dialog>

     
        </div>
        /*
        <div>
            <Box className={classes.Formcol}>
                          <label>User Level</label>
                          <Box className={classes.Selectcol}>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select User Level" className={classes.select} style={{width:'50%'}} />
                      <Box className={classes.btncol}>
                      <Button className={classes.addprovider}><BiPlusCircle className={classes.icon} /> Add New Level</Button>
                      </Box>
                      </Box>
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>User Permission</label>
                          <Box className={classes.Selectcol}>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select Permission" className={classes.select} style={{width:'50%'}} />
                      <Box className={classes.btncol}>
                      <Button className={classes.addprovider} onClick={handleClickOpen}><BiPlusCircle className={classes.icon} /> Add New Permission</Button>
                      </Box>
                      </Box>
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>User Type</label>
                          <Box className={classes.Selectcol}>
                      <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Select User Level" className={classes.select} style={{width:'50%'}} />
                      <Box className={classes.btncol}>
                      <Button className={classes.addprovider}><BiPlusCircle className={classes.icon} /> Add New User Type</Button>
                      </Box>
                      </Box>
                      </Box>
                        
                <Dialog  className={classes.modal}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
            <Box className={classes.ModalTop}>
                <Typography variant="h3" className={classes.TopHeading}>Add New User Permission</Typography>
            <Button onClick={handleClose} className={classes.closebtn}><BiXCircle className={classes.closeicon} /></Button>
            </Box>
          <DialogContentText id="alert-dialog-slide-description">
          <Box className={classes.loginform}>
              <form>
              <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                      <Box className={classes.Formcol}>
                          <label>Program Name</label>
                          <TextField className={classes.input} placeholder="Enter Program Name"
                        type="text" style={{width:'70%'}} />
                      </Box>
                      <Typography variant="" style={{color:'#141621',fontFamily:'Poppins',fontSize:16, paddingBottom:20,display:'flex'}}>Select Permissions For New Template :</Typography>
                      <Box className={classes.checkCol}>
                          <Box className={classes.checkList}>
                              <label>Provider Info</label>
                              <Checkbox {...label} defaultChecked color="default" />
                          </Box>
                          <Box className={classes.checkList}>
                              <label>Billing</label>
                              <Checkbox {...label} defaultChecked color="success" />
                          </Box>
                          <Box className={classes.checkList}>
                              <label>User Setup</label>
                              <Checkbox {...label} defaultChecked color="success" />
                          </Box>
                          <Box className={classes.checkList}>
                              <label>Patient Upload</label>
                              <Checkbox {...label} defaultChecked color="default" />
                          </Box>
                          <Box className={classes.checkList}>
                              <label>Manage Care Team</label>
                              <Checkbox {...label} defaultChecked color="default" />
                          </Box>
                          <Box className={classes.checkList}>
                              <label>Reporting</label>
                              <Checkbox {...label} defaultChecked color="default" />
                          </Box>
                          <Box className={classes.checkList}>
                              <label>Program Setup</label>
                              <Checkbox {...label} defaultChecked color="success" />
                          </Box>
                      </Box>
                      <Box className={classes.Formcol}>
                          <label>Status</label>
                          <TextField className={classes.input} placeholder="Active"
                        type="text" style={{width:'30%'}} />
                      </Box>
                      <Box className={classes.Formcol}>
                          <label style={{marginTop:0,}}>Date Added</label>
                          <Box style={{fontSize:14,color:'#141621'}}>04/22/2021</Box>
                      </Box>
                  </Grid>
                  
              </Grid>
            </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
        <FormGroup aria-label="position" row>
        </FormGroup>
        <Button size="large" className={classes.loginbtn}>
        Save
        </Button>
        </DialogActions>
      </Dialog>
        </div>
        */
    )
}

export default UserTemplate
const useStyles = makeStyles(() => ({
    select:{
        width:'100%',
        border:'none !important',
        borderRadius:'10px !important',
        background:'#F1F1F1',
        height:'50px',
        fontSize:'18px !important',
        paddingLeft:'15px !important',
        paddingRight:'15px !important'
    },
    ModalTop:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        
    },
    Throw1: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px rgba(0,0,0,0.1) solid",
        paddingBottom: 5,
      },
      Tdrow1: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        borderBottom: "1px rgba(0,0,0,0.1) solid",
        padding: "10px 0",
      },
      Thcol: {
        width: "40%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Thcol2: {
        width: "25%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Thcol3: {
        width: "25%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Thcol4: {
        width: "10%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Thcol5: {
        width: "38%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
      },
      Thcol6: {
        width: "40%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
      },
      Thcol7: {
        width: "14%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
      },
      Thcol8: {
        width: "14%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
       
      },
      Thcol9: {
        width: "14%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
      },
      Thcol10: {
        width: "20%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
      },
      Thcol11: {
        width: "14%",
        fontFamily:'poppins',
        fontSize: 14,
        color: "#696969",
        // display: "flex",
        // justifyContent: "flex-end",
        textAlign: "center"
      },
      Tdcol: {
        width: "40%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol2: {
        width: "25%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol3: {
        width: "25%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol4: {
        width: "10%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol5: {
        width: "38%",
        wordWrap: "break-word",
        overflow: "auto",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol6: {
        width: "40%",
        wordWrap: "break-word",
        overflow: "auto",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol7: {
        width: "14%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol8: {
        width: "14%",
        wordWrap: "break-word",
        overflow: "auto",
        fontFamily:'poppins',
        fontSize: 14,
        
      },
      Tdcol9: {
        width: "14%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol10: {
        width: "20%",
        fontFamily:'poppins',
        fontSize: 14,
      },
      Tdcol11: {
        width: "14%",
        display: "flex",
        justifyContent: "flex-end",
        fontFamily:'poppins',
        fontSize: 14,
      },
    checkCol:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        marginBottom:40,
    },
    addprovider: {
        fontSize: 14,
        textTransform: "capitalize",
        color: "#7087A7",
        "&:hover": {
          background: "none",
          color: "#000",
        },
      },
    btncol: {
        marginTop: 15,
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        marginBottom: 20,
      },
    icon: {
        color: "#7087A7",
        fontSize: "20px",
        marginRight: "10px",
      },
    checkList:{
        width:'50%',
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        '& label':{
            width:200,
            color:'#141621'
        }
    },
    TopHeading:{
        fontFamily:'Poppins',
        fontSize:18,
    },
    addprovider:{
        fontSize:14,
        textTransform:'capitalize',
        color:'#7087A7',
        '&:hover':{
            background:'none',
            color:'#000'
        }
    },
    // btncol:{
    //     marginTop:15,
    // },
    backarrow:{
        color:'#8088A8',
        fontSize:'20px',
        marginRight:'8px'
    },
    Formcol:{
        display:'flex',
        alignItems:'flex-start',
        marginBottom:'30px',
        '&>div':{
            width:'100%'
        },
        '& p':{
            fontSize:'18px',
            margin:'0px'
        },
        '& label':{
            flex:'0 0 205px',
            color:'#000',
            fontSize:'15px',
            fontWeight:'400',
            marginTop:10,
        },
        '& .react-dropdown-select-input':{
            width:'100%'
        }
    },
    Formcolumn:{
        display:'flex',
       
        alignItems:'flex-start',
        marginBottom:'30px',
       
        // '&>div':{
        //     width:'100%'
        // },
        '& p':{
            fontSize:'18px',
            margin:'0px'
        },
        '& label':{
            flex:'0 0 205px',
            color:'#000',
            fontSize:'15px',
            fontWeight:'400',
            marginTop:10,
        },
        '& .react-dropdown-select-input':{
            width:'100%'
        }
    },
    FormcolNew:{
      display:'flex',
     
      alignItems:'flex-start',
      marginBottom:'30px',
     
    '&>div':{
          width:'100%'
      },  
      '& p':{
          fontSize:'18px',
          margin:'0px'
      },
      '& label':{
          // flex:'0 0 205px',
          color:'#000',
          fontSize:'15px',
          fontWeight:'400',
          // marginTop:10,
      },
      '& .react-dropdown-select-input':{
          width:'100%'
      }
  },
    modal:{
        '& .MuiPaper-rounded':{
            borderRadius:'10px !important',
            padding:'20px',
            width:'776px',
            maxWidth:'776px'
        }
    },
    userModal:{
      '& .MuiPaper-rounded':{
          borderRadius:'10px !important',
          padding:'20px',
          width:'730px',
          maxWidth:'730px'
      }
  },
    ccmmodal:{
        borderRadius:'10px',
    },
    modalbtn:{
        display:'flex',
        justifyContent:'space-between',
        marginRight:'30px',
        marginLeft:'15px',
        alignItems:'center'
    },
    closebtn:{
        width:'40px',
        position:'absolute',
        right:'10px',
        height:'40px',
        background:'#fff',
        top:'10px',
        minWidth:'40px',
        '&:hover':{
            background:'#fff'
        }
    },
    closeicon:{
        fontSize:'25px',
        color:'#7087A7'
    }, 
    customRadio: {
        // border: "1px solid #3399f1",
        // borderRadius: "10px",
        // height: "50px",
        // margin: "0",
        // background: "#fff",
        // marginBottom: "8px",
        "& .MuiRadio-colorSecondary.Mui-checked": {
          color: "#3399f1",
        },
      },
    loginform:{
        width:'100%',
        marginTop:40,
        '& .MuiInput-underline:before':{
            display:'none'
        },
        '& .MuiInput-underline:after':{
            display:'none'
        },
        '& .MuiInput-formControl':{ 
            height:'50px',
            
        },
        '& .MuiInputBase-input':{
            height:'50px',
            borderRadius:'10px',
            background:'#F1F1F1',
            padding:'0 15px'
        }
    },
    
    loginbtn:{
        background:'#7087A7',
        padding:'0 40px',
        width:'142px',
        height:'45px',
        borderRadius:'10px',
        color:'#fff',
        marginTop:'20px',
        '&:hover':{
            background:'#333'
        }
    },
}));