import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../images/DHCT_Logo.png";
import loginbg from "../images/login-bg.jpg";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { apiAxios } from "../Util/ApiAxios";
import { useState } from "react";
import { checkOtp, checkPassword, numbers } from "../Util/validations";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';


function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState("");
  const [isSuccessUser, setSuccess] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [forgotPassStep1, setForgotPassStep1] = useState(false);
  const [ifLoading, setIfLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorPassChange, setErrorPassChange] = useState("");
  const [otpError, setOtpError] = useState("");

  const [passwordShown, setPasswordShown] = useState(false)

  const [passwordChangeVisible, setPasswordChangeVisible] = useState(false)


  function onUserSubmit(e) {

    
    e.preventDefault();

    let isValid = true

    // setIsValid(true);
    console.log(isValid);
    let data = {
      username: email,
      password: password,
      user_login_panel_type: "0",
    };

    localStorage.removeItem("Refresh_Token");
    localStorage.removeItem("Token");

    if(email && password){
      // setIsValid(true);
      isValid = true
    }
    else if(password  === "" && email === ""){
      setErrorMsg("Username & Password cannot be blank");
      // setIsValid(false);
      isValid = false
    }
    else if(email === "" && password === "" ){
      setErrorMsg("Username & Password cannot be blank");
      // setIsValid(false);
      isValid = false
    }
    else{
      setErrorMsg("Username & Password cannot be blank");
      // setIsValid(false);
      isValid = false
    }

    if(isValid){

    setErrorMsg("");   
    setIfLoading(true);

    apiAxios
      .post("/auth/sign-in", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.statusCode == 200) {
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.setItem(
            "admin_First Name",
            response.data.user_details.first_name
          );
          localStorage.setItem(
            "admin_Last Name",
            response.data.user_details.last_name
          );
          localStorage.setItem("admin_User ID", response.data.user_details.user_id);
          setSuccess(true);
          setIfLoading(false);
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIfLoading(false);
      });

    }  
  }

  function onOtpChange(e) {
    if (checkOtp(e.target.value)) {
      setOtp(e.target.value);
    }
  }

  function onVerify(e) {

    e.preventDefault();

    let isValid = true;

    if(otp == ""){
      // setIsValid(false);
      isValid = false;
      setOtpError("OTP can not be blank")
    }else {
      setOtpError("")
    }


    if(isValid) {


    let data = {
      user_id: localStorage.getItem("admin_User ID"),
      otp: otp,
      login_type: "enterprise",
    };

    setIfLoading(true);

    apiAxios
      .post("/auth/verify-otp", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusCode == 200) {
          localStorage.setItem("admin_Token", response.data.id_token);
          localStorage.setItem("admin_Refresh_Token", response.data.refresh_token);
          
          var decoded = jwt_decode(localStorage.getItem("admin_Token"));
          localStorage.setItem("admin_JWT", JSON.stringify(decoded) );
          props.history.push("/dashboard");
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setIfLoading(false);
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setIfLoading(false);
      });
    }
  }

  function onForgotPass() {

 
    setEmail("");
 
    setForgotPass(true);
    setForgotPassStep1(true);
  }

  function onForgotPassGetOtp(e) {

    e.preventDefault();

    let isValid = true;

    if(email === ""){
      setErrorMsg("Username cannot be blank");
      // setIsValid(false);
      isValid = false
    }

    let data = {
      username: email,
      login_type: "0",
    };

    
    if(isValid){
      setIfLoading(true);
    apiAxios
      .post("/auth/forgot-pass-send-otp", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.statusCode == 200) {
          setUserId(response.data.user_id);
          setForgotPassStep1(false);
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setIfLoading(false);
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIfLoading(false);
      });
    }
  }

  function onPassUpdate(e) {

    // setIsValid(true);
    e.preventDefault();

    let isValid = true;

    let data = {
      user_id: userId,
      otp: otp,
      password: password,
      confirm_password: confirmPassword,
    };

    if(otp == ""){
      // setIsValid(false);
      isValid = false;
      setOtpError("OTP can not be blank")
    }else {
      setOtpError("")
    }

    

    
      if(!checkPassword(password)){
        // setIsValid(false);
        isValid = false;
        setErrorPassChange("Enter a valid password. At least 8 characters— One special character, 1 Uppercase, 1 lowercase & 1 Number.");
        console.log("test");
      }
 

   

      if (password != confirmPassword) {
        // setIsValid(false);
        isValid = false;
        setErrorPassChange("Confirm password didn't match.");
      }
   

  
    

    console.log(isValid);
    console.log(errorPassChange);

    if (isValid) {
      setErrorPassChange("");
      setIfLoading(true);
      apiAxios
        .post("/auth/update-forgot-password", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.statusCode == 200) {
            setForgotPass(false);
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
            window.location.href = "/"
           
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });
    }
  }

  // forgot anchor tag enter key press

  const enterForgot = (e)=>{
    if(e.keyCode == 13){
      onForgotPass();
    }
  
  }

  // verify otp enter key press
   const keyPress = (e)=>{
    if (e.keyCode === 13) {
      onVerify();
    }
   }

  //enter key press

  const keyDownHandler = (e) => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13) {
    onUserSubmit();
  }
};

// forgot password 
 const keyPressHandler = (e) => {

  if (e.keyCode === 13) {
    onForgotPassGetOtp();
  }
 }

 const keyDownHandle = (e) =>{
  if (e.keyCode === 13) {
    onForgotPassGetOtp();
  }
 }

 const togglePasswordVisibility = (flag)=>{

  if(flag === 1){
    setPasswordShown(!passwordShown)
  }else if(flag === 2){
    setPasswordChangeVisible(!passwordChangeVisible)
  }
  
}


  const classes = useStyles();
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Box className={classes.Login}>
        <Box className={classes.LoginLeft}>
          <Box className={classes.logo}>
            <img src={logo} alt="logo" style={{ width: "200px" }} />
          </Box>
          <h2 className={classes.toptext}>HealthyRPM</h2>
          <h6 style={{backgroundColor: "#f1a984", padding: "6px 10px", borderRadius: "40px", width: "100px"}}>Enterprise Admin</h6>
          <Box className={classes.loginform}>
            <form>
              {!forgotPass ? (
                !isSuccessUser ? (
                  <>
                  <Box style={{position:"relative"}}>
                    <TextField
                      className={classes.input}
                      placeholder="Username"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyPress={keyDownHandler}
                    
                    />
                    <TextField
                      style={{ marginBottom: "15px" }}
                      className={classes.input}
                      placeholder="Password"
                      type={passwordShown? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={keyDownHandler}
                     
                    />
                    {password?
                    (<RemoveRedEyeIcon
                className={classes.redEye}
                onClick={()=>togglePasswordVisibility(1)}
              />) : <></>}
              </Box>
                    <FormGroup aria-label="position" row style={{justifyContent:'flex-end'}}>
                      {/* <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary" />}
                        label="Remember Me"
                        labelPlacement="end"
                      /> */}
                      
                      <a
                        style={{ marginTop: "8px", color: "#7da2d5", cursor: "pointer"  }}
                        onClick={(e) => {
                          onForgotPass();
                        }}
                        tabindex="0"
                        onKeyDown={enterForgot}
                      >
                        Forgot Password
                      </a>
                      
                    </FormGroup>
                    {errorMsg ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {errorMsg}
                      </p>
                    ) : (
                      <></>
                    )}
                    { !ifLoading ?
                    <Button
                      size="large"
                      className={classes.loginbtn}
                      type="submit"
                      onClick={onUserSubmit}
                     
                    >
                      LOG IN
                    </Button>
                    :
                   
                    <ThreeDots
                   
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    />
                 
                    }
                  </>
                ) : (
                  <>
                    <TextField
                      className={classes.input}
                      placeholder="Enter OTP"
                      type="tel"
                      value={otp}
                      onChange={(e) => onOtpChange(e)}
                      onKeyPress={keyPress}
                    />

                      {otpError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {otpError}
                      </p>
                    ) : (
                      <></>
                    )}
                    <Box style={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
                   <Button className={classes.backbtn} 
                    onClick={()=>{
                      window.location.href = "/"
                    }
                     
                    }
                    >Back</Button>

                    { !ifLoading ?
                  <>
                    <Button
                      size="large"
                      className={classes.otpSub}
                      onClick={onVerify}
                      type="submit"
                    >
                      VERIFY
                    </Button>
                    </>
                    :
                    <box>
                      <box>
                    <ThreeDots
                   
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    
                    />
                </box>
                </box>
                
                    }
                    </Box>
                  </>
                )
              ) : forgotPassStep1 ? (
                <>
                  <p style={{ marginTop: "0px" }}>Forgot Password</p>
                  <TextField
                    className={classes.input}
                    placeholder="Username"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    onKeyPress={keyPressHandler}
                  />

                    {errorMsg ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {errorMsg}
                      </p>
                    ) : (
                      <></>
                    )}
                  <Box style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                 <Button className={classes.backbtn} 
                    onClick={()=>{
                      window.location.href = "/"
                    }
                     
                    }
                    >Back</Button>
                    
                  { !ifLoading ?
                  <Button
                    size="large"
                    className={classes.otpbtn}
                    onClick={onForgotPassGetOtp}
                    type="submit"
                  >
                    SEND OTP
                  </Button>
                  :
                  <box style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                  <box>
                     <ThreeDots
                   
                   color="#000000"
                   height={50}
                   width={50}
                   timeout={0} //30 secs
                   
                   />
 
                  </box>
                  </box>
                 



                  }
                  </Box>
                </>
              ) : (
                <>
                  <TextField
                    className={classes.input}
                    placeholder="Enter OTP"
                    type="tel"
                    value={otp}
                    onChange={(e) => onOtpChange(e)}
                    onKeyPress={keyDownHandle}
                  />

                   {otpError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {otpError}
                      </p>
                    ) : (
                      <></>
                    )}
               
                  <p style={{ marginTop: "20px" }}>Change Password</p>
                  <Box style={{position:"relative"}}>
                  <TextField
                    style={{ marginBottom: "15px" }}
                    className={classes.input}
                    placeholder="Password"
                    type={passwordShown? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={keyDownHandle}
                  />
                    {password?
                     (<RemoveRedEyeIcon
                className={classes.redEyeIcon}
                onClick={()=>togglePasswordVisibility(1)}
              />) : <></>
                     }
                     </Box>
                     <Box style={{position:"relative"}}>
                  <TextField
                    style={{ marginBottom: "15px" }}
                    className={classes.input}
                    placeholder="Confirm Password"
                    type={passwordChangeVisible? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyPress={keyDownHandle}
                  />
                  {confirmPassword?
                    ( <RemoveRedEyeIcon
                className={classes.eyeIcon}
                onClick={()=>togglePasswordVisibility(2)}
              />) : <></> }
              </Box>
                  {errorPassChange ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {errorPassChange}
                      </p>
                    ) : (
                      <></>
                    )}

                     {/* {otpError ? (
                      <p
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {otpError}
                      </p>
                    ) : (
                      <></>
                    )} */}
                    <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                     <Button className={classes.backbtn} 
                    onClick={()=>{
                      window.location.href = "/"
                    }
                     
                    }
                    >Back</Button>

                  { !ifLoading ?
                  <Button
                    size="large"
                    className={classes.subBtn}
                    onClick={onPassUpdate}
                      
                    
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                   :
                   <box style={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                      <box>
                    <ThreeDots
                   
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    
                    />
                </box>
                </box>
                   }
                   </Box>
                </>
              )}
            </form>
            <p>www.healthyrpm.com</p>
          </Box>
        </Box>
        <Box className={classes.LoginRight}>
          <Box className={classes.rightcontent}>
            <h3 className={classes.rightheading}>About HealthyRPM</h3>
            <p className={classes.righttext}>
              HealthyRPM is DHCT's web-based CCM patient engagement application.
              HealthyRPM is designed for Medicare reimbursement programs such as
              Chronic Care Management (CCM), Principal Care Management (PCM),
              and Behavioral Health.
            </p>
          </Box>
          <img src={loginbg} alt="login-bg" />
        </Box>
      </Box>
    </div>
  );
}

export default Login;
const useStyles = makeStyles(() => ({
  Login: {
    display: "flex",
    width: "100%",
    '@media only screen and (max-width: 820px)':{
      flexDirection:'column'
    },
    '@media only screen and (max-width: 768px)':{
      flexDirection:'column'
    },
    "& a": {
      textDecoration: "none",
    },
  },
  LoginLeft: {
    width: "40%",
    paddingTop: "50px",
    paddingLeft: "5%",
    paddingRight: "5%",
    textAlign: "left",
    '@media only screen and (max-width: 1200px)':{
      width: "36%",
      paddingLeft: "2%",
    paddingRight: "2%",
    },
    '@media only screen and (max-width: 820px)':{
      width: "90%",
      paddingLeft: "5%",
    paddingRight: "5%",
    },
    '@media only screen and (max-width: 768px)':{
      width: "90%",
      paddingLeft: "5%",
    paddingRight: "5%",
    },
    "& p": {
      fontSize: "16px",
      color: "#AEAEAE",
      marginTop: "70%",
      '@media only screen and (max-width: 820px)':{
        marginTop: "5%",
      },
      '@media only screen and (max-width: 768px)':{
        marginTop: "5%",
      }
    },
  },
  toptext: {
    fontSize: "34px",
    fontWeight: "bold",
    marginBottom: "50px",
  },
  LoginRight: {
    width: "50%",
    position: "relative",
    '@media only screen and (max-width: 1200px)':{
      width: "60%",
    },
    '@media only screen and (max-width: 820px)':{
      width: "100%",
    },
    '@media only screen and (max-width: 768px)':{
      width: "100%",
    },
    "& img": {
      width: "100%",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
    marginBottom: "30px",
  },
  loginform: {
    width: "70%",
    '@media only screen and (max-width: 1200px)':{
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      background: "#F1F1F1",
      padding: "0 15px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
  },
  loginbtn: {
    background: "#7087A7",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  subBtn: {
    background: "#7087A7",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    marginLeft: "78px",
    "&:hover": {
      background: "#333",
    },
  },
  backbtn: {
    background: "#7087A7",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  otpSub: {
    background: "#7087A7",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    marginLeft : "78px",
    "&:hover": {
      background: "#333",
    },
  },
  otpbtn: {
    background: "#7087A7",
    padding: "0 40px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    marginLeft: "70px",
    "&:hover": {
      background: "#333",
    },
  },
  rightcontent: {
    width: "80%",
    padding: "0 10%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    left: "0px",
    top: "0px",
    bottom: "0px",
  },
  rightheading: {
    color: "#fff",
    margin: "0px",
  },
  righttext: {
    textAlign: "left",
    color: "#fff",
  },
  redEyeIcon : {
    position: "absolute",
    // top: "20px",
   bottom: "27px",
    right: "15px",
    cursor: "pointer",
    opacity: 0.8
    
  },
  eyeIcon : {
    position: "absolute",
    // top: "20px",
   bottom: "30px",
    right: "15px",
    cursor: "pointer",
    opacity: 0.8

  },
  redEye: {
    position: "absolute",
    // top: "20px",
   bottom: "29px",
    right: "14px",
    cursor: "pointer",
    opacity: 0.8
  },
}));
