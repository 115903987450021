import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core';
import {RiAdminFill} from 'react-icons/ri';

function TopHeading() {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h2" className={classes.Toptext}><RiAdminFill className={classes.marginRightIcon} />Enterprise Admin</Typography>
        </div>
    )
}

export default TopHeading
const useStyles = makeStyles(() => ({
    Toptext:{
        fontSize:'15px',
        color:'#7087a7',
        textTransform:'capitalize',
        marginBottom:'20px',
        marginTop:22,
        fontWeight:'600',
        fontFamily:'Poppins',
       
    },
    marginRightIcon:{
        marginRight: '8px',
    }
    
}));