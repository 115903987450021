import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTime } from "react-timer-hook";
import Select from "react-dropdown-select";
import { FiEdit } from "react-icons/fi";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MonthPicker from "simple-react-month-picker";
import TextField from "@material-ui/core/TextField";
import { apiAxios } from "../../Util/ApiAxios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  BiChevronUp,
  BiChevronDown,
  BiArrowBack,
  BiPlusCircle,
  BiXCircle,
  BiCheckCircle,
} from "react-icons/bi";
import { is } from "date-fns/locale";
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Billing(options) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [clientOptions, setClientOptions] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [client, setClient] = React.useState("");
  const [programList, setProgramList] = React.useState([]);
  const [programOptions, setProgramOptions] = React.useState([]);
  const [program, setProgram] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [providerOptions, setProviderOptions] = React.useState([]);
  const [provider, setProvider] = React.useState("");
  const [providerList, setProviderList] = React.useState([]);
  const [providerListOption, setProviderListOption] = React.useState([]);
  const [programProvider, setProgramProvider] = React.useState("");
  // const [month,setMonth] = React.useState("");
  const [billingListData, setBillingListData] = React.useState([]);
  const [tableMessage, setTableMessage] = React.useState("");
  const [tableMsg, setTableMsg] = React.useState("");
  const [edit, setEdit] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [checkbox, setCheckBox] = React.useState([])

  const [codes, setCodes] = React.useState([]);
  const [onFocusIndex, setOnFocusIndex] = React.useState("");
  const [searchString, setSearchString] = React.useState([])

  const [clientError, setClientError] = React.useState(false);
  const [programError, setProgramError] = React.useState(false);
  const [providerError, setProviderError] = React.useState(false);
  const [startDateError, setStartDateError] = React.useState(false)
  const [excelList, setExcelList] = useState("");
  const [startDate, setStartDate] = useState(
    moment().toDate()
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentListPage, setCurrentListPage] = useState(1);
  const [totalListPages, setTotalListPages] = useState(1);
  const [codesOptions, setCodesOptions] = useState([]);
  const [availableCodes, setAvailableCodes] = useState([]);

  

  const handleChange2 =(event,k,data) => {
    // setState({ ...state, [event.target.name]: event.target.checked });

    // if (event.target.id === k) {
    //   setIsChecked(true);
    //   billingUpdate(data, k);
    // }
  
  const {id, checked} = event.target
  console.log(id)
  console.log(checked)

if(checked && id === "checkedAll"){
  setCheckBox([...checkbox,id])
  // billingBulkApprove();
  bulkApproveModal();
}else {

  // setCheckBox(prev =>prev.filter(x=> x!== "checkAll"))
    let isCheck = false;
  if(checked) {
    
    setCheckBox(prev=>[...prev,id])
    setIsChecked(!isChecked)
    isCheck = true;
    
    approveModal(k,data, isCheck);
       // billingUpdate(data, k)
     
     
    
   }else {
     setCheckBox(prev=> prev.filter(x=> x !== id))
     setIsChecked(false)
     isCheck  = false;
     
   }

}


  
  
  }


 

// const handleChange3 = (event) => {
//   const {id,checked} = event.target
//   console.log(id)
//   console.log(checked)
// }

  

  // const handleCodes = (e)=>{
  //   console.log(e.target.value)
  //   console.log(e.target.name)
  //   const value =e.target.value
  //   setCodes({
  //     ...codes,
  //     [e.target.name] : value
  //   });

  // }

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = () => {
    let data = {
      table_config_rows_per_page: "200",
      table_config_page_no: "1",
    };

    apiAxios
      .post("/client/list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setClientList(response.data.data);
        } else {
          // toast.error(response.data.message, {
          //   position: "bottom-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      })

      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        localStorage.removeItem("admin_Refresh_Token");
        localStorage.removeItem("admin_Token");
        window.location.href = "/";
      });
  };

  useEffect(() => {
    if (clientList) {
      let ob_arr = [];

      for (var i = 0; i < clientList.length; i++) {
        let label = clientList[i].client_name;
        let value = clientList[i].client_id;

        let ob = {
          label: label,
          value: value,
        };

        ob_arr.push(ob);
      }

      setClientOptions(ob_arr);
    }

    if (programList.length > 0) {
      let ob_arr = [];
      for (var i = 0; i < programList.length; i++) {
        let label = programList[i].program_name;
        let value = programList[i].template_id;

        let obj = {
          label: label,
          value: value,
        };

        ob_arr.push(obj);
      }

      let objc = {
        label: "All Programs",
        value: "all",
      };

      ob_arr.unshift(objc);
      setProgramOptions(ob_arr);
    }

    if (userList.length > 0) {
      let ob_arr = [];
      for (var i = 0; i < userList.length; i++) {
        if (userList[i].short_name === "Provider") {
          let label =
            userList[i].provider_first_name +
            " " +
            userList[i].provider_last_name;

          let value = userList[i].id;

          let obj = {
            label: label,
            value: value,
          };

          ob_arr.push(obj);
        }
      }

      let objc = {
        label: "All Providers",
        value: "all",
      };

      ob_arr.unshift(objc);
      setProviderOptions(ob_arr);
    }
  }, [clientList, programList, userList]);

  // client provider program list

  const getProgramList = (client_id) => {
    setProgramOptions([]);

    setProgram("");
    setProvider("")

    let data = {
      client_id: client_id ? client_id : "",
      table_config_rows_per_page: "150",
      table_config_page_no: "1",
      program_id: "",
      provider_id: "",
      search_value: "",
      status: "1",
      is_unique: "1",
    };

    apiAxios
      .post("/client/client-provider-program-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setProgramList(response.data.data);
        } else if (response.data.statusCode == 502) {
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        localStorage.removeItem("admin_Refresh_Token");
        localStorage.removeItem("admin_Token");
        window.location.href = "/";
      });
  };

  // provider list of a particular client

  const getUserList = (client_id) => {




    let data = {
      client_id: client_id ? client_id : "",
      table_config_rows_per_page: "200",
      table_config_page_no: "1",
      user_master_type_id: "",
      cutid: "",
      search_value: "",
      is_provider: 0,
    };

    apiAxios
      .post("/client/clientuserlist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("response --> ", response);
        if (response.data.statusCode == 200) {
          setUserList(response.data.data);
        } else if (response.data.statusCode == 502 || response.data.statusCode == 501) {

        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        localStorage.removeItem("admin_Refresh_Token");
        localStorage.removeItem("admin_Token");
        window.location.href = "/";
      });
  };

  // related provider list of a particular program in a particular client

  const getProviderList = (id) => {
    let data = {
      client_id: client.value,
      program_id: id,
      table_config_rows_per_page: "200",
      table_config_page_no: "1",
    };

    setProviderOptions([]);
    setUserList([]);
    setProvider("")

    apiAxios
      .post("/provider/provider-for-given-program-list", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("res", response.data);
        // setShowLoader(false);

        if (response.data.statusCode == 200) {
          setProviderList(response.data.data);
          toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        // setShowLoader(false);
        console.log(error);
        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    if (providerList) {
      var ob_arr = [];
      //console.log("user types = ", userTypes);
      for (var i = 0; i < providerList.length; i++) {
        let value = providerList[i].client_provider_id;
        
        let label = providerList[i].name;

        let ob = {
          label: label,
          value: value,
        };
        //console.log("ob types = ", ob);
        ob_arr.push(ob);
      }

      console.log(ob_arr);
      setProviderListOption(ob_arr);
      // setSelectedProgram(ob_arr[0]);
    }
  }, [providerList]);



  // getting billing list after submitting client, program, provider filter

  const submitBillingFilter = useCallback(
    async ({
      table_config_rows_per_page = "5",
      table_config_page_no = "1",
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let isValid = true;

      console.log("submit form");
      console.log(client);
      console.log(program);
      console.log(provider);
      console.log(moment(startDate).format("YYYY-MM"));

      if (client) {
        setClientError(false);
      } else {
        setClientError(true);
        isValid = false;
      }

      if (program) {
        setProgramError(false);
      } else {
        setProgramError(true);
        isValid = false;
      }

      if (provider) {
        setProviderError(false);
      } else {
        setProviderError(true);
        isValid = false;
      }

      if(startDate !== null){
        setStartDateError(false)
       
      }else {
        setStartDateError(true)
        isValid = false
      }

      if (isValid) {
        setCheckBox([])
        setBillingListData([]);
        setTableMessage("Loading...");
        // setIfLoading(true)
        let data = {
          client_id: client.value,
          prog_id: program.value,
          provider_id: provider.value,
          month: startDate ? moment(startDate).format("YYYY-MM") : "",
          is_paginated: "1",
          table_config_rows_per_page: table_config_rows_per_page,
          table_config_page_no: table_config_page_no,
        };

        apiAxios
          .post("/finalize/billing-codes/list", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("admin_Token"),
            },
          })
          .then((response) => {
            console.log("response billing list --> ", response);
            if (response.data.statusCode == 200) {
              console.log(response.data.data);
              setTotalPages(response.data.total_pages);

              if (
                response.data.data.length == 0 ||
                Object.entries(response.data.data).length == 0
              ) {
                setTableMessage("No Data Found");
              } else {
                setBillingListData(response.data.data);
              }
            } else if (response.data.statusCode == 404) {
              setTableMessage("No Data Found");
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setIfLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);

            toast.error("Something Went Wrong!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setIfLoading(false);
          });
      }
    }
  );

  // update billing data

  function billingUpdate (id,item,flag) {
    console.log("submit form");

    console.log(item.available_add_on_codes)
    // console.log(isChecked)
    console.log(checkbox.find(x=> x === id)? "yes" : "no")
    console.log(flag ? "true" : "false")
    

    console.log(codes);

    let data = {
      billing_token: id,
      is_approved: flag ? "1" : "0",
      billing_data: {
        first_name: item.first_name,
        last_name: item.last_name,
        middle_name: item.middle_name,
        billing_tier: item.billing_tier,
        date: item.date,
        codes: codes,
        available_add_on_codes: item.available_add_on_codes ? item.available_add_on_codes : [] ,
        overall_time: item.overall_time,
        remaining_time: item.remaining_time,
        primary_billing_code: item.primary_billing_code,
        hrpm_id: item.hrpm_id,
        agent_first_name: item.agent_first_name,
        agent_last_name: item.agent_last_name,
      },
    };

    apiAxios
      .post("/finalize/billing-codes/update", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("admin_Token"),
        },
      })
      .then((response) => {
        console.log("response billing update --> ", response);
        if (response.data.statusCode == 200) {
          setEdit("");
          console.log(response.data.data);
          toast.success(response.data.messsage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          submitBillingFilter();
          setIsChecked(false)
        } else {
          setEdit("");
          toast.error(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setIfLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error("Something Went Wrong!", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setIfLoading(false);
      });
  }
  
  
  useEffect(() => {
    if (codesOptions) {
      let arr = [];
      for (var i = 0; i < codesOptions.length; i++) {
        let name = codesOptions[i].code;
        let id = i;
        let ob = {
          id: id,
          name: name,
        };

        arr.push(ob);
      }

      console.log(arr);

      setAvailableCodes(arr);
    }
  }, [codesOptions]);

 

  const handleOnSearch = (string, results,index) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
   
    console.log(string)
    console.log(results)
    console.log(index)
    console.log(string, results);
 
    setSearchString(string)
    if(string.length < 1){
      setCodes(prev=>prev.filter((x,i)=> i != index))
      console.log("search if")
    }
  
    
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item,index) => {
    // the item selected
    

   console.log(typeof(codes))
   console.log(item.name)



   if(item){
    setCodes(prev=> prev.filter((x,i)=> i !== index))
    setCodes(prev =>[...prev,item.name])
   }

    

    // var arr=[];
    // const isChecked = item?  item  : null
    // console.log(isChecked)
    // // const eventID = event ? event.target.name : null
    // const check = isChecked ? isChecked : null
    // var symp = [];
    // var ros = ROSList;
    // var up_symp = [];
    // setUpdateList("");
    // // console.log("Event ID ->", eventID);
    // console.log("Check ->", check);
    // for(var i =0;i< ros.length;i++){
    //   symp = ros[i].symptoms;
    //   for(var j=0; j < symp.length;j++){
    //     //  symp[j].checked = 'N';
    //      if(symp[j].checked === 'N' || symp[j].checked === null){
    //       symp[j].checked = 'N';
    //       console.log("this is working first Time")
    //      }

    //     if(symp[j].id === eventID){
    //       if(isChecked === 'Y'){
    //         symp[j].checked = 'Y';

    //       }
    //       else {
    //         symp[j].checked = 'N';

    //       }
    //     }

    //     up_symp.push(symp[j]);
    //   }
    //   ros[i].symptom = symp;
    // }
    // //console.log(up_symp);
    // setUpdateList(up_symp);
    // setROSList(ros);
    // setFlag(1);
    // console.log("updateList")
    // console.log(updateList)
  };

  const handleOnFocus = (index) => {
    console.log("Focused");
    setOnFocusIndex(index)
  };

  const handleClear = (index)=>{

    console.log("clear")
    console.log(index)
    setCodes(prev => prev.filter((x,i)=> i != index))
  }

  const billingBulkApprove = ()=>{
   

    

        let data = {
          client_id: client.value,
          prog_id: program.value,
          provider_id: provider.value,
          month: startDate ? moment(startDate).format("YYYY-MM") : "",
          is_paginated: 0,
         
        };

        apiAxios
          .post("/finalize/billing-codes/bulk-approve", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("admin_Token"),
            },
          })
          .then((response) => {
            console.log("response bulk apprve list --> ", response);
            if (response.data.statusCode == 200) {
              // console.log(response.data.data);
              // setTotalPages(response.data.total_pages);
              submitBillingFilter();
             
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setIfLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);

            toast.error("Something Went Wrong!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setIfLoading(false);
          });
      
    }
  
    const downloadBilling = ()=>{
   

    
      // console.log("submit form");
      // console.log(client);
      // console.log(program);
      // console.log(provider);
      // console.log(moment(startDate).format("YYYY-MM"));

   

      
        // setBillingListData([]);
        // setTableMessage("Loading...");
        // setIfLoading(true)
        let data = {
          client_id: client.value,
          prog_id: program.value,
          provider_id: provider.value,
          month: startDate ? moment(startDate).format("YYYY-MM") : "",
          is_paginated: 0,
         
        };

        apiAxios
          .post("/finalize/billing-codes/create-billing-file", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("admin_Token"),
            },
          })
          .then((response) => {
            console.log("response bulk apprve list --> ", response);
            if (response.data.statusCode == 200) {
                let domain = response.data.domain
                let file = response.data.file_uri
                console.log(domain)
                console.log(file)
                console.log(domain+file)
                window.location = domain + file

                toast.success(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
          
                 submitBillingFilter();
             
            } else {
              toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setIfLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);

            toast.error("Something Went Wrong!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setIfLoading(false);
          });
      
    }



    // modal for a particular list row checkbox approve

    const approveModal = (k,data, isCheck) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Are you sure?</h1>
              <p>You want to approve this record?</p>
              <p>Note: After submit you can not undo it</p>
              <button className={classes.modalNoBtn} style={{border: '2px solid #0f3d6e',  cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
              <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
                onClick={() => {
                  
                  billingUpdate(k,data, isCheck)
                  onClose();
                }}
              >
                Yes, Approve
              </button>
            </div>
          );
        }
      });
    };

    const bulkApproveModal = (k,data) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Are you sure?</h1>
              <p>You want to approve all records?</p>
              <p>Note: After submit you can not undo it</p>
              <button className={classes.modalNoBtn} style={{border: '2px solid #0f3d6e',  cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
              <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
                onClick={() => {
                 
                 
                  billingBulkApprove();
                  onClose();
                }}
              >
                Yes, Approve
              </button>
            </div>
          );
        }
      });
    };









  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box className={classes.loginform}>
        <Box className={classes.TopFiletr}>
          <Box
            className={classes.SelectCol1}
            style={{ marginRight: 15, marginBottom: 15 }}
          >
            <Select
              options={clientOptions}
              placeholder="Select client"
              className={classes.select}
              style={{ width: "100%" }}
              onChange={(values) => {
                setClient(values[0]);
                setProviderOptions([]);
                setUserList([]);
                setProgramOptions([]);
                setProgramList([]);
                getProgramList(values[0].value);
                getUserList(values[0].value);
              }}
              value={client}
              backspaceDelete={false}
            />
            {clientError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a client First
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box
            className={classes.SelectCol3}
            style={{ marginBottom: 15 }}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className={classes.dateInput}
              maxDate={moment().toDate()}
              minDate={moment().subtract(1, "years").toDate()}
            />
             {startDateError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a date
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box
            className={classes.SelectCol2}
            style={{ marginRight: 15, marginBottom: 15 }}
          >
            <Select
              options={programOptions}
              placeholder="Select Program "
              className={classes.select}
              style={{ width: "100%" }}
              onChange={(values) => {
                setProgram(values[0]);
                if (values[0].value == "all") {
                  setProvider("");
                  getUserList(client.value);

                } else {
                  getProviderList(values[0].value);
                }
              }}
              backspaceDelete={false}
            />
            {programError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a program
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box className={classes.SelectCol4} style={{ marginRight: 15 }}>
            <Select
              placeholder="Select Provider"
              className={classes.select}
              style={{ width: "100%" }}
              options={
                providerOptions.length > 0
                  ? providerOptions
                  : providerListOption
              }
              onChange={(values) => setProvider(values[0])}
              backspaceDelete={false}
            />
            {providerError ? (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Please select a provider
              </p>
            ) : (
              <></>
            )}
          </Box>
          <Box>
            <Button className={classes.SubmitBtn} onClick={submitBillingFilter}>
              Create Billing Preview
            </Button>
          </Box>
        </Box>
        <Box className={classes.TopFiletr}>
          {/* <Box style={{marginRight:15,width:'33%'}}><TextField className={classes.input} placeholder="Other" type="text" /></Box> */}
        </Box>
        
      </Box>
      <Box
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <FormControlLabel
            control={
              <GreenCheckbox
                onChange={(e)=>handleChange2(e)}
                // checked={deSelect}
                id="checkedAll"
                color="primary"
                // checked={Object.entries(billingListData).every(([key,data])=> data.status === 2)? "checked" : ""}

              checked = {(checkbox.find(x => x === "checkedAll") || (Object.keys(billingListData).length ===0 ? "" :Object.entries(billingListData).every(([key,data])=> data.status === 2))) ? "checked" : ""} 
              

              />
            }
            label="Approve All"
          />
        </Box>
      <Box className={classes.BillingTable}>
        <Box className={classes.Throw}>
        <Box className={classes.Thcol1}>Patient ID</Box>
          <Box className={classes.Thcol1}>First Name</Box>
          <Box className={classes.Thcol2}>Last Name</Box>
          <Box className={classes.Thcol3}>
            <Box className={classes.providerbtn} role="button">
              Dos
              <span>
                <button>
                  <BiChevronUp />
                </button>
                <button>
                  <BiChevronDown />
                </button>
              </span>
            </Box>
          </Box>
          <Box className={classes.Thcol4}>User</Box>
          <Box className={classes.Thcol5}>Billing Tier</Box>
          <Box className={classes.Thcol10}>Primary BCode</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol6}>Code</Box>
          <Box className={classes.Thcol7}>
            {" "}
            <Box className={classes.providerbtn} role="button">
              Time
              <span>
                <button>
                  <BiChevronUp />
                </button>
                <button>
                  <BiChevronDown />
                </button>
              </span>
            </Box>
          </Box>
          <Box className={classes.Thcol7}>
            {" "}
            <Box className={classes.providerbtn} role="button">
              R.Time
              <span>
                <button>
                  <BiChevronUp />
                </button>
                <button>
                  <BiChevronDown />
                </button>
              </span>
            </Box>
          </Box>

          <Box className={classes.Thcol8}>APR</Box>
          <Box className={classes.Thcol9}>Mod</Box>
        </Box>
        {Object.keys(billingListData).length > 0 ? (
          <>
            {Object.entries(billingListData).map(([key, data]) => (
              <Box className={classes.Tdrow} key={key}>
                <Box className={classes.Tdcol1}>{data.hrpm_id}</Box>
                <Box className={classes.Tdcol1}>{data.first_name}</Box>
                <Box className={classes.Tdcol2}>{data.last_name}</Box>
                <Box className={classes.Tdcol3}>
                  {moment(data.date).format("YYYY-MM-DD")}
                </Box>
                <Box className={classes.Tdcol4}>{data.agent_first_name + " " + data.agent_last_name}</Box>
                <Box className={classes.Tdcol5}>{data.billing_tier}</Box>
                <Box className={classes.Tdcol10}>
                  {data.primary_billing_code}
                </Box>
            {data.codes.length < 5 ?
                <>
               {data.codes.map((code, index) => (
                  <>
                    {(edit === key  && data.status === 1 )  ? 
                      <Box className={classes.Tdcol6}>
                   

                        <div>
                          <ReactSearchAutocomplete
                            items={availableCodes}
                            inputSearchString={code}
                            fuseOptions={{ keys: ["name"] }}
                            onSearch={(str,res)=>handleOnSearch(str,res,index)}
                            onHover={handleOnHover}
                            onSelect={(item)=>handleOnSelect(item,index)}
                            onFocus={()=>handleOnFocus(index)}
                            onClear={()=>handleClear(index)}
                            key = {index}
                            autoFocus
                            // formatResult={formatResult}
                            styling={{
                              height: "35px",
                              fontSize: "11px",
                              clearIconMargin: "1px 3px 0 0",
                              searchIconMargin: "0 0 0 2px",
                              borderRadius: "8px",
                            }}
                          />
                        </div>
                      </Box>
                     : 
                      <Box key={index} className={classes.Tdcol6}>
                        {/* {typeof code === "object" ? "" : code} */}
                        {/* {code[index]? code[index] : "N/A"} */}
                        {code}
                      
                      </Box>
                    }
                  </>
                ))}



               
                
                {[...new Array(5-data.codes.length).keys()].map((ele,i)=>(
                  
                  // <Box key={i} className={classes.Tdcol6}>&nbsp;</Box>)
                  <>
                  {(edit === key  && data.status === 1 )  ? 
                    <Box className={classes.Tdcol6}>
                 

                      <div>
                        <ReactSearchAutocomplete
                          items={availableCodes}
                          // inputSearchString={code}
                          fuseOptions={{ keys: ["name"] }}
                          onSearch={(str,res)=>handleOnSearch(str,res,data.codes.length+i)}
                          onHover={handleOnHover}
                          onSelect={(item)=>handleOnSelect(item,data.codes.length+i)}
                          onFocus={()=>handleOnFocus(data.codes.length+i)}
                          onClear={()=>handleClear(data.codes.length+i)}
                          key = {data.codes.length+i}
                          autoFocus
                          // formatResult={formatResult}
                          styling={{
                            height: "35px",
                            fontSize: "11px",
                            clearIconMargin: "1px 3px 0 0",
                            searchIconMargin: "0 0 0 2px",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    </Box>
                   : 
                    <Box key={data.codes.length+i} className={classes.Tdcol6}>
                      {/* {typeof code === "object" ? "" : code} */}
                      {/* {code[index]? code[index] : "N/A"} */}
                      &nbsp;
                    
                    </Box>
                  }
                </>)
                )
} 
</>

: 
<>
 {data.codes.slice(0,5).map((code, index) => (
    <>
      {(edit === key  && data.status === 1 )  ? 
        <Box className={classes.Tdcol6}>
     

          <div>
            <ReactSearchAutocomplete
              items={availableCodes}
              inputSearchString={code}
              fuseOptions={{ keys: ["name"] }}
              onSearch={(str,res)=>handleOnSearch(str,res,index)}
              onHover={handleOnHover}
              onSelect={(item)=>handleOnSelect(item,index)}
              onFocus={()=>handleOnFocus(index)}
              onClear={()=>handleClear(index)}
              key = {index}
              autoFocus
              // formatResult={formatResult}
              styling={{
                height: "35px",
                fontSize: "11px",
                clearIconMargin: "1px 3px 0 0",
                searchIconMargin: "0 0 0 2px",
                borderRadius: "8px",
              }}
            />
          </div>
        </Box>
       : 
        <Box key={index} className={classes.Tdcol6}>
          {/* {typeof code === "object" ? "" : code} */}
          {/* {code[index]? code[index] : "N/A"} */}
          {code}
        
        </Box>
      }
    </>
  ))}

</>
}

       

            

                <Box className={classes.Tdcol7}>
                  {moment.utc(data.overall_time * 1000).format("HH:mm:ss")}
                </Box>
                <Box className={classes.Tdcol7}>
                  {moment.utc(data.remaining_time * 1000).format("HH:mm:ss")}
                </Box>
                <Box className={classes.Tdcol8}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        onChange={(e) => handleChange2(e,key,data)}
                        id={key}
                        // checked = {data.status === 2  ? "checked" : ""}

                       
                        checked={(checkbox.find((i)=> key === i) || checkbox.find((i)=> i === "checkedAll") || data.status === 2) ? "checked" : ""}

                        value={[key, data]}
                      />
                    }
                  />
                </Box>
                {(edit === key && data.status === 1) ? (
                  <Box className={classes.Tdcol9}>
                    <button
                      className={classes.SaveBtn}
                      onClick={() => billingUpdate(key,data,isChecked)}
                    >
                      Save
                    </button>
                    <button
                      className={classes.CancelBtn}
                      onClick={() => {
                        setEdit("");
                        // setCodes({  code1: "",
                        //   code2 : "",
                        //   code3 : "",
                        //   code4 : "",
                        //   code5: "",

                        // })

                        setCodes([]);

                        setCodesOptions([]);
                      }}
                    >
                      Cancel
                    </button>
                  </Box>
                ) : (
                  <Box className={classes.Tdcol9}>
                    <Button className={classes.EditIcon}
                      onClick={() => {
                        setEdit(key);
                        setCodes([])
                         
                        

                        console.log(data.available_add_on_codes);
                        setCodes([...data.codes])

                        setCodesOptions(data.available_add_on_codes);
                      }}
                    >
                      {" "}
                      <FiEdit size="20" color="#5C85EE" />
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </>
        ) : (
          <>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {tableMessage}
            </Box>
          </>
        )}
      </Box>
      <br />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, pageNumber) =>
            submitBillingFilter({
              table_config_page_no: `${pageNumber}`,
            })
          }
        />
      </div>

      <Box className={classes.BtnCol}>
        {/* <Button className={classes.SubmitBtn} onClick={downloadBilling}>Create Billing File</Button> */}
     
{Object.keys(billingListData).length > 0 ?
    <Button className={classes.SubmitBtn} onClick={downloadBilling}>Create Billing File</Button>
    :
    <Button className={classes.SubmitBtn} style={{pointerEvents : "none"}}>Create Billing File</Button>

}
      </Box>
    </div>
  );
}

export default Billing;

const useStyles = makeStyles(() => ({
  select: {
    width: "148px !important",
    height: "40px !important",
    border: "1px solid #AEAEAE !important",
    borderRadius: "10px !important",
    background: "#fff !important",
    "& .css-16cm63-DropDown": {
      width: "100% !important",
      top: "30px !important",
    },
  },
  SelectCol1: {
    width: "20%",
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: "31%",
      },
  },
  SelectCol3: {
    width: "10%",
    marginRight:20,
    '& .react-datepicker-ignore-onclickoutside':{
      fontFamily:'poppins'
    },
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: "20%",
        marginRight:15,
      },
  },
  SelectCol4:{
    width: "22%",
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: "31%",
      },
  },
  SelectCol2: {
    width: "25%",
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: "44%",
        marginRight:'0px !important'
      },
  },
  Toptext: {
    fontSize: "12px",
    color: "#919699",
    margin: "0px",
  },
  BillingTable: {
    width: "100%",
    overflowX: "auto",
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        overflowX: "auto",
      },
  },
  Bottomtext: {
    fontSize: "16px",
    color: "#141621",
    margin: "0px",
    fontWeight: "normal",
  },
  Throw: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 5,
    borderBottom: "1px #ccc solid",
    width: 1366,
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: 1366,
      },
      
  },
  Tdrow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px #ccc solid",
    padding: "7px 0",
    width: 1366,
    "@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)":
      {
        width: 1366,
      },
      '& .nUdNW input':{
        fontFamily:'poppins',
        fontSize:13,
        paddingLeft:8,
      }
  },
  Thcol1: {
    width: "15%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol2: {
    width: "15%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol3: {
    width: "14%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol4: {
    width: "12%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol5: {
    width: "13%",
    fontSize: 14,
    color: "#696969",
    textAlign: "center",
  },
  Thcol6: {
    width: "17%",
    fontSize: 14,
    color: "#696969",
    textAlign : "center",
  },
  Thcol10: {
    width: "14%",
    fontSize: 14,
    color: "#696969",
    textAlign : "center"
  },
  Thcol7: {
    width: "10%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol8: {
    width: "5%",
    fontSize: 14,
    color: "#696969",
  },
  Thcol9: {
    width: "10%",
    fontSize: 14,
    color: "#696969",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  Tdcol1: {
    width: "15%",
    fontSize: 14,
    paddingRight: "0.5%",
    wordWrap : "break-word"
  },
  Tdcol2: {
    width: "14.5%",
    fontSize: 14,
    paddingRight: "0.5%",
    wordWrap : "break-word"
  },
  Tdcol3: {
    width: "14%",
    fontSize: 14,
    paddingRight: "0.5%",
    
  },
  Tdcol4: {
    width: "11.5%",
    fontSize: 14,
    paddingRight: "0.5%",
  },
  Tdcol5: {
    width: "13%",
    fontSize: 14,
    textAlign: "center",
    paddingRight: "0.5%",
  },
  Tdcol6: {
    width: "17%",
    fontSize: 14,
    paddingRight: "0.5% !important",
    textAlign:'center'
  },
  Tdcol10: {
    width: "14%",
    fontSize: 14,
    paddingRight: "0.5%",
    textAlign:'center'
  },
  Tdcol7: {
    width: "10%",
    fontSize: 14,
    paddingRight: "0.5%",
  },
  Tdcol8: {
    width: "5%",
    paddingRight: "0.5%",
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
  },
  Tdcol9: {
    width: "10%",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 14,
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign:'right'
  },
  editinput: {
    width: "90%",
    borderRadius: 3,
    border: "1px #ccc solid",
    textAlign: "center",
    padding: "3px 0",
    "&:focus": {
      border: "1px solid #5C85EE !important",
    },
  },
  dateInput: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "90%",
    paddingLeft:'15px',
    // background:'#fff !important',
    background: "#F1F1F1",
    fontFamily:'Poppins'
  },
  SaveBtn: {
    width: 60,
    background: "#7087A7",
    borderRadius: 5,
    fontSize: 12,
    color: "#fff",
    border: "none",
    padding: "4px 0",
    marginBottom: 4,
    cursor: "pointer",
    "&:hover": {
      background: "#000",
      color: "#fff",
    },
  },
  CancelBtn: {
    width: 60,
    background: "#fff",
    borderRadius: 5,
    fontSize: 12,
    color: "#BD3535",
    border: "1px #BD3535 solid",
    padding: "2px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#BD3535",
      color: "#fff",
    },
  },
  SubmitBtn: {
    background: "#7087A7",
    padding: "0 10px",
    height: "48px",
    borderRadius: "10px",
    textTransform: "capitalize",
    color: "#fff",
    "&:hover": {
      background: "#000",
    },
  },
  BtnCol: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  // *** add css ***//
  TopFiletr: {
    display: "flex",
    marginBottom: 30,
    flexWrap: "wrap",
    "& .dPOxbO": {
      borderRadius: 10,
      background: "#F1F1F1",
      fontSize: 14,
      color: "#8b8686",
    },
    "& .iuxhhH": {
      height: 35,
      padding: "6px 17px",
    },
    "& .cCMzRR": {
      display: "none",
    },
    "& .eeOlSf": {
      top: 50,
    },
    "& .clLvve.selected": {
      background: "#0A70E8",
    },
    "& .react-dropdown-select-no-data": {
      fontSize: 14,
    },
  },
  
  EditIcon:{
    minWidth:'initial'
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
    marginBottom: "30px",
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      background: "#F1F1F1",
      padding: "0 15px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiTabs-flexContainer": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiTabScrollButton-root:last-child": {
      position: "absolute",
      right: "-15px",
      marginTop: "11px",
    },
    "& .MuiTabScrollButton-root:first-child": {
      position: "absolute",
      left: "-15px",
      zIndex: "99",
      marginTop: "12px",
    },
    "& .MuiTabs-indicator": {
      display: "none !important",
    },
    "& .MuiTabScrollButton-root": {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      background: "#0A70E8",
      color: "#fff",
    },
    "& .MuiTab-root": {
      textTransform: "capitalize",
      fontFamily: "Poppins",
    },
    "& .MuiTabs-flexContainer": {
      borderRadius: "10px",
      background: "#F3F3F3",
      textTransform: "capitalize",
      justifyContent: "space-between",
      "& .MuiTab-textColorPrimary.Mui-selected": {
        background: "#D9E3F0",
        color: "#000",
        borderRadius: "10px",
      },
      "& .MuiTab-textColorInherit": {
        textTransform: "capitalize",
        fontSize: "16px",
        padding: "0 22px",
      },
    },
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    background: "#F1F1F1",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },

  modalNoBtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
  loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
}));
