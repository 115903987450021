import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from "react-router-dom"
import { Pagination } from '@material-ui/lab';
import Header from '../components/Header'
import { Box,Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiChevronUp, BiChevronDown,BiArrowBack, BiPlusCircle, BiXCircle} from "react-icons/bi"
import ManageUser from '../components/AdminDashboard'
import profile from '../images/doctor2.jpg'
import profile2 from '../images/doctor1.jpg'
import TopHeading from '../components/TopHeading'
import LeftNav from '../components/LeftNav'
import { Link } from 'react-router-dom'
import { apiAxios } from "../Util/ApiAxios";
import DatePicker from "react-date-picker";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormGroup from "@material-ui/core/FormGroup";
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
    checkName,
    checkEmail,
    checkNumbers,
    checkPhone,
    checkPhoneNumbers,
    checkNumbersOnly,
    checkDob,
    checkOnlyZero,
    checkPassword,
    checkUserName,
  } from "../Util/validations";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

function ManageProgram(props) {
    const classes = useStyles();

    const [tablemessage, setTableMessage] = useState("");
    const [programList, setProgramList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);

    const location = useLocation();
    const client_id = location.state?.client_id;
    const client_name = location.state?.client_name;

    console.log(client_name)

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [value2, onChange2] = useState(new Date());
    const[clientUserTypeID, setClientUserTypeID] = useState("");
    const[userMasterID, setUserMasterID] = useState("")
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [clientID, setClientID] = useState("");
    const [clientName, setClientName] = useState("");
    // error validation
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [dobError, setDobError] = useState(false);
   

  const getProgramList = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let data = {
        client_id: client_id? client_id : "" ,
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
        program_id: "",
        provider_id: "",
        search_value: "",
        status: "1"



      };

      setTableMessage("Loading...");

      apiAxios
      .post("/client/client-provider-program-list", data, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("admin_Token")
        },
      })
      .then((response) => {
        console.log("response --> ",response);
        if(response.data.statusCode == 200) {
            setProgramList(response.data.data);
            setTotalPages(response.data.total_pages);
            if(response.data.data.length > 0 ){}
            else{
                setTableMessage("No Data Found");
            }
        }
        else if(response.data.statusCode == 502){
            setTableMessage("No Data Found");
        }
        else {
            // toast.error(response.data.message, {
            //     position: "bottom-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
        }
      })
      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //     position: "bottom-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     });

            localStorage.removeItem("admin_Refresh_Token");
                localStorage.removeItem("admin_Token");
                window.location.href = "/"; 

      });

    },
  );
  

  useEffect(() => {
    getProgramList();

    console.log(props.history.location.state)
    if(props.history.location.state) {
      setClientID(props.history.location.state.client_id)
      setClientName(props.history.location.state.client_name)
    }

  }, [props.history.location.state]);


   //  modal  //
//    const [open, setOpen] = React.useState(false);

//    const handleClickOpen = (data) => {
//      setOpen(true);
    
//      setFirstName(data.first_name)
//      setLastName(data.last_name)
//      setEmail(data.email)
//      setPhone(data.mobile)
//      onChange2(data.dob)
//      setClientUserTypeID(data.client_user_type_id)
//      setUserMasterID(data.user_master_id)
//      setUserName(data.username);
//      setPassword(data.password);
 
//    };
 
//    const handleClose = () => {
//      setOpen(false);
//    };
 
//    function submitForm() {
//     let isValid = true;

//     console.log("submit form");

//     if (!checkName(firstname.trim())) {
//       setFirstNameError(true);
//       isValid = false;
//       console.log("First Error if= ", firstnameError);
//     } else {
//       setFirstNameError(false);
//     }
//     console.log("First Error = ", firstnameError);
   
//     if (!checkName(lastname.trim())) {
//       setLastNameError(true);
//       isValid = false;
//     } else {
//       setLastNameError(false);
//     }
//     if (!checkUserName(username.trim())) {
//       setUserNameError(true);
//       isValid = false;
//     } else {
//       setUserNameError(false);
//     }
//     if (!checkPhone(phone)) {
//       setPhoneError(true);
//       isValid = false;
//     } else {
//       if (checkOnlyZero(phone)) {
//         setPhoneError(true);
//         isValid = false;
//       } else {
//         setPhoneError(false);
//       }
//     }
//     if (!checkEmail(email)) {
//       setEmailError(true);
//       isValid = false;
//     } else {
//       setEmailError(false);
//     }
    
//     // if (parseInt(checkDob(value2).substring(0, 2)) < 0) {
//     //   setDobError(true);
//     //   isValid = false;
//     // } else {
//     //   setDobError(false);
//     // }
    
//     if (value2){
//       setDobError(false);
      
//     } else {
//       setDobError(true);
//       isValid = false;
//     }

//     if (isValid) {
//       let data = {
//         additional_info_view_table: "",
//         cutid: clientUserTypeID,
//         permission: "",
//         email: email,
//         first_name: firstname,
//         last_name: lastname,
//         dob: moment(value2).format("YYYY-MM-DD"),
//         user_master_id: userMasterID,
//         user_login_panel_type: "1",
//         mobile: phone,
//         username: username,
//         password: password? password : "",
//       };

//       console.log("Final Data = ", data);

//       apiAxios
//         .post("/provider/update-dhct-provider", data, {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: localStorage.getItem("Token"),
//           },
//         })
//         .then((response) => {
//           console.log("response --> ", response);

//           if (response.data.statusCode == 200) {
//             // setClientUserTypes(response.data.data);

//             //console.log("user types = ", userTypes);
//             toast.success(response.data.message, {
//               position: "bottom-center",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//             });

//             window.location.href = "/manage-user";
//           } else {
//             toast.error(response.data.message, {
//               position: "bottom-center",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//             });
//           }
//         })
//         .catch((error) => {
//           console.log(error);

//           toast.error("Something Went Wrong!", {
//             position: "bottom-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//         });
//     }
//   }

// delete

const handleDelete = (id) =>{

  let data ={
    
  
    provider_program_id: id,
    client_id: client_id,
  }

  apiAxios
      .post("/provider/delete_client_provider_programs", data, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("admin_Token")
        },
      })
      .then((response) => {
        console.log("delete response --> ",response);
        if(response.data.status == 200) {
           toast.success(response.data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          const timer = setTimeout(()=>{
            window.location.href=("/manage-program")
          },2000)
         
        }
        else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

             


      });
    


}

const deleteModal = (id) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h1>Are you sure?</h1>
          <p>You want to delete this record?</p>
          <button className={classes.modalNoBtn} style={{border: '2px solid #0f3d6e',  cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
          <button className={classes.modalYesBtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
            onClick={() => {
              
              handleDelete(id);
              onClose();
            }}
          >
            Yes, Delete
          </button>
        </div>
      );
    }
  });
};


    return (
        <div>
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>  
               <Box className={classes.pageTop} style={{marginBottom:'40px'}}>
                     
                 </Box>
                 <Box className={classes.leftHeading}>
                 <TopHeading />
                 </Box>
                 {/* left Accordion*/}
                <LeftNav />
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
                   <Link to="/clients">
                        <Button className={classes.backBtn}><BiArrowBack className={classes.backarrow} /></Button>
                    </Link>
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <Box className={classes.providerlist}>
                      <Box className={classes.Topcol}>
                    <h3 className={classes.topheading}>Provider Program List</h3>
                    <Link to={{pathname: "/add-program", state: { client_id: client_id? client_id : clientID , client_name : client_name? client_name : clientName}, }}><Button className={classes.addprovider}><BiPlusCircle className={classes.icon} /> Add Provider Program</Button></Link>
                    </Box>
                    <Typography style={{marginBottom:40,fontFamily:'Poppins',fontSize:18,color:'#696969'}}>Client: <b>{client_name ? client_name : clientName}</b></Typography>
                    <Box className={classes.ProgramTable}>
                    <Box className={classes.Throw}>
                        <Box className={classes.Thcol}>Program Name</Box>
                        <Box className={classes.Thcol1}>Progrm Abbreviation</Box>
                        <Box className={classes.Thcol2}>Provider Name</Box>
                        {/* <Box className={classes.Thcol3}>Provider Last Name</Box> */}
                        <Box className={classes.Thcol4}>Start Date</Box>
                        <Box className={classes.Thcol5}>Action</Box>
                    </Box>
                    
                    { (programList.length > 0) ?
                    <>
                    {programList.map((each) => (
                    <Box className={classes.Tdrow}>
                        <Box className={classes.Tdcol}>{each.program_name}</Box>
                        <Box className={classes.Tdcol1}>{each.program_abbrev}</Box>
                        <Box className={classes.Tdcol2}>{each.first_name + " " + each.last_name}</Box>
                        {/* <Box className={classes.Tdcol3}>{each.last_name}</Box> */}
                        <Box className={classes.Tdcol4}>{each.provider_program_start_date}</Box>
                        <Box className={classes.Tdcol5}><Button className={classes.ActionBtn} onClick={()=>{
                         
                          deleteModal(each.id)
                        }
                        }>Delete</Button></Box>
                    </Box>
                    ))}
                    </>
                    :
                    <Box className={classes.providerrow}>
                        <br />
                        <Box className={classes.tdcol12} style={{textAlign:'center',width:'100%',color:'#dfdfdf'}}>
                            {tablemessage}
                        </Box>
                    </Box>
                    }
                    </Box>
                </Box>

                  </Grid>

                  <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getProgramList({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div>  

                        {/* Update user modal
        <Dialog
          className={classes.modal}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
              <Box className={classes.loginform}>
                <h3 className={classes.Toptext}>Update User</h3>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                     

                      <Box className={classes.Formcol}>
                        <label>
                          First Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter First Name"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {firstnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          First name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Last Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Last Name"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {lastnameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Last name is required.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Date of Birth
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                          onChange={onChange2}
                          
                          value={value2? new Date(value2):  null}
                          className={classes.input}
                          clearIcon={null}
                          maxDate={moment().toDate()}
                        />
                      </Box>
                      {dobError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please provide valid age
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Email
                        <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Phone Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Provide a valid phone number.
                        </p>
                      ) : (
                        <></>
                      )}
                    
                      
                    <Box className={classes.Formcol}>
                        <label>
                          User Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="User Name"
                          value={username}
                          onChange={(e) => setUserName(e.target.value)}
                          type="text"
                        />
                      </Box>
                      {userNameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          User name required.
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>
                          Password
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Box>
                      {passwordError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Please enter valid password. (Password should have: 8
                          characters and minimum 1 upper character, 1 lower
                          character, 1 number and 1 special character)
                        </p>
                      ) : (
                        <></>
                      )}


                    </Grid>
                  </Grid>
                </form>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            <Button
              size="large"
              className={classes.loginbtn}
                onClick={submitForm}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
                 */}

                  <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                  
               </Grid>
               
               </Box>
            </Box>

                    <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />

        </div>
    )
}

export default ManageProgram;
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    backBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        marginTop:20,
        marginBottom:15,
        width:30,
        height:20,
        '&:hover':{
            background:'none'
        }
    },
    ActionBtn:{
        borderRadius:10,
        textTransform:'capitalize',
        background:'#0A70E8',
        color:'#fff',
        '&:hover':{
            background:'rgba(0,0,0,0.8)'
        }
    },
    Topcol:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:'0',
        '& a':{
            textDecoration:'none'
        }
    },
    Tdcol:{
        width:'34%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol1:{
      width:'21%',
      wordWrap: "break-word",
      overflow: "auto",
  },
    Tdcol2:{
        width:'25%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol3:{
        width:'21%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol4:{
        width:'12%',
        wordWrap: "break-word",
        overflow: "auto",
    },
    Tdcol5:{
        width:'10%',
        textAlign:'center'
    },
    Thcol:{
        width:'34%'
    },
    Thcol1:{
      width:'21%'
  },
    Thcol2:{
        width:'25%'
    },
    Thcol3:{
        width:'21%'
    },
    Thcol4:{
        width:'12%'
    },
    Thcol5:{
        width:'10%',
        textAlign:'center'
    },
    addprovider:{
        fontSize:'16px',
        color:'#7087A7',
        textTransform:'capitalize'
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end'
    },
    topheading:{
        fontWeight:'600',
        color:'#141621'
    },
    Throw:{
        width:'100%',
        borderBottom:'2px #E3E5E5 solid',
        display:'flex',
        color:'#919699',
        paddingBottom:'10px',
        alignItems:'center',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1000,
                 },
    },
    pageTop:{
        textAlign:'left',
        marginBottom:'40px',
        display:'flex',
        '& button':{
            padding:'0px',
            background:'none',
            color:'#919699',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500'
        }
    },
    profile:{
        width:'80px',
        height:'80px',
        borderRadius:'50%',
        overflow:'hidden',
        '& img':{
            width:'100%'
        }
    },
    backarrow:{
        color:'#7087A7',
        fontSize:'20px',
        marginRight:'8px'
    },
    Leftcol:{
        width:'15%',
        padding:'20px 3%',
        position:'relative',
        minHeight:'1050px',
        '@media only screen and (max-width: 820px)':{
          position:'absolute'
                  },
        '@media only screen and (max-width: 768px)':{
          position:'absolute'
                  },
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px',
        '@media only screen and (max-width: 820px)':{
          display:'none'
        },
        '@media only screen and (max-width: 768px)':{
          display:'none'
        }
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      left:'15px',
    right:'15px',
            }
    },
    Rightcol:{
        width:'75%',
        padding:'20px 2%',
        borderLeft:'1px #ededed solid',
        '@media only screen and (max-width: 820px)':{
          width:'94%',
          padding:'20px 3%',
                },
                '@media only screen and (max-width: 768px)':{
                  width:'94%',
                  padding:'20px 3%',
                        },
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #E3E5E5 solid',
            height:'40px',
            color:'#919699',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        }
    },
    
Downarrow:{
    fontSize:'20px',
    color:'#7087A7',
    marginLeft:'5px'
},
leftHeading:{
  '@media only screen and (max-width: 820px)':{
    display:'none'
   },
  '@media only screen and (max-width: 768px)':{
    display:'none'
   },
},
Editbtn:{
  background:'#fff',
  border:'1px #AEAEAE solid',
  width:'60px',
  height:'30px',
  color:'#7087A7',
  textTransform:'capitalize',
  borderRadius:'10px',
  fontWeight:'600',
  '&:hover':{
    background:'#7087A7',
    color:'#fff',
  }
},

icon:{
  color:'#7087A7',
  fontSize:'20px',
  marginRight:'10px'
},
Tdrow:{
    width:'100%',
    borderBottom:'1px #E3E5E5 solid',
    padding:'15px 0',
    alignItems:'center',
    display:'flex',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
             },
    '& p':{
        textAlign:'left'
    }
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},
ProgramTable:{
  width:'100%',
  '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
   overflowX:'auto'
          }
},
pageTop:{
    textAlign:'left',
    '& button':{
        padding:'0px',
        background:'none',
        color:'#919699',
        fontSize:'15px',
        textTransform:'capitalize',
        fontWeight:'500'
    }
},
modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "550px",
      maxWidth: "550px",
    },
  },
  ccmmodal: {
    borderRadius: "10px",
    "& label": {
      color: "#666",
      marginBottom: 5,
      display: "flex",
      flex: "0 0 150px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0074D9 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },

  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  modalNoBtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
  modalYesBtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },

   }));