import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Setting from "../components/Setting";
import { BiArrowBack } from "react-icons/bi";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";
import Slide from "@material-ui/core/Slide";
import ManageAdminUser from "../components/ManageAdminUser";
import AdminDashboard from "../components/AdminDashboard";
import DatePicker from "react-date-picker";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TopHeading from "../components/TopHeading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftNav from "../components/LeftNav";
import { tieredPriceList } from "../Util/DropDownData";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import {
  checkName,
  checkEmail,
  checkNumbers,
  checkPhone,
  checkPhoneNumbers,
  checkNumbersOnly,
  checkPassword,
  checkAlphaNumeric,
  checkCost,
  checkUSPhone
  
} from "../Util/validations";
import { apiAxios } from "../Util/ApiAxios";
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

//import Loader from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Input = styled("input")({
  display: "none",
});

function AddNewClientStep3(props, { options }) {
  const classes = useStyles();
  const [form1data, setForm1data] = useState({});
  const [form2data, setForm2data] = useState({});
  const [logo, setLogo] = useState("");

  const [accMgr, setaccMgr] = useState("");
  const [effectiveDate, seteffectiveDate] = useState(new Date());
  const [expireDate, setexpireDate] = useState("");
  const [billingContact, setbillingContact] = useState("");
  // const [tieredPrice, settieredPrice] = useState("yes");
  const [tieredPrice, settieredPrice] = useState("");
  const [billingRate, setbillingRate] = useState([{}]);
  const [flatRate, setflatRate] = useState("");
  const [addCost1, setaddCost1] = useState("");
  const [addCost2, setaddCost2] = useState("");
  const [contactPhone, setcontactPhone] = useState("");
  const [contactEmail, setcontactEmail] = useState("");
  const [billingOptions, setBillingOptions] = useState([]);
  const [selectedBiling, setSelectedBiling] = useState([]);
  const [ifLoading, setIfLoading] = useState(false);
  const [tieredToggler, setTieredToggler] = useState("no");
  const [billingList, setBillingList] = useState([]);
  //Error States
  const [accMgrError, setaccMgrError] = useState(false);
  const [flatRateError, setFlatRateError] = useState(false);
  const [selectedBillingError, setSelectedBilingError] = useState(false);
  const [effectiveDateError, seteffectiveDateError] = useState(false);
  const [billingContactError, setbillingContactError] = useState(false);
  const [tieredPriceError, settieredPriceError] = useState(false);
  const [contactPhoneError, setContactPhoneError] = useState(false);
  function onBackClick() {
    props.history.push({
      pathname: "/new-clients",
      state: {
        logo : logo,
        form1data: form1data,
        // form2data: form2data,
        form3data: {
          contract_start: effectiveDate,
          contract_end: expireDate ? expireDate : "",
          tiered_pricing: tieredPrice,
          price_change_date: "",
          dhct_account_mgr: accMgr,
          billing_contact: billingContact.replace(/[^0-9]/g,""),
          contact_phone: contactPhone ? contactPhone.replace(/[^0-9]/g,'') : "",
          contact_email: contactEmail,
          additional_billing_fee1: addCost1,
          additional_billing_fee2: addCost2,
          additional_billing_fee3: "",
          location: "mumbai",
          user_login_panel_type: "1",
          billing: selectedBiling,
        
        },
      },
    });
  }

  useEffect(() => {
    console.log("date of birth")
    console.log(props.history.location.state);
    if (props.history.location.state) {
      if (
        props.history.location.state.form3data &&
        Object.keys(props.history.location.state.form3data).length != 0
      ) {
        setaccMgr(props.history.location.state.form3data.dhct_account_mgr);
        seteffectiveDate(props.history.location.state.form3data.contract_start);
        setexpireDate(props.history.location.state.form3data.contract_end);
        setbillingContact(
          formatPhoneNumber(props.history.location.state.form3data.billing_contact)
        );
        // settieredPrice(props.history.location.state.form3data.tiered_pricing);
          console.log(props.history.location.state.form3data.tiered_pricing)
          let tieredList = tieredPriceList()
          console.log(tieredList)
       
          let tieredVal = props.history.location.state.form3data.tiered_pricing
          for(var i=0; i< tieredList.length; i++){
            if(tieredList[i].value === tieredVal || tieredList[i].value === tieredVal.value){

              console.log(tieredList[i])
              settieredPrice(tieredList[i]);
            }
          }

          
        

        setbillingRate(props.history.location.state.form3data.billing);

        // console.log(props.history.location.state.form3data.billing)
        //  console.log(props.history.location.state.form3data.billing)
      
        setSelectedBiling(props.history.location.state.form3data.billing);

        // let billingData = props.history.location.state.form3data.billing

        // let billingRes =  billingOptions

        // console.log(billingRes)
        // console.log(billingData)

        // for(var i=0; i<billingRes.length; i++){
        //   console.log("i")
        //   for (var j=0; j<billingData.length; j++){
        //     if(billingRes[i].id === billingData[j]){
        //         console.log(billingRes[i])
        //         console.log("j")
        //     }
        //   }
        // }
        

        // console.log(props.history.location.state.form3data.billing);
        // let billingData = props.history.location.state.form3data.billing;
        //   let billingArr = billingOptions
     
        // console.log(billingData)
        // console.log(billingArr)
       
        // for(var i=0;i<billingArr.length; i++){
       
        //   for(var j=0;j<billingData.length;j++){
        //     if(billingArr[i].id === billingData[j]){
        //         setSelectedBiling(billingArr[i])
        //         console.log("billing new array")
        //         console.log(billingArr[i])
        //     }
        //   }
          
        // }

        

        setflatRate(
          props.history.location.state.form3data.additional_billing_fee3
        );
        setaddCost1(
          props.history.location.state.form3data.additional_billing_fee1
        );
        setaddCost2(
          props.history.location.state.form3data.additional_billing_fee2
        );
        setcontactPhone(formatPhoneNumber(props.history.location.state.form3data.contact_phone));
        setcontactEmail(props.history.location.state.form3data.contact_email);
      }
      if (
        props.history.location.state.form1data &&
        Object.keys(props.history.location.state.form1data).length != 0
      ) {
        setForm1data(props.history.location.state.form1data);
      }
      if (
        props.history.location.state.form2data &&
        Object.keys(props.history.location.state.form2data).length != 0
      ) {
        setForm2data(props.history.location.state.form2data);
      }
    }

    if(props.history.location.state.logo != ""){
      setLogo(props.history.location.state.logo)
    }


  }, [props.history.location.state]);

  function onCreateClient() {
    let isvalid = true;

    

   console.log("selected")
   console.log(selectedBiling)

   console.log(tieredPrice)

  

    if (!checkName(accMgr.trim())) {
      setaccMgrError(true);
      isvalid = false;
    } else {
      setaccMgrError(false);
    }
    if (!checkUSPhone(billingContact.trim())) {
      setbillingContactError(true);
      isvalid = false;
    } else {
      setbillingContactError(false);
    }

    if(contactPhone){
      if(!checkUSPhone(contactPhone)){
        setContactPhoneError(true)
        isvalid = false
      }else {
        setContactPhoneError(false)
        
      }
    }else {
      setContactPhoneError(false)
    }


    if (effectiveDate) {
      seteffectiveDateError(false)
     
    } else {
      isvalid = false;
      seteffectiveDateError(true)
    }
    if (tieredPrice) {
      settieredPriceError(false)
     
    } else {
      isvalid = false;
      settieredPriceError(true)
    }

    if(tieredPrice == "no" || tieredPrice.value == "no"){

    if (flatRate) {
      setFlatRateError(false)
     
    } else {
      isvalid = false;
      setFlatRateError(true)
    }
  }else {
    setFlatRateError(false)
  }

  
    if(tieredPrice == "yes" || tieredPrice.value == "yes"){
        if (selectedBiling.length == 0){
          setSelectedBilingError(true)
          isvalid = false
        }else {
          setSelectedBilingError(false)
        
        }
    }else {
      setSelectedBilingError(false)
    }

    console.log(selectedBiling);

    if (isvalid) {
      setIfLoading(true);
      let data = {
        ...form1data,
        // ...form2data,
        // dob: moment(props.history.location.state.form2data.dob).format(
        //   "YYYY-MM-DD"
        // ),
        contract_start: moment(effectiveDate).format("YYYY-MM-DD"),
        contract_end: expireDate ? moment(expireDate).format("YYYY-MM-DD") : "",
        tiered_pricing: tieredPrice.value ? tieredPrice.value : tieredPrice,
        price_change_date: "",
        dhct_account_mgr: accMgr,
        billing_contact: billingContact.replace(/[^0-9]/g,""),
        contact_phone: contactPhone? contactPhone.replace(/[^0-9]/g,'') : "",
        contact_email: contactEmail,
        additional_billing_fee1: addCost1,
        additional_billing_fee2: addCost2,
        additional_billing_fee3: "",
        location: "mumbai",
        user_login_panel_type: "1",
        flat_rate: flatRate,
        billing_id: selectedBiling,
        action : "create_client"
      };
      console.log(data);
      apiAxios
        .post("/client/create-client", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.statusCode == 200) {
            console.log(response.data.message)
            console.log(response.data)
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setIfLoading(false);


            const timer = setTimeout(()=>{

              props.history.push({
                pathname: "/clients",
              });

            },3000)

           


           
            
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });
    }
  }

  useEffect(() => {
    apiAxios
      .post(
        "/client/billing-rates",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setBillingList(response.data)
        setbillingRate(response.data);
        // response.data.map((e) => {
        //   billingOptions.push({
        //     name: e.billing_code,
        //     id: e.id,
        //   });
        // });
    
          
       
        console.log(billingOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [billingRate != []]);


  useEffect(() => {
    if(billingList){ 
      var ob_arr =[];
    
      for(var i=0;i<billingList.length;i++){
      let name = billingList[i].billing_code;
      let id = billingList[i].id;
     
      let ob = {
          "name":name, "id": id, 
      }
   
      ob_arr.push(ob);
      }

      console.log(ob_arr)
      setBillingOptions(ob_arr);
     
   }
   
 }, [billingList]);  




 const handleInput = (e) => {
   
  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
  
  setcontactPhone(formattedPhoneNumber);
};

const handleContactInput = (e) => {
   
  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
  
  setbillingContact(formattedPhoneNumber);
};

function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;


}

 

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Header />
      <Box className={classes.Pagecontent}>
        <Box className={classes.Leftcol}>
          <Box className={classes.leftnav}>
            <Box className={classes.leftHeading}>
            <TopHeading />
            </Box>
            <LeftNav />
            <Box className={classes.bottomnav}>
              <Setting />
            </Box>
          </Box>
        </Box>
        {/* right col */}
        <Box className={classes.Rightcol}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Button className={classes.TopbackBtn} onClick={onBackClick}>
                <BiArrowBack className={classes.backarrow} />
              </Button>
              <Box className={classes.providerlist}>
                <Typography variant="h3" className={classes.topheading}>
                  Add New Client
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    color: "#696969",
                    fontStyle: "italic",
                  }}
                >
                  / Step 2
                </Typography>
                {/* <Typography variant="p" className={classes.Righttext}>General Informations</Typography> */}
              </Box>
              <Box className={classes.loginform}>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5" className={classes.FormHeading}>
                        Contract Information
                      </Typography>

                      <Box className={classes.Formcol}>
                        <label>
                          Account Manager
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          // value="DHCT Owner"
                          placeholder="Name of Client"
                          type="text"
                          value={accMgr}
                          onChange={(e) => setaccMgr(e.target.value)}
                        />
                      </Box>
                      {accMgrError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid account manager.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Effective Date
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                          className={classes.input}
                          value={effectiveDate}
                          onChange={(e) => seteffectiveDate(e)}
                          maxDate={expireDate}
                        />
                      </Box>
                      {effectiveDateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid date.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Expiration Date
                          {/* <span style={{ color: "#ff0000" }}>*</span> */}
                        </label>
                        <DatePicker
                          value={expireDate}
                          onChange={(e) => setexpireDate(e)}
                          className={classes.input}
                          minDate={effectiveDate}
                        />
                      </Box>
                      {/* {expireDateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid date.
                        </p>
                      ) : (
                        <></>
                      )} */}
                      <Box className={classes.Formcol}>
                        <label>
                          Billing Contact
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          // value="Billing Contact"
                          placeholder="Please enter a contact number"
                          type="text"
                          value={billingContact}
                          onChange={(e) =>{
                            // if(checkPhoneNumbers(e.target.value)){
                            //   setbillingContact(e.target.value)
                            // }
                            handleContactInput(e)
                          }
                            }
                        />
                      </Box>
                      {billingContactError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid contact number
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Tiered Pricing
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={tieredPriceList()}
                          
                          onChange={(values)=>{
                            settieredPrice(values[0].value)
                            
                            
                          }}
                          className={classes.select}
                          style={{ width: "100%" }}
                          // values={tieredPriceList().filter(
                          //   (option) => option.value === tieredPrice
                          // )}
                          value={tieredPrice}
                          placeholder={tieredPrice? tieredPrice.label : "Please Select"}
                       
                        />
                      </Box>

                      {tieredPriceError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please select Tiered Pricing
                        </p>
                      ) : (
                        <></>
                      )}



                      {(tieredPrice === "yes" || tieredPrice.value === "yes")? (
                        <Box className={classes.Formcol}>
                          <label>Billing Rate
                          <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          {/* <Select
                            options={billingOptions}
                            onChange={(values) =>
                              setSelectedBiling(values[0].value)
                            }
                            className={classes.select}
                            style={{ width: "100%" }}
                          /> */}
                          <Multiselect
                         
                            className={classes.select}
                            style={{ width: "100%" }}
                            options={billingOptions}
                            displayValue="name"
                            selectedValues={billingOptions.filter((e) =>
                              selectedBiling.some((v) => v == e.id)
                            )}

                            onSelect={(e, i) =>
                              setSelectedBiling((oldArray) => [
                                ...oldArray,
                                i.id,
                              ])
                            }
                            onRemove={(e, i) =>
                              setSelectedBiling(
                                selectedBiling?.filter(function (f) {
                                  return f !== i.id;
                                })
                              )
                            }
                            // placeholder= {selectedBiling ? "" : "Select Billing"}
                            // isDisabled="false"
                           
                            hidePlaceholder={true}
                          />
                        </Box>
                        
                        
                      ) : (
                        <Box className={classes.Formcol}>
                          <label>Flat Rate
                          <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <TextField
                            className={classes.input}
                            placeholder="Flat Rate"
                            type="tel"
                            value={flatRate}
                            onChange={(e) => setflatRate(e.target.value)}
                          />

                          
                        </Box>

                      
                       
                      )}

                       {flatRateError ? (
                          <p
                            style={{
                              color: "#dc3545",
                              fontSize: ".875em",
                              marginTop: "0.25rem",
                            }}
                          >
                           Flat rate is required
                          </p>
                        ) : (
                          <></>
                        )}

                       {selectedBillingError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please select billing
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Additional Cost 1</label>
                        <TextField
                          className={classes.input}
                          placeholder="Additional Cost 1"
                          type="tel"
                          value={addCost1}
                          onChange={(e) => {
                            
                            setaddCost1(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Additional Cost 2</label>
                        <TextField
                          className={classes.input}
                          placeholder="Additional Cost 2"
                          type="tel"
                          value={addCost2}
                          onChange={(e) => {
                           
                            setaddCost2(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Contact (Phone)</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={contactPhone}
                          onChange={(e) => {
                            // if (checkPhoneNumbers(e.target.value)) {
                            //   setcontactPhone(e.target.value);
                            // }
                            handleInput(e)
                          }}
                        />
                        
                     
                      </Box>
                      {contactPhoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please enter a valid contact number
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>Contact (Email)</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email Address"
                          type="text"
                          value={contactEmail}
                          onChange={(e) => setcontactEmail(e.target.value)}
                        />
                      </Box>
                      {/* <Box className={classes.Formcol}>
                      <label>Price Change Date</label>
                      <TextField className={classes.input} placeholder="Price Change Date" type="text" />
                      </Box> */}
                      {(tieredPrice === "yes" || tieredPrice.value === "yes") ? (
                        <>
                          <Box className={classes.Thcol}>
                            <Box className={classes.col1}>Billing Code</Box>
                            <Box className={classes.col2}>Description</Box>
                            <Box className={classes.col3}>Volume Tier</Box>
                            <Box className={classes.col4}>Unit Price</Box>
                            <Box className={classes.col5}></Box>
                          </Box>
                          {billingRate
                            .filter((e) =>
                              selectedBiling.some((v) => v == e.id)
                            )
                            .map((e) => {
                              return (
                                <Box className={classes.Tdcol}>
                                  <Box className={classes.Tdcol1}>
                                    {e.billing_code}
                                  </Box>
                                  <Box className={classes.Tdcol2}>
                                    {e.billing_description}
                                  </Box>
                                  <Box className={classes.Tdcol3}>
                                    {e.tier_range_start +
                                      "-" +
                                      e.tier_range_end}
                                  </Box>
                                  <Box className={classes.Tdcol4}>
                                    {e.tier_range_price}
                                  </Box>
                                  <Box className={classes.Tdcol5}>
                                    <Button
                                      className={classes.deletebtn}
                                      onClick={(f) =>
                                        setSelectedBiling(
                                          selectedBiling?.filter(function (f) {
                                            return f !== e.id;
                                          })
                                        )
                                      }
                                    >
                                      -
                                    </Button>
                                  </Box>
                                </Box>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}

                      
                      <Box className={classes.Btncol}>
                       
                        {/* <Button
                          size="large"
                          className={classes.Backbtn}
                          onClick={(e) => onBackClick()}
                         
                        >
                          {" "}
                          Back{" "}
                        </Button> */}
                       
                        { !ifLoading?
                        <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={(e) => onCreateClient()}
                        >
                          {" "}
                          Create Client{" "}
                        </Button>

                        :
                        <ThreeDots
                   
                      color="#000000"
                       height={50}
                      width={50}
                       timeout={0} //30 secs
                   
                   />
                   }
                        {/* <Button size="large" className={classes.Previewbtn}>
                          {" "}
                          Preview{" "}
                        </Button> */}
                      </Box>

                      
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}

export default AddNewClientStep3;
const useStyles = makeStyles(() => ({
  Pagecontent: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  FormHeading: {
    fontFamily: "Poppins",
    fontSize: 17,
    color: "rgba(0,0,0,0.5)",
    marginBottom: 20,
    fontWeight: "600",
    borderBottom: "1px rgba(0,0,0,0.1) solid",
    paddingBottom: 10,
  },
  deletebtn: {
    width: 20,
    height: 20,
    background: "#E81010",
    borderRadius: 40,
    color: "#fff",
    minWidth: 20,
    margin: "0 0 0 60px",
    padding: 0,
    fontSize: 20,
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#333",
    },
  },
  Thcol: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px #E3E5E6 solid",
    padding: "5px 0",
  },
  Tdcol: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px #E3E5E6 solid",
    padding: "10px 0",
  },
  col1: {
    width: "22%",
    fontSize: 14,
    color: "rgba(143,150,153,0.6)",
  },
  col2: {
    width: "36%",
    fontSize: 14,
    color: "rgba(143,150,153,0.6)",
  },
  col3: {
    width: "15%",
    fontSize: 14,
    color: "rgba(143,150,153,0.6)",
  },
  col4: {
    width: "15%",
    fontSize: 14,
    color: "rgba(143,150,153,0.6)",
    textAlign: "right",
  },
  col5: {
    width: "12%",
  },
  Tdcol1: {
    width: "22%",
  },
  Tdcol2: {
    width: "36%",
  },
  Tdcol3: {
    width: "15%",
  },
  Tdcol4: {
    width: "15%",
    textAlign: "right",
  },
  Tdcol5: {
    width: "12%",
    textAlign: "right",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  Btncol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  providerlist: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 50,
    marginTop: 20,
  },
  Righttext: {
    fontSize: 16,
    color: "#696969",
  },

  topheading: {
    marginBottom: "0px",
    fontWeight: "600",
    color: "#141621",
    fontFamily: "Poppins",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 10,
  },
  toprow: {
    width: "100%",
    borderBottom: "2px #E3E5E5 solid",
    display: "flex",
    color: "#919699",
    paddingBottom: "10px",
  },
  pageTop: {
    textAlign: "left",
    marginBottom: "40px",
    display: "flex",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  profile: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  backarrow: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "8px",
  },
  Leftcol: {
    width: "15%",
    padding: "20px 3%",
    position: "relative",
    minHeight: "1050px",
    '@media only screen and (max-width: 820px)':{
      position:'absolute'
              },
    '@media only screen and (max-width: 768px)':{
      position:'absolute'
              },
  },
  bottomnav: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    '@media only screen and (max-width: 768px)':{
      display:'none'
    }
  },
  leftnav: {
    position: "absolute",
    top: "15px",
    bottom: "15px",
    left: "40px",
    right: "40px",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      left:'15px',
    right:'15px',
            }
  },
  Rightcol: {
    width: "75%",
    padding: "20px 2%",
    borderLeft: "1px #ededed solid",
    '@media only screen and (max-width: 820px)':{
      width:'94%',
      padding:'20px 3%',
            },
    '@media only screen and (max-width: 768px)':{
      width:'94%',
      padding:'20px 3%',
            },
    "& .MuiAccordionSummary-root": {
      borderBottom: "2px #E3E5E5 solid",
      height: "40px",
      color: "#919699",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },
  leftHeading:{
    '@media only screen and (max-width: 820px)':{
      display:'none'
     },
    '@media only screen and (max-width: 768px)':{
      display:'none'
     },
  },
  Downarrow: {
    fontSize: "20px",
    color: "#7087A7",
    marginLeft: "5px",
  },

  Editbtn: {
    background: "#fff",
    border: "1px #AEAEAE solid",
    width: "60px",
    height: "30px",
    color: "#7087A7",
    textTransform: "capitalize",
    borderRadius: "10px",
    fontWeight: "600",
    "&:hover": {
      background: "#7087A7",
      color: "#fff",
    },
  },

  icon: {
    color: "#7087A7",
    fontSize: "20px",
    marginRight: "10px",
  },
  providerrow: {
    width: "100%",
    borderBottom: "1px #E3E5E5 solid",
    padding: "15px 0",
    display: "flex",
    "& p": {
      textAlign: "left",
    },
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
      },
    },
  },

  pageTop: {
    textAlign: "left",
    "& button": {
      padding: "0px",
      background: "none",
      color: "#919699",
      fontSize: "15px",
      textTransform: "capitalize",
      fontWeight: "500",
    },
  },
  checkicon: {
    fontSize: "25px",
    color: "#47C932",
  },
  inputfile: {
    display: "none",
  },
  select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    background: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px #D5D5D5 solid",
  },
  searchWrapper: {
    border: "none",
    padding: "10px",
    minHeight: "22px",
    position: "relative",
  },
  Toptext: {
    fontSize: "18px",
    color: "#141621",
    fontWeight: "600",
    marginTop: "-15px",
    marginBottom: "30px",
  },
  Textheading: {
    fontSize: "16px",
    marginTop: "0px",
    fontWeight: "500",
  },
  Addbtn: {
    width: "180px",
    height: "45px",
    background: "#E13F66",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 0px 12px 6px rgba(0, 0, 0, 0.18)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
    },
  },
  cancelbtn: {
    background: "#DADADA",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      background: "#000",
    },
  },
  nextbtn: {
    background: "#7087A7",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    marginLeft: "15px",
    "&:hover": {
      background: "#000",
    },
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  addprovider: {
    fontSize: "16px",
    color: "#7087A7",
    textTransform: "capitalize",
  },
  btncol: {
    display: "flex",
   
  
    marginTop: "100px",
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  loginform: {
    width: "100%",
    '& .searchWrapper':{
      border:'none',
      padding:0,
      marginTop:9,
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiAutocomplete-input": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 20px !important",
      height: 40,
    },
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      background: "#F9F9F9",
      padding: "0 15px",
      border: "1px #D5D5D5 solid",
    },
    "& .MuiInput-input:focus": {
      border: "1px #D5D5D5 solid",
      boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.1)",
    },
  },
  TopbackBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 30,
    height: 20,
    "&:hover": {
      background: "none",
    },
  },
  Backbtn: {
    background: "#8E8E8E",
    padding: "0 40px",
    width: "180px",
    marginRight: "20px",
    height: "45px",
    borderRadius: "10px",
    
    color: "#fff",
    marginTop: "20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "180px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#333",
    },
  },
  Previewbtn: {
    background: "none",
    fontSize: 16,
    color: "#1612C6",
    height: "45px",
    textTransform: "capitalize",
    padding: "0 40px",
    marginTop: "20px",
    borderRadius: "10px",
    marginLeft: 20,
  },
}));
