import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
  import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box,Grid,Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Setting from '../components/Setting'
import {BiChevronUp, BiChevronDown,BiArrowBack, BiPlusCircle,BiXCircle,BiCheckCircle} from "react-icons/bi"
import { FiChevronDown } from "react-icons/fi"
import client1 from '../images/client1.jpeg'
import client2 from '../images/client2.png'
import Slide from '@material-ui/core/Slide'
import ManageAdminUser from '../components/ManageAdminUser'
import AdminDashboard from '../components/AdminDashboard'
import TopHeading from '../components/TopHeading'
import LeftNav from '../components/LeftNav'
import { Link } from 'react-router-dom'
import { apiAxios, baseFrontendURl } from "../Util/ApiAxios"
import TextField from "@material-ui/core/TextField"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {stateList} from '../Util/DropDownData'
import {
    checkName,
    checkEmail,
    checkNumbers,
    checkPhone,
    checkPhoneNumbers,
    checkNumbersOnly,
    checkZip,
    checkClientZip,
    checkOnlyZero,
    checkAlphaNumeric,
    checkCost,
    checkUSPhone
  } from "../Util/validations";
  import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Select from "react-dropdown-select";
import { clientTypeList, passChangeFreqList } from "../Util/DropDownData";
import FormGroup from "@material-ui/core/FormGroup";
import { ThreeDots} from  'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DatePicker from "react-date-picker";
import { tieredPriceList } from "../Util/DropDownData";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core//Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import jwt_decode from "jwt-decode";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function ManageClients (props,{ options }) {
    const classes = useStyles();

    const [tablemessage, setTableMessage] = useState("");
    const [clientList, setClientList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const currentPageRef = useRef(1);
    const [flag, setFlag] = useState(false);

    // update client state

    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [clientType, setClientType] = useState("");
  // const [clientId, setClientId] = useState("");
  const [website, setWebsite] = useState("");
  const [npi, setNpi] = useState("");
  // const [passChangeFreq, setPassChangeFreq] = useState("90");

  const[billingList, setBillingList] = useState([]);

  const [passChangeFreq, setPassChangeFreq] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [billAddress, setBillAddress] = useState("");
  const [billState, setBillState] = useState("");
  const [billCity, setBillCity] = useState("");
  const [billZip, setBillZip] = useState("");
  const [checked, setChecked] = useState(false);
  const [imgData, setImgData] = useState("")
  const [ifLoading, setIfLoading] = useState(false);
  const [disableClient, setDisableClient] = useState("")
  const [search,setSearch] = useState("")
  //Error States
  const [nameError, setNameError] = useState(false);
  const [contactPhoneError, setContactPhoneError] = useState(false)
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  //const [clientTypeError, setClientTypeError] = useState(false);
  // const [clientIdError, setClientIdError] = useState(false);
  const [npiError, setNpiError] = useState(false);
  const [clientTypeError, setClientTypeError] = useState(false);
  const [clientID, setClientID] = useState("")
  const [selectedImg, setSelectedImg] = useState("")

//   client page 3 state 

const [accMgr, setaccMgr] = useState("");
const [effectiveDate, seteffectiveDate] = useState(new Date());
const [expireDate, setexpireDate] = useState("");
const [billingContact, setbillingContact] = useState("");
// const [tieredPrice, settieredPrice] = useState("yes");
const [tieredPrice, settieredPrice] = useState("");
const [billingRate, setbillingRate] = useState([{}]);
const [flatRate, setflatRate] = useState("");
const [addCost1, setaddCost1] = useState("");
const [addCost2, setaddCost2] = useState("");
const [contactPhone, setcontactPhone] = useState("");
const [contactEmail, setcontactEmail] = useState("");
const [billingOptions, setBillingOptions] = useState([]);
const [selectedBiling, setSelectedBiling] = useState([]);
const [imgSrc, setImgSrc] = useState("")
const [logoFlag, setLogoFlag] = useState("")
const [imgLogo,setImgLogo] = useState("")

const [tieredToggler, setTieredToggler] = useState("no");
//Error States
const [accMgrError, setaccMgrError] = useState(false);
const [flatRateError, setFlatRateError] = useState(false);
const [selectedBillingError, setSelectedBilingError] = useState(false);
const [effectiveDateError, seteffectiveDateError] = useState(false);
const [billingContactError, setbillingContactError] = useState(false);
const [tieredPriceError, settieredPriceError] = useState(false);



    

  const getClientList = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {
      setCurrentPage(parseInt(table_config_page_no));

      let data = {
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
        search_value: search? search: "",
       
      };

      setTableMessage("Loading...");

      apiAxios
      .post("/client/list", data, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("admin_Token")
        },
      })
      .then((response) => {
        console.log("response --> ",response);
        if(response.data.statusCode == 200) {
            setClientList(response.data.data);
            setTotalPages(response.data.total_pages);
            let res = response.data.data
            if(res.length > 0 ){
              
            }
            else{
                setTableMessage("No Data Found");
            }

            

        }else if(response.data.statusCode == 502){
          setTableMessage("No Data Found");
          setClientList([])
          setTotalPages(1)
        }

        else {
          setClientList([])
          setTotalPages(1)
            // toast.error(response.data.message, {
            //     position: "bottom-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     });
        }
      })

      
      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //     position: "bottom-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     });

        localStorage.removeItem("admin_Refresh_Token");
        localStorage.removeItem("admin_Token");
        window.location.href = "/";

      });

    },
  );

    useEffect(() => {
        getClientList();
      }, []);

      function onSearchSubmit() {
        getClientList();
        setFlag(true)
      }

      //  modal  //

      function copyAddress(data) {
        setBillAddress(address);
        setBillState(state);
        setBillCity(city);
        setBillZip(zip);
        setChecked(data);
      }
    
      // client logo upload base 64 conversion code

  let base64code = ""
  const onChange = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
 
  const onLoad = fileString => {

    base64code = fileString
    setSelectedImg(base64code)
    let finalImg = base64code.substr(base64code.indexOf(",")+1)
    console.log(finalImg);
    setImgData(finalImg)
  };
 
  const getBase64 = file => {

 
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };



   const [open, setOpen] = React.useState(false);

   const handleClickOpen = (data) => {
     
   
    
     setEmail(data.client_email)
     setPhone(formatPhoneNumber(data.client_phone))
     setNpi(data.client_npi_number)
     setName(data.client_name)
    //  setState(data.client_location_state)
     setCity(data.client_location_zip)
     setZip(data.client_location_zip)
     setClientID(data.client_id)
     seteffectiveDate(data.contract_start)
     setexpireDate(data.contract_end)
    //  settieredPrice(data.tiered_pricing)
     setaccMgr(data.dhct_account_mgr)
     setbillingContact(formatPhoneNumber(data.billing_contact))
     setWebsite(data.client_website)
     setAddress(data.client_location_address)

     let clientType = clientTypeList();
     let clientTypeData = data.client_type
    console.log("outside")
    console.log(clientType)
    console.log(clientTypeData)

     for(var i=0; i<clientType.length; i++){
        if(clientType[i].value === clientTypeData){
            console.log('inside')
            console.log(clientType[i])
            setClientType(clientType[i])
        }

     }

     let passwordChangeFreq = passChangeFreqList();
     let passwordChangeFreqData = data.password_change_frequency
     for(var i=0; i<passwordChangeFreq.length; i++){
        if(passwordChangeFreq[i].value === passwordChangeFreqData){
            setPassChangeFreq(passwordChangeFreq[i])
        }
     }


     let tieredList = tieredPriceList()
     console.log(tieredList)
  
     let tieredVal = data.tiered_pricing
     for(var i=0; i< tieredList.length; i++){
       if(tieredList[i].value === tieredVal){

         console.log(tieredList[i])
         settieredPrice(tieredList[i]);
       }
     }

    
        setBillAddress(data.client_billing_address);


        // setState(data.client_location_state)

        let stateData = data.client_location_state
        let stateCatList = stateList()

     
        console.log(stateData)

  
     
  
    for(var i=0; i<stateCatList.length; i++){

      let str = data.client_location_state
 

    const words = str.split(" ");
    // console.log(words)

     for (let j = 0; j < words.length; j++) {
      words[j] = words[j][0]?.toUpperCase() + words[j].substr(1);
      }
  
         let newWords = words.join(' ');
          // console.log(newWords)


        if(stateCatList[i].label === newWords){
          console.log(stateCatList[i])
        
          setState(stateCatList[i]);
          console.log(state)
        }
  

      }   


     

      for(var i=0; i<stateCatList.length; i++){

        let str =  data.client_billing_state

        let words = str.split(" ")


        for (var j=0;j<words.length; j++){
          words[j] = words[j][0]?.toUpperCase() + words[j].substr(1)
        }

          let newWords = words.join(" ")
          console.log(newWords)

        if(stateCatList[i].label === newWords){
          console.log(stateCatList[i])
          setBillState(stateCatList[i])
        }

      }
  


      let val = data.billing_code

      let newVal = val.match(/None/g);

      if(newVal != "None"){

      val= val.replace(/'/g,'"')

        val = JSON.parse(val)

        let billingData = billingList
        console.log(billingData)
        var ob_array = []
   for(var i=0; i<billingData.length; i++){
    

    for(var j=0; j<val.length; j++){

      console.log(val[j])
    
    if(billingData[i].billing_code == val[j]){
      console.log(val[j])

      ob_array.push(billingData[i].id)
     
    }
    
    
  }

  console.log(ob_array)

  setSelectedBiling(ob_array);

}
} 
      
      

     



      // setbillingRate(data.billing_code);
     
    
      // setSelectedBiling(val2);

        setBillCity(data.client_billing_city);
        setBillZip(data.client_billing_zip);
        setChecked(data);

        setflatRate(data.flat_rate)
        setcontactEmail(data.contact_email)
        setcontactPhone(formatPhoneNumber(data.contact_phone))
        setaddCost1(data.additional_billing_fee1)
        setaddCost2(data.additional_billing_fee2)
        setLogoFlag(data.logo_flag)
        console.log(selectedImg)
      //   let domainName = data.domain;
      //  let s3Url = data.logo_url;
        let imgLink = data.domain + data.logo_url;
        console.log(imgLink)
        setImgSrc(imgLink)
        

       setDisableClient(data.client_status === "1"? "true" : "false")
    
     setOpen(true);
   };
 

   

   const handleClose = () => {
    setImgSrc("")
    setState("")
    setBillState("")
     setOpen(false);
   };


   useEffect(() => {
    apiAxios
      .post(
        "/client/billing-rates",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setBillingList(response.data)
        setbillingRate(response.data);
        // response.data.map((e) => {
        //   billingOptions.push({
        //     name: e.billing_code,
        //     id: e.id,
        //   });
        // });
    
          
       
        console.log(billingOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [billingRate != []]);


  useEffect(() => {
    if(billingList){ 
      var ob_arr =[];
    
      for(var i=0;i<billingList.length;i++){
      let name = billingList[i].billing_code;
      let id = billingList[i].id;
     
      let ob = {
          name:name, id: id, 
      }
   
      ob_arr.push(ob);
      }

      console.log(ob_arr)
      setBillingOptions(ob_arr);
     
   }
   
 }, [billingList]);  




   function submitForm() {
    let isvalid = true;

    console.log(tieredPrice)
    console.log(disableClient)

    console.log("submit form");

    if (!checkName(name.trim())) {
        setNameError(true);
        isvalid = false;
      } else {
        setNameError(false);
      }
      if (!checkUSPhone(phone)) {
        setPhoneError(true);
        isvalid = false;
      } else {
        setPhoneError(false);
      }
      if (!checkEmail(email)) {
        setEmailError(true);
        isvalid = false;
      } else {
        setEmailError(false);
      }
     
      if (!checkNumbersOnly(npi)) {
        setNpiError(true);
        isvalid = false;
      } else {
        setNpiError(false);
      }
  
      if(clientType){
        setClientTypeError(false)
      }else {
        setClientTypeError(true)
        isvalid = false;
      }

      if (!checkName(accMgr.trim())) {
        setaccMgrError(true);
        isvalid = false;
      } else {
        setaccMgrError(false);
      }
      if (!checkUSPhone(billingContact.trim())) {
        setbillingContactError(true);
        isvalid = false;
      } else {
        setbillingContactError(false);
      }

      if(contactPhone){
        if(!checkUSPhone(contactPhone)){
          setContactPhoneError(true)
          isvalid = false
        }else {
          setContactPhoneError(false)
        }
      }else {
        setContactPhoneError(false)
      }
  
      if (effectiveDate) {
        seteffectiveDateError(false)
       
      } else {
        isvalid = false;
        seteffectiveDateError(true)
      }
      if (tieredPrice) {
        settieredPriceError(false)
       
      } else {
        isvalid = false;
        settieredPriceError(true)
      }
  
      if(tieredPrice == "no" || tieredPrice.value == "no"){
  
      if (flatRate) {
        setFlatRateError(false)
       
      } else {
        isvalid = false;
        setFlatRateError(true)
      }
    }else {
      setFlatRateError(false)
    }
  
    
      if(tieredPrice == "yes" || tieredPrice.value == "yes"){
          if (selectedBiling.length == 0){
            setSelectedBilingError(true)
            isvalid = false
          }else {
            setSelectedBilingError(false)
          
          }
      }else {
        setSelectedBilingError(false)
      }
  




    if (isvalid) {
      setIfLoading(true);
      let data = {
        client_id: clientID,
        client_npi_number: npi,
        name: name,
        client_name: name,
        client_type: clientType.value ? clientType.value : clientType[0].value,
        client_billing_address: billAddress,
        client_billing_city: billCity,
        client_billing_state: billState.label ? billState.label : billState ,
        client_billing_zip: billZip,
        client_location_address: address,
        client_location_city: city,
        client_location_state: state.label? state.label: state,
        client_location_zip: zip,
        client_phone: phone.replace(/[^0-9]/g,""),
        client_email: email,
        client_website: website,
        password_change_frequency: passChangeFreq.value ? passChangeFreq.value : passChangeFreq[0].value,
        checked: checked,
        image_name: imgData,
        client_status : disableClient === "true" ? "1" : "0",
       
        contract_start: moment(effectiveDate).format("YYYY-MM-DD"),
        contract_end: expireDate ? moment(expireDate).format("YYYY-MM-DD") : "",
        tiered_pricing: tieredPrice.value ? tieredPrice.value : tieredPrice,
        price_change_date: "",
        dhct_account_mgr: accMgr,
        billing_contact: billingContact.replace(/[^0-9]/g,''),
        contact_phone: contactPhone? contactPhone.replace(/[^0-9]/g, "") : "",
        contact_email: contactEmail,
        additional_billing_fee1: addCost1,
        additional_billing_fee2: addCost2,
        additional_billing_fee3: "",
        location: "mumbai",
        user_login_panel_type: "1",
        flat_rate: flatRate,
        billing_id:(tieredPrice == "no" || tieredPrice.value == "no")? [] : selectedBiling,
       

      };

      console.log("Final Data = ", data);

      apiAxios
        .post("/client/update-client", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);

          if (response.data.statusCode == 200) {
            // setClientUserTypes(response.data.data);
           

            //console.log("user types = ", userTypes);
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);

            setSelectedImg("")

             const timer = setTimeout(() => {
              window.location.href = "/clients";
            }, 2000);


          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });
    }
  }


  function gotoCareteam(item) {
   

   
  let userDetails = JSON.parse(localStorage.getItem("admin_JWT")) 
   

    




   
      // setIfLoading(true);


      let data = {
       
        user_master_id: userDetails.user_uuid,
        client_name: item.client_name,
        client_id: item.client_id

      };

      console.log("Final Data = ", data);

      apiAxios
        .post("/user/enterprise_sso", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("admin_Token"),
          },
        })
        .then((response) => {
          console.log("response --> ", response);

          if (response.data.statusCode == 200) {

      
          // localStorage.setItem("Token_Careteam", response.data.id_token);

         console.log(response.data.id_token)
           const str = response.data.id_token
           const str2 = response.data.refresh_token
           // encoding the string
           const result = window.btoa(str);
           const secResult = window.btoa(str2)
           console.log(result);
    
         
        

          // window.location.href = "http://127.0.0.1:3000/dashboard-redirect?token="+result+ "&refreshToken=" +secResult

          // let redirectUrl= "http://127.0.0.1:3000/dashboard-redirect?token="+result+ "&refreshToken=" +secResult
           

          // window.location.href = baseFrontendURl+"/dashboard-redirect?token="+result + "&refreshToken=" + secResult

           let redirectUrl = baseFrontendURl+"/dashboard-redirect?token="+result + "&refreshToken=" + secResult

          window.open(redirectUrl, "_blank")
         
          
            toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          
         
          } else {
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIfLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Something Went Wrong!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIfLoading(false);
        });
    
  }


  const handleChangeSelected = (e)=>{
 
    setDisableClient(e.target.value)


  }


  const handlePhone = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setPhone(formattedPhoneNumber);
  };

  const handleInput = (e) => {
   
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setcontactPhone(formattedPhoneNumber);
  };
  
  const handleContactInput = (e) => {
     
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    
    setbillingContact(formattedPhoneNumber);
  };
  
  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
  
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
  
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
  
    if (phoneNumberLength < 4) return phoneNumber;
  
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
  
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  
  
  }
  
// ***  //
const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedMenu, setSelectedMenu] = React.useState(null);


  const handleClick = (e,index) => {
    setAnchorEl(e.currentTarget);
    setSelectedMenu(index)


  };

  const handleClose3 = () => {
    setAnchorEl(null);
    setSelectedMenu(null)
  };


  // client Search

  const keyPressHandler = (e)=>{

    if(e.keyCode === 13){
      onSearchSubmit();
    }

  }


    return (
        <div>
             <Header />
            <Box className={classes.Pagecontent}>
               <Box className={classes.Leftcol}>
               <Box className={classes.leftnav}>
               <Box className={classes.leftHeading}>
                   <TopHeading />
                </Box>     
               <LeftNav />
                 {/* left Accordion*/}
               
                 <Box className={classes.bottomnav}>
                 <Setting />
               </Box>
               </Box>
               
               </Box>
               {/* right col */}
               <Box className={classes.Rightcol}>
               
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <Box className={classes.providerlist}>
                  <Box className={classes.pageTop}>
                  <Link to="/toolkit"><Button className={classes.backBtn}><BiArrowBack className={classes.backarrow} /></Button></Link>
                 </Box>
                 <Box className={classes.Topfilter}>
                 <Box
              style={{ display: "flex", flexDirection: "row", width: "50%" }}
            >
              
              <Box style={{ width: "60%" }}>
                <label>Client Search</label>
                <TextField
                  className={classes.input}
                  placeholder="Client Search"
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown= {keyPressHandler}
                   
                />
              </Box>
              
              <Button className={classes.Searchbtn} onClick={onSearchSubmit}>
                Search
              </Button>
              
            </Box>

            </Box>
             <Box>
                         {(search && flag) ?
                            <h4>Search Results for ‘{search}’</h4>
                            :
                            <></>
                         }
                    </Box> 
            
        
                      <Box className={classes.topcol}>

                    <h3 className={classes.topheading}>Client List</h3>
                    <Link to="/new-clients"><Button className={classes.addprovider}><BiPlusCircle className={classes.icon} /> Add New Client</Button></Link>
                    </Box>
                    <Box className={classes.ClientTable}>
                    <Box className={classes.toprow}>
                        <Box className={classes.thcol}>Client ID</Box>
                        <Box className={classes.thcol}>NPI Number</Box>
                        <Box className={classes.thcol2}><Box className={classes.providerbtn} role="button">Client</Box></Box>
                        <Box className={classes.thcol3}>Email ID</Box>
                        <Box className={classes.thcol4}>Phone Number</Box>
                        <Box className={classes.thcol5}>Action</Box>
                    </Box>
                    {(clientList.length > 0) ?
                    <>
                    {clientList.map((each,index) => (
                    <Box className={classes.providerrow}>
                       <Box className={classes.tdcol}><Typography className={classes.tdtext}>{each.client_view_id}</Typography></Box>

                        <Box className={classes.tdcol}><Typography className={classes.tdtext}>{each.client_npi_number}</Typography></Box>

                        <Box className={classes.tdcol2}><Typography className={classes.tdtext}>{each.client_name}</Typography></Box>
                        <Box className={classes.tdcol3}><Typography className={classes.tdtext}>{each.client_email}</Typography></Box>
                        <Box className={classes.tdcol4}><Typography className={classes.tdtext}>{formatPhoneNumber(each.client_phone)}</Typography></Box>
                        <Box className={classes.tdcol5}>
                        <Button className={classes.ActionBtn} aria-controls={"simple-menu" + index} aria-haspopup="true" onClick={(e)=>handleClick(e,index)}>
       Action <FiChevronDown />
      </Button>
      <Menu className={classes.ActionList} style={{boxShadow:'none'}}
        id={"simple-menu" + index}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(selectedMenu === index)}
        onClose={handleClose3}
        key={"simple-menu" + index}
      >
        <MenuItem style={{padding:'6px 16px'}} id={"simple-menu-item" + index} onClick={()=>{
          handleClickOpen(each)
          handleClose3()
          
        }
          }>Edit</MenuItem>
        <MenuItem  onClick={handleClose3}><Link to={{pathname: "/patient", state: { client_id: each.client_id, client_name: each.client_name },}}>Patients</Link> </MenuItem>
        <MenuItem  onClick={handleClose3}><Link to={{pathname: "/manage-program", state: { client_id: each.client_id, client_name: each.client_name },}}>Programs</Link></MenuItem>
        <MenuItem  onClick={handleClose3}><Link to={{pathname: "/manage-user", state: { client_id: each.client_id, client_name: each.client_name },}}>Users</Link></MenuItem>
        <MenuItem style={{padding:'6px 16px'}}  onClick={()=>{
          gotoCareteam(each)
          handleClose3()
        }
        }>Launch</MenuItem>
      </Menu>
                          {/* <Button className={classes.edit} onClick={()=>handleClickOpen(each)}>Edit</Button> 
                         <Link to={{pathname: "/patient", state: { client_id: each.client_id, client_name: each.client_name },}}><Button className={classes.PatientBtn}>Patients</Button></Link> 
                         <Link to={{pathname: "/manage-program", state: { client_id: each.client_id, client_name: each.client_name },}}><Button className={classes.program}>Programs</Button></Link> 

                         <Link to={{pathname: "/add-program", state: { client_id: each.client_id, client_name: each.client_name },}}><Button className={classes.program} >Program</Button></Link> 
                          <Link to={{pathname: "/manage-user", state: { client_id: each.client_id, client_name: each.client_name },}}><Button className={classes.view}>Users</Button></Link> 
                          <Button className={classes.viewBtn} onClick={()=>gotoCareteam(each)}>Launch</Button>  */}
                        </Box>
                    </Box>
                    ))}
                    </>
                    :
                    <Box className={classes.providerrow}>
                        <Box className={classes.tdcol12} style={{textAlign:'center',width:'100%',color:'#dfdfdf'}}>
                            {tablemessage}
                        </Box>
                    </Box>
                    }
                    </Box>
                    
                </Box>
                  </Grid>
                  
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                      <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getClientList({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      />
                    </div>

                    <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />

               </Grid>
               
               </Box>
            </Box>
           
                        {/* Update client modal */}
        <Dialog
          className={classes.modal}
          open={open}
          disableEnforceFocus={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.ccmmodal}>
            <Box className={classes.btncol}>
              <Button onClick={handleClose} className={classes.closebtn}>
                <BiXCircle className={classes.closeicon} />
              </Button>
            </Box>
            <DialogContentText id="alert-dialog-slide-description">
            <Box className={classes.loginform}>
                <form>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5" className={classes.FormHeading}>
                        Update Client
                      </Typography>
                        <br />
                      <Box className={classes.Formcol}>
                        <label>
                          Name<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Name of Client"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Box>
                      {nameError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid name.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Phone<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Phone Number"
                          type="tel"
                          value={phone}
                          onChange={(e) => {
                            // if (checkPhoneNumbers(e.target.value)) {
                            //   setPhone(e.target.value);
                            // }
                            handlePhone(e)
                          }}
                        />
                      </Box>
                      {phoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Email<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Email Address"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Box>
                      {emailError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid email.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Client Type<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={clientTypeList()}
                          onChange={setClientType}
                         
                          
                          className={classes.select}
                          style={{width:'100%'}} 
                          
                          value={clientType? clientType : ""}
                          placeholder={clientType ? clientType.label : "Select Client Type"}
                         
                        />
                      </Box>
                      {clientTypeError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please select client type
                        </p>
                      ) : (
                        <></>
                      )}
                      {/* <Box className={classes.Formcol}>
                        <label>
                          Client ID<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Client ID"
                          type="text"
                          value={clientId}
                          onChange={(e) => {
                            if (checkAlphaNumeric(e.target.value)) {
                              setClientId(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      {clientIdError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid Id.
                        </p>
                      ) : (
                        <></>
                      )} */}
                      <Box className={classes.Formcol}>
                        <label>Client Website (if any)</label>
                        <TextField
                          className={classes.input}
                          placeholder="example: www.dhct.com"
                          type="text"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>
                          Client NPI Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          placeholder="Client NPI Number"
                          type="text"
                          value={npi}
                          onChange={(e) => {
                            if (checkPhoneNumbers(e.target.value)) {
                              setNpi(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      {npiError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid NPI number.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>Password Change Freq</label>
                        <Select
                          options={passChangeFreqList()}
                          // onChange={(values) =>
                          //   setPassChangeFreq(values[0].value)
                          // }

                          onChange={setPassChangeFreq}
                            
    
                          placeholder={passChangeFreq ? passChangeFreq.label : "Select Password Change Frequency"}
                          className={classes.select}
                          style={{ width: "100%" }}
                         
                          value={passChangeFreq?  passChangeFreq.value : ""}
                        />
                      </Box>

                      <Box className = {classes.Formcol}>
                      <label>
                        Disable Client
                      </label>
                     
                       
                      <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          
                          value={disableClient}
                          name="radio-buttons-group"
                         
                        style={{marginLeft: "135px"}}
                        >
                          <Box style={{display:"flex", alignItems:"flex-start"}}>
                          <FormControlLabel
                            className={classes.customRadio}
                            value="false"
                            control={<Radio />}
                            label="Yes"
                            onChange={handleChangeSelected}
                            selected={disableClient}
                          />
                          <FormControlLabel
                            className={classes.customRadio}
                            value="true"
                            control={<Radio />}
                            label="No"
                            onChange={handleChangeSelected}
                            selected={disableClient}
                          />
                         </Box>
                        </RadioGroup>

                      </Box>



                      
                      {/* Client Logo */}

                      <Box className={classes.Formcol}>
                        <label>Client Logo</label>

                       <form>
                       <input type="file" onChange={onChange} /> <br />
                       {/* <textarea className={classes.input}></textarea> */}
                       {/* <Button className={classes.uploadBtn} onClick={imgUpload}>Upload</Button> */}
                       </form>
                       {selectedImg ?
                       <Box className={classes.profileLogo2}>

                       <img src={selectedImg ? selectedImg : ""} alt="Client Logo"  />
                       </Box>

                       
                        :logoFlag ?
                       <Box className={classes.profileLogo2}>

                       <img src={imgSrc+ `?${performance.now()}`} alt ="Logo"/>
                       </Box>
                       : 
                       <>
                       </>
}
                        
                      </Box>
                     

                      

                      <Typography variant="h6" className={classes.FormHeading}>
                        Client Address (Location)
                      </Typography>
                      <br />
                      <Box className={classes.Formcol}>
                        <label>Client Address</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={address}
                          onChange={(e) => {
                            if (checked) {
                              setAddress(e.target.value);
                              setBillAddress(e.target.value);
                            } else {
                              setAddress(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>State</label>
                        {/* <TextField
                          className={classes.input}
                          placeholder="Enter State here"
                          type="text"
                          value={state}
                          onChange={(e) => {
                            if (checked) {
                              setState(e.target.value);
                              setBillState(e.target.value);
                            } else {
                              setState(e.target.value);
                            }
                          }}
                        /> */}
                         <Select
                          options={stateList()}
                          value={state? state.label : ""}
                          // onChange={setState}
                          placeholder={state? state.label : "Select State"}
                          className={classes.select}
                          onChange={(values) => {
                            if (checked) {
                              setState(values[0].label);
                              setBillState(values[0]);
                            } else {
                              setState(values[0].label);
                              
                            }
                          }}
                         
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>City</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City here"
                          type="text"
                          value={city}
                          onChange={(e) => {
                            if (checked) {
                              setCity(e.target.value);
                              setBillCity(e.target.value);
                            } else {
                              setCity(e.target.value);
                            }
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Zipcode</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="text"
                          style={{ width: 200 }}
                          value={zip}
                          onChange={(e) => {
                            if (checked) {
                              if (checkClientZip(e.target.value)) {
                                setZip(e.target.value);
                                setBillZip(e.target.value);
                              }
                            } else {
                              if (checkClientZip(e.target.value)) {
                                setZip(e.target.value);
                              }
                            }
                          }}
                        />
                      </Box>

                      <Box className={classes.Formcol}>
                        <label
                          style={{ flex: "0 0 260px", paddingTop: "20px" }}
                        >
                          Billing & Location Address Same
                        </label> 
                       
                        <label style={{ paddingTop: "20px" }}>
                          <input
                            type="checkbox"
                            checked={checked}
                            onChange={(e) => copyAddress(e.target.checked)}
                          />
                          Yes
                        </label>
                      </Box>
                      <Typography variant="h6" className={classes.FormHeading}>
                        Client Billing Address
                      </Typography>
                      <br />
                      <Box className={classes.Formcol}>
                        <label>Client Address</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Address"
                          type="text"
                          value={billAddress}
                          disabled={checked}
                          onChange={(e) => setBillAddress(e.target.value)}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>State</label>
                        {/* <TextField
                          className={classes.input}
                          placeholder="Enter State here"
                          type="text"
                          value={billState}
                          disabled={checked}
                          onChange={(e) => setBillState(e.target.value)}
                        /> */}
                         <Select
                          options={stateList()}
                          value={billState ? billState.label : ""}
                          onChange={(values)=>setBillState(values[0].label)}
                          placeholder={billState? billState.label : "Select State"}
                          className={classes.select}
                          disabled={checked}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>City</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter City here"
                          type="text"
                          value={billCity}
                          disabled={checked}
                          onChange={(e) => setBillCity(e.target.value)}
                        />
                       
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Zipcode</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Zipcode"
                          type="text"
                          style={{ width: 200 }}
                          value={billZip}
                          disabled={checked}
                          onChange={(e) => {
                            if (checkClientZip(e.target.value)) {
                              setBillZip(e.target.value);
                            }
                          }}
                        />
                      </Box>
{/* 
                      <Box className={classes.Btncol}>
                        <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={(e) => onNextClick()}
                        >
                          {" "}
                          Next{" "}
                        </Button>
                      </Box> */}
                     <Typography variant="h6" className={classes.FormHeading}>
                        Contract Information
                      </Typography>
                      <br />
                      <Box className={classes.Formcol}>
                        <label>
                          Account Manager
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          // value="DHCT Owner"
                          placeholder="Name of Client"
                          type="text"
                          value={accMgr}
                          onChange={(e) => setaccMgr(e.target.value)}
                        />
                      </Box>
                      {accMgrError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid account manager.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Effective Date
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <DatePicker
                          className={classes.input}
                          // value={effectiveDate}
                          value={effectiveDate? new Date(effectiveDate):  null}
                          onChange={(e) => seteffectiveDate(e)}
                          // maxDate={moment().toDate()}
                        //   maxDate={expireDate}
                        />
                      </Box>
                      {effectiveDateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid date.
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Expiration Date
                          {/* <span style={{ color: "#ff0000" }}>*</span> */}
                        </label>
                        <DatePicker
                          // value={expireDate}
                          value={expireDate? new Date(expireDate):  null}
                          onChange={(e) => setexpireDate(e)}
                          className={classes.input}
                        //   minDate={effectiveDate}
                        // maxDate={moment().toDate()}
                        />
                      </Box>
                      {/* {expireDateError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid date.
                        </p>
                      ) : (
                        <></>
                      )} */}
                      <Box className={classes.Formcol}>
                        <label>
                          Billing Contact
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <TextField
                          className={classes.input}
                          // value="Billing Contact"
                          placeholder="Please enter a mobile number"
                          type="text"
                          value={billingContact}
                          onChange={(e) => handleContactInput(e)}
                        />
                      </Box>
                      {billingContactError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid contact number
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>
                          Tiered Pricing
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <Select
                          options={tieredPriceList()}
                          
                          onChange={(values)=>{
                            settieredPrice(values[0].value)
                            
                            
                          }}
                          className={classes.select}
                          style={{ width: "100%" }}
                          // values={tieredPriceList().filter(
                          //   (option) => option.value === tieredPrice
                          // )}
                          value={tieredPrice}
                          placeholder={tieredPrice? tieredPrice.label : "Please Select"}
                       
                        />
                      </Box>

                      {tieredPriceError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please select Tiered Pricing
                        </p>
                      ) : (
                        <></>
                      )}



                      {(tieredPrice === "yes" || tieredPrice.value === "yes")? (
                        <Box className={classes.Formcol}>
                          <label>Billing Rate
                          <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          {/* <Select
                            options={billingOptions}
                            onChange={(values) =>
                              setSelectedBiling(values[0].value)
                            }
                            className={classes.select}
                            style={{ width: "100%" }}
                          /> */}
                          <Multiselect
                            className={classes.select}
                            style={{ width: "100%" }}
                            options={billingOptions}
                            displayValue="name"
                            selectedValues={billingOptions.filter((e) =>
                              selectedBiling.some((v) => v == e.id)
                            )}

                            onSelect={(e, i) =>
                              setSelectedBiling((oldArray) => [
                                ...oldArray,
                                i.id,
                              ])
                            }
                            onRemove={(e, i) =>
                              setSelectedBiling(
                                selectedBiling?.filter(function (f) {
                                  return f !== i.id;
                                })
                              )
                            }
                            // placeholder= "Select billing"
                          />
                        </Box>
                        
                        
                      ) : (
                        <Box className={classes.Formcol}>
                          <label>Flat Rate
                          <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <TextField
                            className={classes.input}
                            placeholder="Flat Rate"
                            type="tel"
                            value={flatRate}
                            onChange={(e) => setflatRate(e.target.value)}
                          />

                          
                        </Box>

                      
                       
                      )}

                       {flatRateError ? (
                          <p
                            style={{
                              color: "#dc3545",
                              fontSize: ".875em",
                              marginTop: "0.25rem",
                            }}
                          >
                           Flat rate is required
                          </p>
                        ) : (
                          <></>
                        )}

                       {selectedBillingError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please select billing
                        </p>
                      ) : (
                        <></>
                      )}

                      <Box className={classes.Formcol}>
                        <label>Additional Cost 1</label>
                        <TextField
                          className={classes.input}
                          placeholder="Additional Cost 1"
                          type="tel"
                          value={addCost1}
                          onChange={(e) => {
                            
                            setaddCost1(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Additional Cost 2</label>
                        <TextField
                          className={classes.input}
                          placeholder="Additional Cost 2"
                          type="tel"
                          value={addCost2}
                          onChange={(e) => {
                            
                            setaddCost2(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className={classes.Formcol}>
                        <label>Contact (Phone)</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Phone Number"
                          type="tel"
                          value={contactPhone}
                          onChange={(e) => {
                            // if (checkPhoneNumbers(e.target.value)) {
                            //   setcontactPhone(e.target.value);
                            // }
                            handleInput(e)
                          }}
                        />
                      </Box>
                      {contactPhoneError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please enter a valid contact number
                        </p>
                      ) : (
                        <></>
                      )}
                      <Box className={classes.Formcol}>
                        <label>Contact (Email)</label>
                        <TextField
                          className={classes.input}
                          placeholder="Enter Email Address"
                          type="text"
                          value={contactEmail}
                          onChange={(e) => setcontactEmail(e.target.value)}
                        />
                      </Box>
                      {/* <Box className={classes.Formcol}>
                      <label>Price Change Date</label>
                      <TextField className={classes.input} placeholder="Price Change Date" type="text" />
                      </Box> */}
                      {(tieredPrice === "yes" || tieredPrice.value === "yes") ? (
                        <>
                          <Box className={classes.Thcol}>
                            <Box className={classes.col1}>Billing Code</Box>
                            <Box className={classes.col2}>Description</Box>
                            <Box className={classes.col3}>Volume Tier</Box>
                            <Box className={classes.col4}>Unit Price</Box>
                            <Box className={classes.col5}></Box>
                          </Box>
                          {billingRate
                            .filter((e) =>
                              selectedBiling.some((v) => v == e.id)
                            )
                            .map((e) => {
                              return (
                                <Box className={classes.Tdcol}>
                                  <Box className={classes.Tdcol1}>
                                    {e.billing_code}
                                  </Box>
                                  <Box className={classes.Tdcol2}>
                                    {e.billing_description}
                                  </Box>
                                  <Box className={classes.Tdcol3}>
                                    {e.tier_range_start +
                                      "-" +
                                      e.tier_range_end}
                                  </Box>
                                  <Box className={classes.Tdcol4}>
                                    {e.tier_range_price}
                                  </Box>
                                  <Box className={classes.Tdcol5}>
                                    <Button
                                      className={classes.deletebtn}
                                      onClick={(f) =>
                                        setSelectedBiling(
                                          selectedBiling?.filter(function (f) {
                                            return f !== e.id;
                                          })
                                        )
                                      }
                                    >
                                      -
                                    </Button>
                                  </Box>
                                </Box>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}

                      
                      {/* <Box className={classes.Btncol}>
                       
                        
                       
                        { !ifLoading?
                        <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={(e) => onCreateClient()}
                        >
                          {" "}
                          Create Client{" "}
                        </Button>

                        :
                        <ThreeDots
                   
                      color="#000000"
                       height={50}
                      width={50}
                       timeout={0} //30 secs
                   
                   />
                   }
                      </Box> */}




                    </Grid>
                  </Grid>
                </form>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalbtn}>
            <FormGroup aria-label="position" row></FormGroup>
            {!ifLoading?
            <Button
              size="large"
              className={classes.loginbtn}
                onClick={submitForm}
            >
              Save
            </Button>
            :
                  <ThreeDots
                   
                    color="#000000"
                    height={50}
                    width={50}
                    timeout={0} //30 secs
                    />
            }
          </DialogActions>
        </Dialog>
                
             
        </div>
    )
}

export default ManageClients
const useStyles = makeStyles(() => ({
    Pagecontent:{
        width:'100%',
        display:'flex',
        textAlign:'left'
    },
    
    Searchbtn: {
      background: "#7087A7",
      borderRadius: 10,
      display: "flex",
      color: "#fff",
      height: 50,
      paddingLeft: 15,
      paddingRight: 15,
      marginLeft: 10,
      textTransform: "capitalize",
      marginTop: 28,
      "&:hover": {
        background: "rgba(0,0,0,0.8)",
      },
    },
    ActionBtn:{
      width:100,
      display:'flex',
      justifyContent:'space-between',
      border:'1px #ccc solid',
      textTransform:'capitalize',
      fontFamily:'poppins',
      fontSize:13,
      borderRadius:10,
    },
    backBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        width:30,
        minWidth:30,
        height:20,
        borderRadius:5,
        padding:5,
        '&:hover':{
            background:'none'
        }
    },
    providerlist:{
        fontSize:'14px',
    },
    Thcol: {
      display: "flex",
      flexDirection: "row",
      borderBottom: "1px #E3E5E6 solid",
      padding: "5px 0",
    },
    Tdcol: {
      display: "flex",
      flexDirection: "row",
      borderBottom: "1px #E3E5E6 solid",
      padding: "10px 0",
    },
    col1: {
      width: "22%",
      fontSize: 14,
      color: "rgba(143,150,153,0.6)",
    },
    col2: {
      width: "36%",
      fontSize: 14,
      color: "rgba(143,150,153,0.6)",
    },
    col3: {
      width: "15%",
      fontSize: 14,
      color: "rgba(143,150,153,0.6)",
    },
    col4: {
      width: "15%",
      fontSize: 14,
      color: "rgba(143,150,153,0.6)",
      textAlign: "right",
    },
    col5: {
      width: "12%",
    },
    
    thcol:{
        // width:'10%',
        width:'12%',
        fontSize:14,
        fontFamily:'poppins',
       
    },
    thcol2:{
        width:'20%',
        fontSize:14,
        fontFamily:'poppins',
       
    },
    thcol3:{
        width:'30%',
        fontSize:14,
        fontFamily:'poppins',
       
    },
    thcol4:{
        width:'16%',
        fontSize:14,
        fontFamily:'poppins',
        
    },
    thcol5:{
        width:'10%',
        textAlign:'right',
        fontSize:14,
        fontFamily:'poppins',
        
    },
    tdcol:{
        width:'11.5%',
        wordWrap: "break-word",
        overflow: "auto",
        fontSize:14,
        fontFamily:'poppins',
        paddingRight:'0.5%',
    },
    tdcol2:{
        width:'19.5%',
        wordWrap: "break-word",
        overflow: "auto",
        fontSize:14,
        fontFamily:'poppins',
        paddingRight:'0.5%',
    },
    tdcol3:{
        width:'29.5%',
        wordWrap: "break-word",
        overflow: "auto",
        fontSize:14,
        fontFamily:'poppins',
        paddingRight:'0.5%',
    },
    tdcol4:{
        width:'15.5%',
        wordWrap: "break-word",
        overflow: "auto",
        textAlign : "center",
        fontSize:14,
        fontFamily:'poppins',
        paddingRight:'0.5%',
    },
    tdcol5:{
        width:'10%',
        textAlign:'right',
        flexDirection:'row',
        fontSize:14,
        fontFamily:'poppins',
        // alignItems:'center',
        display:'flex',
        justifyContent:'flex-end',
        
        '& a':{
            textDecoration:'none'
        },
       
    },
    topcol:{
        display:'flex',
        alignItems:'center',
        marginBottom:30,
        justifyContent:'space-between',
        '& a':{
            textDecoration:'none'
        }
    },
    Btnlink:{
        fontSize:'16px',
        color:'#8088A8',
        backgroundColor:'transparent',
        padding:'0 10px',
        display:'flex',
        justifyContent:'flex-start',
        textTransform:'capitalize',
        '&:hover':{
            color:'#000',
            backgroundColor:'#fff'
        }
    },
    PatientBtn:{
        color:'#fff',
        fontFamily:'Poppins',
        background:'#E8740A',
        cursor:'pointer',
        display:'flex',
        paddingLeft:10,
        paddingRight:10,
        height:35,
        marginRight:5,
        fontSize:12,
        borderRadius:10,
        textTransform:'capitalize',
        '&:hover':{
            color:'#fff',
            borderRadius:10,
            background:'#121212'
        }
    },
    ClientTable:{
      width:'100%',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
       overflowX:'auto'
              }
    },
    view:{
        color:'#fff',
        background:'#0A70E8',
        fontFamily:'Poppins',
        borderRadius:10,
        fontSize:12,
        height:35,
        cursor:'pointer',
        textTransform:'capitalize',
        marginLeft:5,
        '&:hover':{
            color:'#fff',
            borderRadius:10,
            background:'#121212',
        }
    },
    viewBtn:{
      color:'#fff',
      background:'#eb4934',
      fontFamily:'Poppins',
      borderRadius:10,
      fontSize:12,
      cursor:'pointer',
      textTransform:'capitalize',
      marginLeft:5,
      '&:hover':{
          color:'#fff',
          borderRadius:10,
          background:'#121212',
      }
  },
    edit:{
        color:'#fff',
        background:'#030ffc',
        fontFamily:'Poppins',
        borderRadius:10,
        paddingLeft:10,
        paddingRight:10,
        height:35,
        fontSize:12,
        cursor:'pointer',
        textTransform:'capitalize',
        marginRight:5,
        '&:hover':{
            color:'#fff',
            borderRadius:10,
            background:'#121212',
        }
    },
    Leftbutton:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start'
    },
    Accessbtn:{
        fontSize:'14px',
        color:'#141621',
        textTransform:'capitalize',
    },
    addprovider:{
        fontSize:'14px',
        borderRadius:10,
        color:'#8088A8',
        textTransform:'capitalize',
        backgroundColor:'transparent',
        marginRight:'10px',
        display:'flex',
        alignItems:'center'
    },
    btncheck:{
        color:'#5FD828',
        marginLeft:'10px'
    },
    btncancel:{
        color:'#C13229',
        marginLeft:'10px'
    },
    btncol:{
        display:'flex',
        justifyContent:'flex-end'
    },
    leftHeading:{
      '@media only screen and (max-width: 820px)':{
        display:'none'
       },
      '@media only screen and (max-width: 768px)':{
        display:'none'
       },
    },
    topheading:{
        margin:'0',
        fontWeight:'600',
        color:'#141621',
        fontFamily:'Poppins',
        fontSize:18,
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start'
    },
    toprow:{
        width:'100%',
        borderBottom:'2px #E3E5E5 solid',
        display:'flex',
        color:'#919699',
        paddingBottom:'10px',
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1000,
                }
    },
    profileLogo2:{
      width:'45px !important',
      height:'45px',
      borderRadius:'50%',
      
      marginRight:'10px',
      // overflow:'hidden',
      '& img':{
          width:'100%',
          // objectFit:"stretch",
          height:'100%'
      }
  },
    pageTop:{
        textAlign:'left',
        marginBottom:'30px',
        display:'flex',
        '@media only screen and (max-width: 820px)':{
          marginBottom:'20px',
        },
        '@media only screen and (max-width: 768px)':{
          marginBottom:'20px',
        },
        '& button':{
            padding:'0px',
            background:'none',
            color:'#919699',
            fontSize:'15px',
            textTransform:'capitalize',
            fontWeight:'500',
        }
    },
    profile:{
        width:'60px',
        height:'60px',
        borderRadius:'50%',
        overflow:'hidden',
        '& img':{
            width:'100%'
        }
    },
    backarrow:{
        color:'#8088A8',
        fontSize:'20px',
        marginRight:'8px'
    },
    Leftcol:{
        width:'15%',
        padding:'20px 3%',
        position:'relative',
        minHeight:'800px',
        '@media only screen and (max-width: 1200px)':{
          width:'25%',
                  },
                  '@media only screen and (max-width: 820px)':{
                    position:'absolute'
                            },
        '@media only screen and (max-width: 768px)':{
          position:'absolute'
                  },
    },
    bottomnav:{
        position:'absolute',
        bottom:'0px',
        left:'0px',
        '@media only screen and (max-width: 820px)':{
          display:'none'
        },
        '@media only screen and (max-width: 768px)':{
          display:'none'
        }
    },
    leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px',
    '@media only screen and (max-width: 820px)':{
      left:'15px',
    },
    '@media only screen and (max-width: 768px)':{
      left:'15px',
    }
    },
    Rightcol:{
        width:'85%',
        padding:'20px 2%',
        borderLeft:'1px #ededed solid',
        '@media only screen and (max-width: 1200px)':{
          width:'70%',
          padding:'20px 3%',
                },
                '@media only screen and (max-width: 820px)':{
                  width:'94%',
                  padding:'20px 3%',
                        },
                '@media only screen and (max-width: 768px)':{
                  width:'94%',
                  padding:'20px 3%',
                        },
        '& .MuiAccordionSummary-root':{
            borderBottom:'2px #E3E5E5 solid',
            height:'40px',
            color:'#919699',
            fontFamily:'Poppins',
        },
        '& .MuiAccordion-root:before':{
            display:'none'
        }
    },
    
Downarrow:{
    fontSize:'20px',
    color:'#8088A8',
    marginLeft:'5px'
},

icon:{
  color:'#8088A8',
  fontSize:'20px',
  marginRight:'5px'
},
providerrow:{
    width:'100%',
    borderBottom:'1px #E3E5E5 solid',
    padding:'15px 0',
    alignItems:'center',
    display:'flex',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1000,
            },
    '& p':{
        textAlign:'left',
        fontSize:14,
        fontFamily:'poppins'
    },
   
},
providerbtn:{
    display:'flex',
    cursor:'pointer',
    '& span':{
        display:'flex',
        flexDirection:'column',
        width:'20px',
        marginRight:'10px',
        '& button':{
            background:'none',
            border:'none',
            height:'10px',
            cursor:'pointer'
        }
    }
},

checkicon:{
    fontSize:'25px',
    color:'#48C932'
},
inputfile:{
    display:'none'
},
// select:{
//     width:'200px',
//     border:'none !important',
//     borderRadius:'10px !important',
//     background:'#F1F1F1',
//     height:'50px',
//     fontSize:'18px !important',
//     paddingLeft:'15px !important',
//     paddingRight:'15px !important'
// },
select: {
    width: "100%",
    border: "none !important",
    borderRadius: "10px !important",
    border: "1px #D5D5D5 solid",
    backgroundColor: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
   
    // '& .react-dropdown-select-input':{
    //  display: "none"
    // },
   
  },
Toptext:{
    fontSize:'18px',
    color:'#141621',
    fontWeight:'600',
    marginTop:'-15px',
    marginBottom:'30px'
},
Textheading:{
    fontSize:'16px',
    marginTop:'0px',
    fontWeight:'500'
},
Addbtn:{
    width:'180px',
    height:'45px',
    background:'#E13F66',
    borderRadius:'10px',
    color:'#fff',
    boxShadow:'0px 0px 12px 6px rgba(0, 0, 0, 0.18)',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textTransform:'capitalize',
    fontSize:'16px',
    '&:hover':{
        background:'#000'
    }
},

addprovider:{
    fontSize:'14px',
    color:'#8088A8',
    textTransform:'capitalize',
    borderRadius:10,
},
btncol:{
    display:'flex',
    justifyContent:'flex-end',
    marginTop:'100px'
},
program:{
    color:'#fff',
    background:'#009933',
    fontFamily:'Poppins',
    borderRadius:10,
    fontSize:12,
    height:35,
    cursor:'pointer',
    textTransform:'capitalize',
    '&:hover':{
        color:'#fff',
        borderRadius:10,
        background:'#121212',
    }
},
ccmmodal: {
    borderRadius: "10px",
    "& label": {
      color: "#666",
      marginBottom: 5,
      display: "flex",
      flex: "0 0 150px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0074D9 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
    },
  },
  modalbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "30px",
    marginLeft: "15px",
    alignItems: "center",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#7087A7",
  },
  loginbtn: {
    background: "#1612C6",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  btncol:{
    display:'flex',
    justifyContent:'flex-end'
},

  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "&>div": {
      width: "100%",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
      flex: "0 0 205px",
      color: "#000",
      fontSize: "15px",
      fontWeight: "400",
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #7087A7 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
  },
  modal: {
    "& .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "20px",
      width: "750px",
      maxWidth: "850px",
    },
  },
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
  },
  Topfilter: {
    width: "100%",
    display: "flex",
    marginTop: 30,
    marginBottom: 30,
    flexDirection: "row",
    "& label": { color: "#666", marginBottom: 5, display: "flex" },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInput-input:focus": {
      border: "1px #0074D9 solid",
      boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 15px",
    },
  },
  
   }));