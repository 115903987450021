import React from "react";
import { Route } from "react-router";
import { Link } from 'react-router-dom';

export const ProtectedRouteAdmin = (props) => {
  // if (localStorage.getItem("Token")) return <Route {...props} />;
  const token = localStorage.getItem("admin_Token");
  return (
    <>
      {/* <div>
        <p>You are not authorized to view this page.</p> <p>Please Login!</p>
      </div> */}
      {token ? <Route {...props} /> :  <div>
      <p>You are not authorized to view this page.</p> <p>Please <Link to="/">Login!</Link></p>
      </div> }

    </>
  );
};

export default ProtectedRouteAdmin;
