export const maxUseOptions = () => {
  var maxUse = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];
  return maxUse;
};

export const minTimeOptions = () => {
  var minTime = [
    { label: "10:00", value: "10" },
    { label: "30:00", value: "30" },
    { label: "45:00", value: "45" },
    { label: "60:00", value: "60" },
  ];
  return minTime;
};

export const maxBillingFreqList = () => {
  var minTime = [
    { label: "1 Month", value: "1" },
    { label: "3 Months", value: "3" },
    { label: "6 Months", value: "6" },
    { label: "12 Months", value: "12" },
  ];
  return minTime;
};

export const carePlanGridInfoList = () => {

var carePlanGridInfo = [
    {
      1: "patient_demographics",
    },
    {
      2: "patient_insurance",
    },
    {
      3: "patient_provider",
    },
    {
      4: "patient_dhct_medication",
    },
    {
      225: "patient_conditions",
    },
    {
      6: "ros",
    },
    {
      7: "patient_hospitilization",
    },
    {
      8: "patient_symmptom",
    },
    {
      9: "otc_medictions",
    },
  ];
  return carePlanGridInfo;
};
